import React, { useState, useContext } from "react";
import "./KanbanFilters.scss";
import { Button, DatePicker, Input, Modal, Select } from "antd";
import { CloseIcon, ModalXIcon } from "../../../assets/icons";
import Swal from "sweetalert2";
import { InterviewsContext } from "../data";
import { useSelector } from "react-redux";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

const KanbanFilters = () => {
  const {
    activeFilters,
    openFilters,
    setOpenFilters,
    setExcecute,
    setActiveFilters,
    setIntervistat,
    defaultSwalProperties,
  } = useContext(InterviewsContext);
  const [filters, setFilters] = useState(activeFilters || {});

  const { candidates } = useSelector((state) => state.candidates);

  const options = (key = "") => {
    let options = [];
    candidates?.forEach?.((kandidati) => {
      const { [key]: value } = kandidati;
      if (value && !options?.includes?.(value)) {
        options.push(
          Array.isArray(value) && value?.length > 0 ? value?.[0] : value
        );
      }
    });
    return options?.map?.((option) => ({ label: option, value: option }));
  };

  const createFilters = (value, key) => {
    if (value) {
      setFilters({ ...filters, [key]: value });
    } else {
      let fltrs = { ...filters };
      delete fltrs[key];
      delete activeFilters[key];
      setFilters(fltrs);
    }
  };
  const handleClick = () => {
    if (!Object.keys(filters)?.length) {
      setExcecute(false);
      setOpenFilters(false);
      setIntervistat(candidates);
      Swal.mixin({
        ...defaultSwalProperties,
      }).fire({
        icon: "question",
        title: "Nuk keni aplikuar asnje filter!",
      });
      return;
    }
    setActiveFilters({ ...activeFilters, ...filters });
    setExcecute(true);
    setOpenFilters(false);
    Swal.mixin({
      ...defaultSwalProperties,
    }).fire({
      icon: "success",
      title: "Filtrat u aplikuan me sukses!",
    });
  };

  return (
    <div>
      <Modal
        title="Filtroni"
        className="kanban-filters-modal lightHeader"
        centered
        open={openFilters}
        onCancel={() => setOpenFilters(false)}
        width={870}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            onClick={() => setOpenFilters(false)}
            className="mondayButtonRed"
          >
            Mbyllni faqen
          </MondayButton>,
          <MondayButton onClick={handleClick} className="mondayButtonGreen">
            Aplikoni filtrimin
          </MondayButton>,
        ]}
      >
        <>
          <div className="filtersContent">
            <div className="filterData">
              <span>Pozicioni</span>
              <Select
                placeholder="Pozicioni..."
                showSearch
                onChange={(value) =>
                  createFilters(value, "applicantDepartment")
                }
                allowClear={true}
                value={filters?.applicantDepartment}
                options={options("applicantDepartment")}
              />
            </div>
            <div className="filterData">
              <span>Universiteti</span>
              <Select
                placeholder="Universiteti..."
                showSearch
                allowClear={true}
                value={filters?.applicantUniversity}
                onChange={(value) =>
                  createFilters(value, "applicantUniversity")
                }
                options={options("applicantUniversity")}
              />
            </div>
            <div className="filterData">
              <span>Datat e Intervistes</span>
              <DatePicker.RangePicker
                placeholder={["Data e Fillimit", "Data e Fundit"]}
                value={filters?.applicantInterviewDate}
                format="DD/MM/YYYY"
                onChange={(value) =>
                  createFilters(value, "applicantInterviewDate")
                }
              />
            </div>
            <div className="filterData">
              <span>Niveli i diplomes</span>
              <Select
                placeholder="Niveli i diplomes..."
                showSearch
                allowClear={true}
                value={filters?.applicantDiplomaLevel}
                onChange={(value) =>
                  createFilters(value, "applicantDiplomaLevel")
                }
                options={options("applicantDiplomaLevel")}
              />
            </div>
            <div className="filterData">
              <span>Dega</span>
              <Select
                placeholder="Dega..."
                showSearch
                allowClear={true}
                value={filters?.applicantUniversityField}
                onChange={(value) =>
                  createFilters(value, "applicantUniversityField")
                }
                options={options("applicantUniversityField")}
              />
            </div>
            <div className="filterData">
              <span>Skuadra</span>
              <Select
                placeholder="Skuadra..."
                onChange={(value) => createFilters(value, "applicantTeam")}
                value={filters?.applicantTeam}
                allowClear={true}
                showSearch
                options={options("applicantTeam")}
              />
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default KanbanFilters;
