import { AgGridReact } from "ag-grid-react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { gridSidebar } from "./utils/gridSidebar";
import StatusesPanel from "./StatusesPanel/StatusesPanel";
import "./DocumentationPage.scss";
import { documentationsPageColDef } from "./documentationPageData";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { clearFilter, onFilter } from "./utils/onFilter";
import useObserver from "./hooks/useObserver";
import { min, uniqBy } from "lodash";
import FormCard from "./FormCard/FormCard";
import { apiRoutes } from "../../apiRoutes";
import { fetchData } from "../../fetchData";
import { useNavigate } from "react-router";
import { Button, Input, Tooltip } from "antd";
import { IconKerko, IconExcel, IconNgargo } from "../../assets/icons";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../utils/MobileComponents/MobileHtmlTabel";
import moment from "moment-timezone";
import FilterAgGrid from "../punonjesit/components/FilterAgGrid";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import DokumentacionePrint from "./DokumentacionePrint";
import { useReactToPrint } from "react-to-print";
import { API } from "aws-amplify";
import AgGridComponent from "../AG-grid/AgGridComponent";
import CustomAgPaginator from "../punonjesit/EmployeeView/CustomAgPaginator/CustomAgPaginator";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";

// * @EneaXharau -  Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/documentation/sockets"));

const EXPORT_COLS = [
  { en: "docType", al: "Lloji" },
  { en: "docStatus", al: "Statusi" },
  { en: "name", al: "Punonjësi" },
  { en: "employeeDepartmentName", al: "Departamenti" },
  { en: "employeeRoleName", al: "Roli" },
  { en: "notes", al: "Shënime" },
  { en: "createdAt", al: "Krijuar në" },
  { en: "expirationDate", al: "Data e Skadencës" },
];
const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: [
        { key: "name", label: "Punonjesi", type: "string" },
        {
          key: "employeeDepartmentName",
          label: "Departamenti",
          type: "string",
        },
        { key: "employeeRoleName", label: "Roli", type: "string" },
      ],
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

const DocumentationPage = () => {
  const navigate = useNavigate();
  const { programFields = [] } = useSelector((state) => state.programFields);
  const { employees } = useSelector((state) => state.employeesList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const [gridApi, setGridApi] = useState(null);
  const [documentations, setDocumentations] = useState([]);
  const [docMapping, setDocMapping] = useState({});
  const [docConfig, setDocConfig] = useState([]);
  // const [selectedCategory] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [gridData, setGridData] = useState([]);
  const gridContainerRef = useRef();
  const [searchInput, setSearchInput] = useState(null);

  console.log(gridData);
  useObserver({
    element: gridContainerRef,
    callback: () => {
      !!gridApi && gridApi.sizeColumnsToFit();
    },
  });

  //* maps all documents uploaded with all their info and shows them at DocumentationPage
  const statuses = uniqBy(
    docConfig
      .flatMap(({ documentationsAvailable }) => documentationsAvailable)
      .flatMap(({ docStatuses = [] }) => docStatuses),
    "statusName"
  );

  //* gets statuses from docConfiguration and shows them at DocumentationPage
  const populatedStatuses = statuses.map((el) => ({
    ...el,
    value: documentations.filter(
      ({ docStatus }) => docStatus === el?.statusName
    ).length,
  }));

  const populatedColDefs = documentationsPageColDef({
    navigate,
    accessToken,
    // docMapping,
    statuses: statuses.map(({ statusName: status, statusColor: color }) => ({
      status,
      color,
    })),
    // selectedCategory,
    employees,
  });

  //region ROW DATA
  // const populateRowData = () => {
  //   // return !selectedCategory

  //   ''  ? documentations
  //     : documentations
  //         .filter(({ categoryName }) => categoryName === selectedCategory)
  //         .reduce((acc, curr = {}, _, arr) => {
  //           const { recordId, recordName, categoryName } = curr;
  //           const filteredArr = arr.filter(
  //             ({ recordId: arrRecordId }) => arrRecordId === recordId
  //           );
  //           return !!acc
  //             .map(({ recordId: accRecordId }) => accRecordId)
  //             .includes(recordId)
  //             ? acc
  //             : [
  //                 ...acc,
  //                 {
  //                   recordId,
  //                   recordName,
  //                   categoryName,
  //                   allGoogleDriveUploads: filteredArr.map(
  //                     ({ googleDriveUploads, docType }) => ({
  //                       docType,
  //                       googleDriveUploads,
  //                     })
  //                   ),
  //                   missingFiles: filteredArr
  //                     .filter(
  //                       ({ googleDriveUploads = [] }) =>
  //                         !googleDriveUploads.length
  //                     )
  //                     .map(({ docType }) => docType),
  //                   nearestExpDate: min(
  //                     filteredArr
  //                       .filter(
  //                         ({ expirationDate }) =>
  //                           expirationDate > dayjs().valueOf()
  //                       )
  //                       .map(({ expirationDate }) => expirationDate)
  //                   ),
  //                 },
  //               ];
  //         });
  // };

  /* shows all data of an documentation uploaded
    at DocumentationView --------------------------------- commented out and changed to state to make this future proof*/
  // const gridData = !selectedCategory
  // 	? documentations
  // 	: documentations
  // 			.filter(({ categoryName }) => categoryName === selectedCategory)
  // 			.reduce((acc, curr = {}, _, arr) => {
  // 				const { recordId, recordName, categoryName } = curr;

  // 				const filteredArr = arr.filter(
  // 					({ recordId: arrRecordId }) => arrRecordId === recordId
  // 				);
  // 				return !!acc
  // 					.map(({ recordId: accRecordId }) => accRecordId)
  // 					.includes(recordId)
  // 					? acc
  // 					: [
  // 							...acc,
  // 							{
  // 								recordId,
  // 								recordName,
  // 								categoryName,
  // 								allGoogleDriveUploads: filteredArr.map(
  // 									({ googleDriveUploads, docType }) => ({
  // 										docType,
  // 										googleDriveUploads,
  // 									})
  // 								),
  // 								missingFiles: filteredArr
  // 									.filter(
  // 										({ googleDriveUploads = [] }) =>
  // 											!googleDriveUploads.length
  // 									)
  // 									.map(({ docType }) => docType),
  // 								nearestExpDate: min(
  // 									filteredArr
  // 										.filter(
  // 											({ expirationDate }) =>
  // 												expirationDate > dayjs().valueOf()
  // 										)
  // 										.map(({ expirationDate }) => expirationDate)
  // 								),
  // 							},
  // 					  ];
  // 			});'

  //region AG GRID
  // * @EneaXharau - Promise function to fetch data for tables: documentation and docConfiguration and set them in state
  const fetchDataRefresh = () => {
    Promise.allSettled([
      fetchData(apiRoutes.documentation),
      fetchData(apiRoutes.docConfiguration),
    ]).then(([{ value: docRes }, { value: docConfigRes }]) => {
      setDocumentations(docRes);
      setDocConfig(docConfigRes);
      setLoading(false);
    });
  };

  const onStatusCardClick = (statusName) => {
    setActiveFilter((currFilter) =>
      currFilter === statusName ? "" : statusName
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onFilterTextChange = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
    setSearchInput(e.target.value);
  };

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
    setSearchInput(null);
  };

  // print file
  const componentRef = useRef("Dokumentacione-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const changeExpirationStatus = async (doc, status) => {
    if (!!doc && !!status) {
      await API.put("documentation", `/documentation/${doc?.docId}`, {
        body: {
          docStatus: status,
        },
      })
        .then(() => {})
        .catch((err) => console.log("Error changing expiration status:", err));
    } else console.log("Invalid arguments!");
  };

  useEffect(() => {
    fetchDataRefresh();
  }, []);

  useEffect(() => {
    !!gridApi &&
      (!!activeFilter
        ? onFilter({ gridApi, column: "docStatus", current: activeFilter })
        : clearFilter({ gridApi, column: "docStatus" }));
  }, [activeFilter]);

  useEffect(() => {
    const fieldObj = programFields.reduce(
      (acc, { fieldName, fieldOptions }) => ({
        ...acc,
        [fieldName]: fieldOptions,
      }),
      {}
    );
    setDocMapping(fieldObj["Documentation Mapping"]);
  }, [programFields]);

  useEffect(() => {
    gridData?.forEach((el) => {
      if (!!el?.expirationDate && el?.expirationDate < Date.now()) {
        changeExpirationStatus(el, "Skaduar");
      }
    });

    if (!!gridApi) {
      gridApi.redrawRows();
      gridApi.sizeColumnsToFit();
    }
  }, [gridData]);

  useEffect(() => {
    setGridData(
      documentations
        ?.map((el) => {
          for (let i = 0; i < employees?.length; i++) {
            const employee = employees[i];
            if (employee?.employeeId === el?.recordId) {
              return {
                ...el,
                name: `${employee?.employeeFirstName} ${employee?.employeeLastName}`,
                employeeDepartmentName: `${employee?.employeeDepartmentName}`,
                employeeRoleName: `${employee?.employeeRoleName}`,
              };
            }
          }
        })
        .filter(Boolean)
    );
  }, [employees, documentations]);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  //   socketHandler.onmessage = (msg) => {
  //     const { request, body } = JSON.parse(msg.data);
  //     switch (request) {
  //       case "document_added_received": {
  //         fetchDataRefresh();
  //         break;
  //       }
  //       case "document_removed_received": {
  //         fetchDataRefresh();
  //         break;
  //       }
  //       case "document_changed_received": {
  //         fetchDataRefresh();
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   };
  //   return () => socketHandler.close();
  // }, []);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  //region RETURN
  return (
    <div className="globalPageWrapper">
      {isMobileView ? (
        <div className="documentationPageWrapper">
          <LoadableComp loading={loading}>
            {/* {!selectedCategory && ( */}
            <StatusesPanel
              {...{
                data: populatedStatuses,
                onClick: onStatusCardClick,
              }}
            />
            {/* )} */}
            <FormCard
              {...{
                className: "docGridCard",
              }}
            >
              <AgGridHeader
                gridApi={gridApi}
                gridData={gridData}
                items={ITEMS}
                exportTitle={"Lista e Dokumenteve"}
              />
              <div
                className="ag-theme-alpine documentationsTable"
                ref={gridContainerRef}
              >
                <AgGridComponent
                  columnDefs={populatedColDefs}
                  rowData={gridData}
                  customDefaultColDef={{
                    enablePivot: true,
                    enableColResize: true,
                    enableRowGroup: true,
                    editable: false,
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                  }}
                  paginationPageSize={11}
                  onGridReady={onGridReady}
                  rowHeight={45}
                  headerHeight={45}
                />
                <CustomAgPaginator gridApi={gridApi} />
              </div>
            </FormCard>
          </LoadableComp>
        </div>
      ) : (
        <div className="base-mobile-div">
          <MobileHtmlTabel
            header={["Emri", "Lloj Doc.", "Statusi", "Krijuar", "Skadon"]}
            tableData={gridData?.map?.(
              ({
                name = "",
                docType,
                docStatus,
                createdAt,
                expirationDate,
              }) => ({
                name,
                statusHeaderKey: docType,
                docStatus,
                createdAt: moment(createdAt).format("DD/MM/YYYY"),
                expirationDate: moment(expirationDate).format("DD/MM/YYYY"),
              })
            )}
            isHeader={true}
            isLink={false}
          />
        </div>
      )}
      <DokumentacionePrint tableData={gridData} componentRef={componentRef} />
    </div>
  );
};
export default DocumentationPage;
