import { useState, useEffect } from "react";
import { Modal } from "antd";
import moment from "moment-timezone";
import "./YesterdayInfo.scss";
import {
  ClockInIcon,
  ClockOutIcon,
  YesterdayShiftClock,
} from "../../assets/index";
import { API } from "aws-amplify";
import { ShiftCard } from "../../../ListPrezenca/timesheet/ProgressShift";
import { useSelector } from "react-redux";
import { ModalXIcon } from "../../../../assets/icons";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
const YesterdayInfo = ({ showYesterday, handleYesterday, shift, userData }) => {
  const [lastShift, setLastShift] = useState(null);
  const { clockings } = useSelector((state) => state.clockings);

  // retrieves the last shift in database with set values for clockInDate and clockOutDate
  async function getLastFinishedShift(emplId) {
    let filtered = clockings?.filter(
      (el) =>
        el.employeeId === emplId &&
        el.clockInDate !== null &&
        el.clockOutDate !== null
    );
    let sorted = filtered.sort((a, b) => a.clockInDate - b.clockInDate).at(-1);
    setLastShift(sorted);
  }
  useEffect(() => {
    if (shift.employeeId !== null && !!clockings) {
      getLastFinishedShift(shift.employeeId);
    }
  }, [shift, clockings]);

  return (
    <div className="yesterday-info-wrapper">
      <Modal
        className="yesterdayModal lightHeader"
        centered={true}
        title={`Detaje | ${moment(lastShift?.clockInDate).format(
          "DD/MM/YYYY"
        )}`}
        open={showYesterday}
        onOk={() => handleYesterday(false)}
        onCancel={() => handleYesterday(false)}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            onClick={() => handleYesterday(false)}
          >
            Mbylle Faqen
          </MondayButton>,
        ]}
        closeIcon={<ModalXIcon />}
        width={805}
      >
        {!!lastShift ? (
          <div className="yesterday-shift-wrapper">
            {/* <div className="clock-icons">
              <ClockOutIcon className="clock-out-icon" />
              <ClockInIcon className="clock-in-icon" />
            </div> */}
            <div className="dsd">
              <YesterdayShiftClock />
            </div>

            <span className="yesterday-shift-text">
              Përshëndetje {userData?.userAccess[0]?.given_name}{" "}
              {userData?.userAccess[0]?.family_name}
              , <br /> ti në rekordin e fundit{" "}
              <span>
                {" "}
                ke nisur turnin në orën{" "}
                {moment(lastShift?.clockInDate).format("HH:mm")},
              </span>{" "}
              dhe e{" "}
              <span>
                ke mbyllur në orën{" "}
                {moment(lastShift?.clockOutDate).format("HH:mm")}.
              </span>
            </span>
            <ShiftCard
              info={{
                clockInDate: lastShift?.clockInDate,
                clockOutDate: lastShift?.clockOutDate,
                type: "",
                duration: moment(lastShift?.clockOutDate)
                  .diff(moment(lastShift?.clockInDate), "hours", true)
                  .toFixed(2),
              }}
              lastShift={lastShift}
            />
          </div>
        ) : (
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <h3 style={{ color: "#EA3943" }}>
              Në rekordet e disponueshme, nuk ekziston një orar i cili ju përket
              juve!
            </h3>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default YesterdayInfo;
