import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router";
import "./GeneratePage.scss";
import { Button, Card, DatePicker, message } from "antd";
import moment from "moment-timezone";
import { Select } from "antd";
import FilterDrawer from "../filters/FilterDrawer";
import { getTranslation } from "./translations";
import { CaretDownFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useRedux } from "../../../hooks/useRedux";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

const { Option } = Select;
const { RangePicker } = DatePicker;
function GeneratePageHeader({
  retrieveFilters,
  retrieveColumns,
  onGenerate,
  contentData,
  view,
  resetCustom,
  src,
}) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    filterType: "range",
    default: true,
    timeInterval: [moment().startOf("month"), moment().endOf("month")],
  });
  const [shortcut, setShortcut] = useState("range");
  const [visible, setVisible] = useState(false);
  const [columns, setColumns] = useState(contentData?.selectedColumns || []);
  const { programFields } = useSelector((state) => state.programFields);

  useEffect(() => {
    retrieveFilters(filters);
  }, [filters]);
  // (() => {
  // 	retrieveFilters(filters);
  // }, [filters.filterType, filters.timeInterval]);

  const onTimeChange = (e) => {
    if (shortcut === "range") {
      const tempInterval = !!e
        ? [moment(e?.[0]), moment(e?.[1])]
        : [null, null];
      if (
        filters.timeInterval[0] !== tempInterval[0] ||
        filters.timeInterval[1] !== tempInterval[1]
      )
        setFilters((prev) => ({
          ...prev,
          timeInterval: tempInterval,
        }));
    }
  };

  console.log("filters", filters);

  // console.log("shortcut", shortcut);

  const getShortcuts = (shortcut) => {
    console.log("Shortcut", shortcut);
    if (shortcut === "range") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        // timeInterval: [moment().startOf("month"), moment().endOf("month")],
      }));
    } else if (shortcut === "today") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        timeInterval: [moment().startOf("day"), moment().endOf("day")],
      }));
    } else if (shortcut === "yesterday") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        timeInterval: [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ],
      }));
    } else if (shortcut === "thisWeek") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        timeInterval: [moment().startOf("week"), moment().endOf("week")],
      }));
    } else if (shortcut === "thisMonth") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        timeInterval: [moment().startOf("month"), moment().endOf("month")],
      }));
    } else if (shortcut === "previousMonth") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        timeInterval: [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ],
      }));
    } else if (shortcut === "thisYear") {
      setFilters((prev) => ({
        ...prev,
        filterType: shortcut,
        timeInterval: [moment().startOf("year"), moment().endOf("year")],
      }));
    }
  };

  useEffect(() => {
    // console.log("Shortcut", shortcut);
    if (!!shortcut && shortcut !== undefined) {
      getShortcuts();
    }
  }, [shortcut]);

  useLayoutEffect(() => {
    if (!!contentData?.selectedColumns) {
      setColumns(contentData?.selectedColumns);
    }
  }, [contentData]);

  return (
    <div className="generate-page-header">
      <Card
        className="card-report-header"
        title="Gjenero raportin"
        bordered={false}
      >
        <div className="content-header-card">
          <MondayButton
            className="mondayButtonGreen"
            onClick={() => navigate("/raportet")}
          >
            Ktheu pas
          </MondayButton>
          <div className="time-period">
            <span>Periudha:</span>
            <div>
              <Select
                style={{
                  width: 120,
                }}
                suffixIcon={<CaretDownFilled />}
                className="select-option"
                variant={false}
                value={shortcut}
                onChange={(e) => {
                  setShortcut(e);
                  getShortcuts(e);
                }}
              >
                <Option value="range">Interval</Option>
                <Option value="today">Sot</Option>
                <Option value="yesterday">Dje</Option>
                <Option value="thisMonth">Kete muaj</Option>
                <Option value="thisWeek">Kete Jave</Option>
                <Option value="thisYear">Kete Vit</Option>
                <Option value="previousMonth">Muaji i Kaluar</Option>
              </Select>
            </div>
            <div>
              <RangePicker
                bordered={false}
                className="range-pick-report"
                allowClear={true}
                disabledDate={(params) =>
                  disabledDate(params, filters.timeInterval)
                }
                value={!!filters ? filters.timeInterval : null}
                renderExtraFooter={() =>
                  "Intervali maksimal i te dhenave per kete report eshte 4 muaj."
                }
                onChange={(e) => {
                  onTimeChange(e);
                  setShortcut("range");
                }}
                format={"DD/MM/YYYY"}
              />
            </div>

            <Select
              mode="multiple"
              allowClear={false}
              className="select-option-multi"
              variant={false}
              placeholder="Please select"
              value={columns}
              onChange={(e) => setColumns(e)}
            >
              {contentData?.allColumns?.map((el) => (
                <Option value={el}>{getTranslation(el)}</Option>
              ))}
            </Select>
            <MondayButton
              onClick={() => retrieveColumns(columns)}
              className="mondayButtonGreen"
            >
              Ndrysho
            </MondayButton>
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => setVisible(true)}
            >
              Opsione filtrimi
            </MondayButton>
          </div>
          <FilterDrawer
            {...{ visible, setVisible, contentData, onGenerate, resetCustom }}
          />
        </div>
      </Card>
    </div>
  );
}

export default GeneratePageHeader;
function disabledDate(current, ledjo) {
  // Disable dates beyond a 3-month range
  return current && !!ledjo?.[0]
    ? current < moment().subtract(4, "months") ||
        current > moment().add(4, "months")
    : false;
}
