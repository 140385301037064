import React, { createContext, useState, useEffect, useRef } from "react";
// import Approvals from "./approvals/Approvals";
// import { API } from "aws-amplify";
import moment from "moment-timezone";
import { IconExcel, IconNgargo } from "../../assets/icons";
import { Tooltip } from "antd";
import AgGridComponent from "../AG-grid/AgGridComponent";
// import ShiftModal from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import { getMonthsOfYear } from "../punonjesit/EmployeeView/Payment/utils/calculations";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  calculateMonthAllPayment,
  getMonthlyAmounts,
  // calculateWagePerHours,
} from "./wages/utils/calculations";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import PagatGridHeader from "./wages/PagatGridHeader";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobilePagatGrid from "./wages/MobilePagatGrid";
import { onFilter } from "../Documentation/utils/onFilter";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
const PagatContext = createContext(null);

const EXPORT_COLS = [
  { en: "muaji", format: "MMMM YYYY", al: "Muaji" },
  { en: "bruto", al: "Shuma Bruto" },
  { en: "neto", al: "Shuma Neto" },
  { en: "taxes", al: "Taksat e Punëdhënësit" },
  { en: "totalCost", al: "Kostoja Totale" },
];

const ITEMS = {
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function PagatGrid() {
  // const { Option } = Select;
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const { clockings } = useSelector((state) => state.clockings);
  const { employees } = useSelector((state) => state.employeesList);
  const { wages } = useSelector((state) => state.wages);
  let navigate = useNavigate();
  // columns displayed in the grid
  const columnDefs = [
    {
      headerName: "Nr",
      field: "nr",
      checkboxSelection: true,
      suppressSizeToFit: true,
      maxWidth: 100,
    },
    {
      headerName: "Muaji",
      field: "muaji",
      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          {moment(data.muaji).format("MMMM YYYY") !== "Invalid date" ? (
            <div
              variant="outlined"
              onClickCapture={() => handleMonthModal(data)}
            >
              <span style={{ color: "#1DA193", fontWeight: 600 }}>
                {moment(data.muaji).format("MMMM YYYY")}
              </span>
            </div>
          ) : (
            <span>{data.muaji}</span>
          )}
        </div>
      ),
    },
    {
      headerName: "Shuma Bruto",
      field: "bruto",
    },
    {
      headerName: "Shuma Neto",
      field: "neto",
    },

    {
      headerName: "Taksat e punedhenesit",
      field: "taxes",
    },
    {
      headerName: "Kostoja totale",
      field: "totalCost",
    },
  ];
  // calculateMonthAllPayment(employees, clockings, { data: { muaji: moment() } });

  // make the grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridApi(params.api);
  };

  const onBtExport = () => {
    gridApi.exportDataAsExcel();
  };

  const handleCellClick = (params) => {
    // console.log("Click", params);
  };

  const handleMonthModal = (data) => {
    return navigate("/pagat/llogaritja", { state: { data } });
  };

  useEffect(() => {
    if (!!wages) {
      let temp = [...getMonthsOfYear()] || [];
      if (temp.length > 0) {
        let appliedWages = wages.filter((el) => el?.wageStatus === "Finalized");
        temp.forEach((el) => {
          el.neto = getMonthlyAmounts("neto", appliedWages, el.muaji) || 0;
          el.bruto = getMonthlyAmounts("bruto", appliedWages, el.muaji) || 0;
          el.taxes = getMonthlyAmounts("taxes", appliedWages, el.muaji) || 0;
          el.totalCost =
            getMonthlyAmounts("totalCost", appliedWages, el.muaji) || 0;
        });
      }
      setRowData([...temp]);
    }
  }, [wages]);

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  console.log(rowData);

  return (
    <div className="globalPageWrapper">
      {isMobileView ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
          <PagatGridHeader
            rowData={rowData}
            onFilter={onFilter}
            gridApi={gridApi}
          />
          <div className="punonjesit-ag-grid">
            <AgGridHeader
              gridApi={gridApi}
              gridData={rowData}
              items={ITEMS}
              exportTitle={"Lista e Pagave"}
            />
            <div
              className="ag-theme-alpine"
              style={{ height: 610, width: "100%" }}
            >
              <AgGridComponent
                rowData={rowData}
                rowSelection="single"
                onGridReady={onGridReady}
                paginationPageSize={12}
                columnDefs={columnDefs}
                //   onRowValueChanged={onRowValueChanged}
                // onSelectionChanged={handleSelectionChanged}
                onCellClicked={handleCellClick}
                onCell
                suppressServerSideInfiniteScroll={"full"}
                enableCellChangeFlash={true}
                rowModelType={"serverSide"}
              />
            </div>
          </div>
        </div>
      ) : (
        <MobilePagatGrid rowData={rowData} />
      )}
    </div>
  );
}

export default PagatGrid;
