import { categorizeHours, performWageCalc, processShifts } from "./calculcate";
import moment from "moment-timezone";
// import { COEFFICENTS_WAGES } from "../../../../constantsFactory/wageHours";
import { COEFFICENTS_WAGES } from "./wageHours";
// import { groupByKey } from "../../../../utils/loadash/methods";
import { groupByKey } from "./methods";
import { add, multiply } from "lodash";
import { message } from "antd";
import { checkHolidaysEarnings } from "./holidayEarnings";
import { prepareRequests } from "./preparePaySub";
import { getBrutoFromNeto, prepareContributions } from "./contributions";
import { preparePrePayments } from "./prepayments";
import { getClockInPrePay, getEarningsSaved } from "./dayCalculateFactory";
export const sliceEmployeeShifts = (
  info,
  calculateEarnings,
  employeeRequests
) => {
  const { employee, clockings, momentInstance, programFields } = info; // O(1) T.C
  // O(1)
  console.log({ clockings });
  let result = {
    // calculatedWage: 0,
    allhours: { normalHours: 0, lateHours: 0, extraHours: 0, totalHours: 0 },
    employeeInfo: employee,
    momentInstance: momentInstance,
    calculations: [],
    // Only for PayStub
    paidClockIns: {},
    earnings: {},
    holidays: {},
    taxes: {},
    prepayments: {},
    bruto: { totalBruto: 0 },
    valid: !!employee && employee?.employeeStatus === "Aktiv" ? true : false,
    note: info?.note || null,
    allShiftsUser: null,
  };

  try {
    if (!!employee) {
      const allShiftsUser = processShifts(
        momentInstance,
        clockings,
        employee?.employeeId
      );
      const processedShifts = allShiftsUser.filter(
        (el) => el?.dayType === "Regular"
      ); // O(n) Linear T.C
      result.paidClockIns = getClockInPrePay(processedShifts);
      const otherTypes = allShiftsUser.filter(
        (el) => el?.dayType !== "Regular"
      );
      // console.log("Processed 13", allShiftsUser);
      result.calculations = groupShifts(processedShifts); // O(n) + O(n) => O(n) Linear T.C
      if (result?.calculations.length > 0) {
        result?.calculations.map((calcArr) =>
          calcArr?.map((c) => {
            if (
              Object(result?.allhours).hasOwnProperty(c?.type) &&
              c?.duration > 0
            ) {
              result.allhours[c?.type] += c?.duration;
            }
          })
        );
      }
      result.allhours.totalHours =
        result.allhours.normalHours +
        result.allhours.lateHours +
        result.allhours.extraHours;
      if (calculateEarnings && employee?.employeeStatus === "Aktiv") {
        // result.earnings = performCalcEarnings(employee, result);
        result.earnings = getEarningsSaved(result);
        result.holidays = checkHolidaysEarnings(
          processedShifts,
          employee,
          momentInstance,
          info?.employeeRequests
        );
        result.requests = prepareRequests(result, otherTypes, programFields);
        result.prepayments = preparePrePayments(
          momentInstance,
          employee?.employeeId,
          info?.prepayments
        );
        result.bruto = getTotalBruto(result);
        console.log("result.bruto", result.bruto);

        // Last step
        result.taxes = prepareContributions({
          finalWage: result.bruto.totalBruto,
          info: employee,
        }); /// ---- insert bruto toal

        // inserting allShiftsUser for TimeSheet
        result.allShiftsUser = allShiftsUser

        return result;
      }

      // Sum different hours all Linear cost O(n) operations

      return result;
    } else {
      return {
        // calculatedWage: 0,
        allhours: {
          normalHours: 0,
          lateHours: 0,
          extraHours: 0,
          totalHours: 0,
        },
        employeeInfo: employee,
        momentInstance: momentInstance,
        calculations: [],
        earnings: {},
        valid: false,
        note: info?.note || null,
      };
    }
  } catch (err) {
    message.error("Ndodhi nje gabim ne llogaritjen e te dhenave!");
    console.error("gabim", err);
  } finally {
    result = {
      // calculatedWage: 0,
      allhours: { normalHours: 0, lateHours: 0, extraHours: 0, totalHours: 0 },
      employeeInfo: employee,
      momentInstance: momentInstance,
      calculations: [],
      earnings: {},
      valid: false,
    };
  }
};

/**
 * Used to group shifts by Date || O(n) + O(1) = O(n) time complexity
 * @param {Array Of Objects} shifts
 * @returns {Array}
 */

export const groupShifts = (shifts) => {
  let result = shifts;
  const differentHours = [];
  // Categorize the timeshift
  // {1. Before 19:00 (coefficent=1), 2. Between 19.00 - 22:00(c=1.2), After 22:00 (c=1.5) }
  if (!!result) {
    if (result.length > 0) {
      // O(n)
      result = result.map((el) => {
        if (!!el?.clockInDate) {
          return {
            ...el,
            shiftDate: moment(el?.clockInDate).format("DD/MM/YYYY"),
          };
        } else {
          return el;
        }
      });
      const groupedShiftDays = groupByKey(result, "shiftDate"); // Likely O(n)
      // console.log("LEDJO GROUPED", groupedShiftDays);
      // O(n)
      Object.values(groupedShiftDays).forEach((el) => {
        // let cat = categorizeHours(el);
        // TO DELETE
        const test = sliceShifts(el);
        test?.map((el) => ({
          ...el,
          fillim: moment(el?.clockInDate).format("DD/MM/YYYY HH:mm"),
          mbarim: moment(el?.clockOutDate).format("DD/MM/YYYY HH:mm"),
        }));

        /////////////
        if (!!test) differentHours.push(test); // O(1)
      });
      return differentHours; // O(1)
    }
  }

  return differentHours;
  // console.log("tayl1", result);
};
export const sliceShifts = (dayShifts) => {
  let temp = dayShifts;
  let result = [];
  let EARNINGS_REFERENCE = {
    NORMAL_HOURS: {
      coefficent: COEFFICENTS_WAGES.NORMAL_HOURS,
      start: moment("07:30", "HH:mm"),
      end: moment("19:00:00", "HH:mm:ss"),
      amount: 0,
    },
    LATE_HOURS: {
      coefficent: COEFFICENTS_WAGES.LATE_HOURS,
      start: moment("19:00", "HH:mm"),
      end: moment("22:00:00", "HH:mm:ss"),
      amount: 0,
    },
    EXTRA_HOURS: {
      coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
      start: moment("22:00", "HH:mm"),
      end: moment("23:59:59", "HH:mm:ss"),
      amount: 0,
    },
    EXTRA_HOURS2: {
      coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
      start: moment("00:00", "HH:mm"),
      end: moment("07:00", "HH:mm:ss"),
      amount: 0,
    },
    // EXTRA_HOURS: {
    // 	coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
    // 	start: moment("00:01", "HH:mm"),
    // 	end: moment("06:59:59", "HH:mm:ss"),
    // 	amount: 0,
    // },
  };

  temp.forEach((t) => {
    let sliced =
      t?.type !== "missing" ? performSliceShifting(t, EARNINGS_REFERENCE) : [t];
    if (sliced?.length > 0) result = [...result, ...sliced];
  });
  return result;
};

export const performSliceShifting = (singleShift, SHIFT_EARNINGS) => {
  // console.log("single 1718", singleShift);

  let slicedShifts = []; // This will return the sliced shifts
  const temp = singleShift; // to avoid direct mutation
  const earningsRef = SHIFT_EARNINGS; // references for coefficents and start intervals

  // In case the shift is classified as "missing" => empty return as such
  if (singleShift?.type === "missing") {
    slicedShifts.push(temp);
  }

  // Go Over the Reference object and set the date (not time) same as current shift to be calculated || O(n) linear T.C
  Object.entries(earningsRef).forEach(([hourType, hourlyReference]) => {
    let lowerBound = moment(hourlyReference.start);
    let higherBound = moment(hourlyReference?.end);
    lowerBound.set({
      date: moment(temp?.clockInDate).get("date"),
      month: moment(temp?.clockInDate).get("month"),
      year: moment(temp?.clockInDate).get("year"),
    });
    higherBound.set({
      date: moment(temp?.clockInDate).get("date"),
      month: moment(temp?.clockInDate).get("month"),
      year: moment(temp?.clockInDate).get("year"),
    });
    hourlyReference.start = lowerBound;
    hourlyReference.end = higherBound;
  });

  // Perfect Case Scenario || Shifts is in one segment, will return classified single shift Array.length = 1
  // This will yeild perfect optimal soltion 3*O(n) = O(n)

  getTypeHours(temp, earningsRef?.EXTRA_HOURS, slicedShifts, "extraHours");
  getTypeHours(temp, earningsRef?.LATE_HOURS, slicedShifts, "lateHours");
  getTypeHours(temp, earningsRef?.NORMAL_HOURS, slicedShifts, "normalHours");
  getTypeHours(temp, earningsRef?.EXTRA_HOURS2, slicedShifts, "extraHours");

  // If perfect case scenario is not applied, check other intervals (intersections)

  if (slicedShifts.length === 0) {
    // console.log("temp", temp);
    // if (checkInterval(temp?.clockInDate, temp?.clockOutDate, ))
    // CASE_1: Check the interval between LATE_HOURS & EXTRA_HOURS (UNION, INCLUSIVE)
    if (
      checkInterval(
        temp?.clockInDate,
        temp?.clockOutDate,
        earningsRef?.LATE_HOURS?.start,
        earningsRef?.EXTRA_HOURS?.end
      )
    ) {
      // If clockOut - 22:00 > 0 temp.clockOutDate = 21:59
      if (
        moment(temp.clockOutDate).diff(
          earningsRef?.extraHours?.start,
          "hours",
          true
        )
      )
        slicedShifts.push({
          ...temp,
          type: "extraHours",
          clockInDate: earningsRef?.EXTRA_HOURS?.start,
        }); // > clockIn 22:00
      temp.clockOutDate = earningsRef?.LATE_HOURS?.end;
      slicedShifts.push({ ...temp, type: "lateHours" });
    }
    // Case that the interval relies on all three cases, so it is betweeen 00:00 - 19:00 or 00:00 - 23:59
    else {
      // clockIn : orginal, clockOut: 18:59
      slicedShifts.push({
        ...temp,
        clockOutDate: earningsRef?.NORMAL_HOURS?.end,
        type: "normalHours",
      });
      temp.clockInDate = earningsRef?.LATE_HOURS?.start;
      if (
        moment(temp?.clockOutDate).diff(
          earningsRef?.EXTRA_HOURS?.start,
          "hours",
          true
        ) > 0
      ) {
        // clockOut relies on 22:00 - 23:59
        slicedShifts.push({
          ...temp,
          clockOutDate: earningsRef?.LATE_HOURS?.end,
          type: "lateHours",
        });
        slicedShifts.push({
          ...temp,
          clockInDate: earningsRef?.EXTRA_HOURS?.start,
          type: "extraHours",
        });
        temp.clockInDate = earningsRef?.EXTRA_HOURS?.start;
      } else {
        // clockOut relies on 19:00 - 22:00
        slicedShifts.push({
          ...temp,
          clockInDate: earningsRef?.LATE_HOURS?.start,
          type: "lateHours",
        });
        temp.clockInDate = earningsRef?.LATE_HOURS?.start;
      }
    }
  }

  slicedShifts = slicedShifts.map((sShift) => ({
    ...sShift,
    duration: moment(sShift?.clockOutDate).diff(
      sShift.clockInDate,
      "hours",
      true
    ),
  }));

  //   console.log("Sliced Shifts", slicedShifts);

  return slicedShifts;
};

const getHourlyDifference = (firstInstance, secondInstance) => {
  if (!!firstInstance && !!secondInstance) {
    return moment(firstInstance).diff(secondInstance, "hours", true);
  }
  return 0;
};

// Checks The Interval of  Shift if it is between the interval
const checkInterval = (startTime, endTime, intervalStart, intervalEnd) => {
  if (
    moment(startTime).isBetween(intervalStart, intervalEnd, undefined, []) &&
    moment(endTime).isBetween(intervalStart, intervalEnd, undefined, [])
  ) {
    return true;
  }
  return false;
};

// Used to check whether the shift relies on one segment!

const getTypeHours = (shift, referenceShift, slicedShifts, shiftType) => {
  if (
    checkInterval(
      shift.clockInDate,
      shift.clockOutDate,
      referenceShift.start,
      referenceShift.end
    )
  ) {
    slicedShifts.push({ ...shift, type: shiftType });
  }
};

const performCalcEarnings = (employeeInfo, resultRefObj) => {
  if (
    employeeInfo?.employeePayrollType === "Page bazuar ne ore" &&
    !!resultRefObj
  ) {
    return calculateHourlyEarnings(
      employeeInfo?.employeeSalaryPerHour,
      resultRefObj
    );
  } else {
    // Static Wage Case
    return { totalWage: parseFloat(employeeInfo?.employeeGrossMonthlySalary) };
  }
};

function calculateHourlyEarnings(amountPerHour, referenceObj) {
  const totalities = {
    normalHours: {
      normalEarnings: 0,
      coefficent: COEFFICENTS_WAGES?.NORMAL_HOURS,
      rate: multiply(amountPerHour, COEFFICENTS_WAGES?.NORMAL_HOURS),
    },
    lateHours: {
      lateEarnings: 0,
      coefficent: COEFFICENTS_WAGES?.LATE_HOURS,
      rate: multiply(amountPerHour, COEFFICENTS_WAGES?.LATE_HOURS),
    },
    extraHours: {
      extraEarnings: 0,
      coefficent: COEFFICENTS_WAGES?.EXTRA_HOURS,
      rate: multiply(amountPerHour, COEFFICENTS_WAGES?.EXTRA_HOURS),
    },
    totalWage: 0,
  };
  let tempRef = referenceObj?.allhours || null;
  if (!!tempRef) {
    const { normalHours, lateHours, extraHours, totalHours } =
      referenceObj?.allhours;
    // Calculating Normal Hours Earnings
    const tempNormalEarnings =
      multiply(totalities?.normalHours?.rate, normalHours) || 0;
    totalities.normalHours.normalEarnings += tempNormalEarnings;
    // console.log("refrence obj", referenceObj);
    // Calculating Late Hours Earnings
    const tempLateEarnings =
      multiply(totalities?.lateHours?.rate, lateHours) || 0;
    totalities.lateHours.lateEarnings += tempLateEarnings;
    // Calculating Extra Hours Earnings
    const tempExtraEarnings =
      multiply(totalities?.extraHours?.rate, extraHours) || 0;
    totalities.extraHours.extraEarnings += tempExtraEarnings;
    // Total Wage
    totalities.totalWage =
      totalities.normalHours.normalEarnings +
      totalities.lateHours.lateEarnings +
      totalities.extraHours.extraEarnings;

    return totalities;
  }
}

export const getTotalBruto = (resultObj) => {
  console.log("result obj", resultObj);
  const totalities = {
    totalEarningsHours:
      resultObj?.earnings?.reduce((a, b) => a + b?.totalWage || 0, 0) || 0,
    totalEarningsHolidays: resultObj?.requests?.totalities?.earnings || 0,
    totalAdditions: !!resultObj?.prepayments
      ? resultObj?.prepayments?.earnings?.totalities?.amount || 0
      : 0,
    totalDeductions: !!resultObj?.prepayments
      ? resultObj?.prepayments?.deductions?.totalities?.amount || 0
      : 0,
    totalBruto: 0,
  };
  let temp =
    totalities?.totalEarningsHours +
    totalities?.totalEarningsHolidays +
    (totalities.totalAdditions - totalities.totalDeductions);
  totalities.totalBruto = getBrutoFromNeto(temp || 0);
  return totalities;
  // console.log("totalities", totalities);
  // if (temp > 32000) totalities.totalBruto = temp;
  // else if (temp < 0)
  // 	message.error("Nuk mund te llogarisim pagesen pasi zbritjet jane me te medhaja se pagesa");
  // return totalities;
};
