import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-grid-component.scss";

function AgGridComponent({
	onGridReady,
	columnDefs,
	paginationPageSize,
	onCellValueChanged,
	rowData,
	onRowValueChanged,
	onSelectionChanged,
	rowSelection,
	suppressRowClickSelection,
	overlayNoRowsTemplate,
	footer,
    rowGroupPanelShow = 'always',
	onPaginationChanged,
	overlayLoadingTemplate,
	getRowId,
	onFirstDataRendered,
	gridApi,
    statusBar,
    rowHeight,
    headerHeight,
	customDefaultColDef = {}
}) {
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    resizable: true,
    // editable: { edit },
  };

	return (<>
        <div className="ag-grid-component">
            <AgGridReact
                gridApi={gridApi}
                rowData={rowData}
                rowSelection={rowSelection}
                editType={"fullRow"}
                onCellValueChanged={onCellValueChanged}
                onRowValueChanged={onRowValueChanged}
                columnDefs={columnDefs}
                statusBar={statusBar}
                defaultColDef={Object.assign(defaultColDef, customDefaultColDef)}
                onGridReady={onGridReady}
                pagination={true}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                // singleClickEdit={true}
                paginationPageSize={paginationPageSize}
                groupSelectsChildren={true}
                rowGroupPanelShow={rowGroupPanelShow}
                onSelectionChanged={onSelectionChanged}
                enterNavigatesVertically={true}
                enterNavigatesVerticallyAfterEdit={true}
                suppressRowClickSelection={suppressRowClickSelection}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                onPaginationChanged={onPaginationChanged}
                overlayLoadingTemplate={overlayLoadingTemplate}
                onFirstDataRendered={onFirstDataRendered}
                getRowNodeId={getRowId}
                animateRows={true}
                selection={{
                    mode: "cell",
                    enableMultiSelectWithClick: true
                }} />
        </div>
        {footer}
    </>);
}

export default AgGridComponent;
