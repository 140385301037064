import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Form, message, Spin } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import {
  IconKryefaqja,
  Iconkonfigurimet,
  IconPagat,
} from "../../../../../assets/icons/index";
import moment from "moment-timezone";
import "./te_dhenat_akses.scss";
import { Link, useLocation } from "react-router-dom";
import Cardkonfig from "../../../dynamic components/Card";
import KryefaqjaAcessRights from "./KryefaqjaAcessRights";
import KonfigurimetAcessRights from "./KonfigurimetAcessRights";
import RouteAcessRights from "./RouteAcessRights";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../DriveRequest";

function PerdoruesitTeDhenatAksesit() {
  const location = useLocation();
  const [savedData, setSaveData] = useState(location.state);
  const [selcetValue, setSelectValue] = useState(null);
  const { programFields } = useSelector((state) => state.programFields);
  const { Option } = Select;
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const [currentFile, setCurrentFile] = useState();
  const driveRequest = driveApi({ accessToken });

  const [konfig, setKonfig] = useState({
    userRole: "",
  });

  const filterData = programFields?.find(
    (data) => data?.fieldId === "c0156b01-161f-4333-97f3-9f2bdd6bc1fc"
  );

  const currentEmpl = activeEmployees?.find?.(
    (el) =>
      `${el?.employeeFirstName} ${el?.employeeLastName}` ===
      `${savedData?.given_name} ${savedData?.family_name}`
  );

  const handleChange = (objField, e) => {
    const value = objField;
    setKonfig((prev) => ({ ...prev, [value]: e }));
    setSelectValue(e);
  };

  const addItems = () => {
    console.log("konfig", konfig);
    API.put("users", `/users/${savedData.identityId}`, {
      body: { ...konfig },
    });
    message.success("Statusi u ndryshua me sukses");
    // navigate("/konfigurimet/siguria/perdoruesit");
  };

  // const deleteItems = () => {
  //   API.del("users", `/users/${savedData.identityId}`).then((r) => {
  //     window.location.pathname = "/konfigurimet/siguria/perdoruesit";
  //   });
  // };

  const filterOptions = filterData?.fieldOptions?.filter(
    (el) => el !== savedData?.userRole
  );

  const getImage = async () => {
    await driveRequest
      .getImageSrc(currentEmpl?.googleDriveFileId)
      .then((image) => setCurrentFile({ ...image }));
  };

  useEffect(() => {
    if (!!currentEmpl) {
      getImage();
    }
  }, [currentEmpl]);

  return (
    <div className="te-dhenat-akses">
      <div className="te-dhenat-detajet-header">
        <Spin spinning={!!currentFile ? false : true}>
          {currentFile?.src?.length > 0 ? (
            <img
              src={currentFile?.src}
              alt="Flex"
              width={180}
              height={200}
              style={{
                border: "#1D3445 solid 2px",
                padding: 3,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            />
          ) : (
            <div style={{ width: 180, height: 200 }} className="no-photo">
              No photo uploaded
            </div>
          )}
        </Spin>
        <Row
          className="te-dhenat-row"
          style={{ width: "100%", paddingLeft: 20 }}
        >
          <Col className="gutter-row" span={6}>
            <div className="hedaer-first-col">
              <h3>
                {savedData.given_name}&nbsp;{savedData.family_name}
              </h3>
              <h1>{savedData.userRole}</h1>
              <Form onFinish={addItems}>
                <Form.Item name="userRole">
                  <Select
                    placeholder="Selekto Rolin"
                    style={{
                      width: 210,
                      background: "#F5F5F7",
                      borderRadius: 5,
                    }}
                    variant={false}
                    popupClassName="festat-edit-dropdown"
                    suffixIcon={<CaretDownOutlined />}
                    defaultValue={savedData.userRole}
                    onChange={(val) => handleChange("userRole", val)}
                  >
                    {filterOptions?.map((el) => (
                      <Option value={el}>{el}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className={
                      selcetValue ? "konfirmo-succes-button" : "konfirmo-button"
                    }
                    // disabled={selcetValue !== null ? false : true}
                    // disabled={true}
                  >
                    Konfirmo
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className="hedaer-second-col">
              <h3>Data e regjistrimit në portal</h3>
              <h1>{moment(savedData.createdAt).format("DD/MM/YYYY")}</h1>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className="hedaer-third-col">
              <h3>Data e fundit në portal</h3>
              <h1>
                {moment(savedData?.lastLoggedIn).format("DD/MM/YYYY , HH:mm")}
              </h1>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className="hedaer-forth-col">
              <h3>Faqja më aktive</h3>
              <Link to="/kryefaqja">
                <h1>Kryefaqja</h1>
              </Link>
              <Link to="/konfigurimet/siguria/perdoruesit">
                <Button className="te-gjith-btn">Të gjithë përdoruesit</Button>
              </Link>
              <Button disabled={true} className="fshij-btn">
                Fshij përdoruesin
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-te-dhenat">
        <Cardkonfig
          contentChild={
            <KryefaqjaAcessRights
              setSaveData={setSaveData}
              savedData={savedData}
            />
          }
          width="100%"
          height="100%"
          title={
            <div>
              <IconKryefaqja width={15} height={15} />
              &nbsp; Faqja Kryesore
            </div>
          }
        />
        <Cardkonfig
          contentChild={
            <RouteAcessRights setSaveData={setSaveData} savedData={savedData} />
          }
          width="100%"
          height="100%"
          title={
            <div>
              <IconPagat width={15} height={15} />
              &nbsp; Route akses
            </div>
          }
        />
        <Cardkonfig
          contentChild={
            <KonfigurimetAcessRights
              setSaveData={setSaveData}
              savedData={savedData}
            />
          }
          width="100%"
          height="100%"
          title={
            <div>
              <Iconkonfigurimet width={15} height={15} />
              &nbsp; Konfigurimet
            </div>
          }
        />
      </div>
    </div>
  );
}

export default PerdoruesitTeDhenatAksesit;
