import React, { forwardRef } from "react";
import { Popover } from "antd";

import { useSelector } from "react-redux";
import { useAuth } from "../../../../authentication/authHelpers";
import { DragIcon } from "../../../../assets/icons";

export const DashboardLayoutCard = forwardRef(
  (
    {
      style,
      title,
      cardKey,
      children,
      className,
      onMouseUp,
      onTouchEnd,
      onMouseDown,
      handleBlur,
      handleDelete,
      data,
      change,
      ...rest
    },
    ref
  ) => {
    const Component = data.content;

    return (
      <div
        {...{
          className: className + " sortable-item-style",
          ref,
          style,
          onMouseUp,
          onTouchEnd,
          onMouseDown,
          children,
          ...rest,
        }}
        // ref={ref}
      >
        <div className="content-style">
          {data?.name === "ClockIn" ? (
            <DynamicHeader
              item={{ ...data, i: cardKey }}
              handleDelete={handleDelete}
              handleBlur={handleBlur}
              change={change}
              // name={props?.data?.name}
              {...{
                style,
                title,
                cardKey,
                children,
                className,
                onMouseUp,
                onTouchEnd,
                onMouseDown,
                data,
                ...rest,
              }}
            />
          ) : (
            <StaticHeader
              item={{ ...data, i: cardKey }}
              handleDelete={handleDelete}
              handleBlur={handleBlur}
              change={change}
              // name={props?.data?.name}
              {...{
                style,
                title,
                cardKey,
                children,
                className,
                onMouseUp,
                onTouchEnd,
                onMouseDown,
                data,
                ...rest,
              }}
            />
          )}
          <data.src
            data={data}
            content={<Component />}
            blur={data?.blur}
            // width={item?.width}
            // height={item?.height}
          />
          {/* <div>hello</div> */}
        </div>
        {children}
      </div>
    );
  }
);

// export default React.memo(SortableItem);
const DragPopOver = ({ handleDelete, item, handleBlur }) => {
  return (
    <div className="drag-pop-over">
      <span className="action-label" onClick={() => handleDelete(item?.i)}>
        Fshih
      </span>
      <span className="action-label" onClick={() => handleBlur(item?.i)}>
        Konfidenciale
      </span>
    </div>
  );
};

const DynamicHeader = ({ item, handleDelete, handleBlur, change }) => {
  const { clockings } = useSelector((state) => state.clockings);
  const { employeeId } = useAuth();
  let curr =
    clockings
      ?.filter((el) => el?.employeeId === employeeId)
      ?.sort((a, b) => a?.clockInDate - b?.clockInDate) || [];
  let condition =
    curr[curr.length - 1]?.clockInDate !== null &&
    !curr[curr.length - 1]?.clockOutDate &&
    item?.name === "ClockIn";
  return (
    <div
      className="header-dashbord-card-title-container"
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        // background: item?.name !== "ClockIn" ? "#E7EEF2" : "#1DA193",
        borderRadius: "10px 10px 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: condition ? "#EA3943" : "#1DA193",
        cursor: change ? "all-scroll" : "",
      }}
    >
      <span
        className="header-dashbord-card-title"
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19.07px",
          letterSpacing: "0em",
          color: "#FBFBFB",
          marginLeft: "20px",
          textAlign: "center",
        }}
      >
        {item?.emri}
      </span>{" "}
      <Popover
        style={{
          cursor: "pointer",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        placement="rightTop"
        title={"Ndryshime"}
        overlayClassName="antd-more-drag"
        content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
        trigger="hover"
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
          }}
        >
          <DragIcon />{" "}
        </span>{" "}
      </Popover>
    </div>
  );
};
const StaticHeader = ({ item, handleDelete, handleBlur, change }) => {
  return (
    <div
      className="header-dashbord-card-title-container"
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "space-between",
        background: "#E7EEF2",
        borderRadius: "10px 10px 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: change ? "all-scroll" : "",
      }}
    >
      <span
        className="header-dashbord-card-title"
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19.07px",
          letterSpacing: "0em",
          color: "#1D3445",
          marginLeft: "20px",
        }}
      >
        {item?.emri}
      </span>{" "}
      <Popover
        style={{
          cursor: "pointer",
          marginRight: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        placement="rightTop"
        title={"Ndryshime"}
        overlayClassName="antd-more-drag"
        content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
        trigger="hover"
      >
        <span
          style={{
            cursor: "pointer",
            marginRight: "5px",
            marginTop: "5px",
          }}
        >
          <DragIcon fill="#1D3445" />{" "}
        </span>{" "}
      </Popover>
    </div>
  );
};
