import StatusesPanel from "../StatusesPanel/StatusesPanel";
import "./DocumentationView.scss";
import {
  DocumentationListCard,
  DocumentationFileTree,
  TemplateSelectorModal,
} from "./components";
import { memo, useContext, useEffect, useState, useMemo } from "react";
import LoadableComp from "../../commonComponents/LoadableComp/LoadableComp";
import PunonjesitContext from "../../punonjesit/store/PunonjesitContext";
import { useLocation } from "react-router";
import { uuidRegex } from "./documentationViewData";
import { fetchData } from "../../../fetchData";
import { useSelector } from "react-redux";
import { apiRoutes } from "../../../apiRoutes";

const DocumentationView = () => {
  const [activeFilter, setActiveFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const openDocModal = useLocation()?.state?.openDocModal;
  const formattedPath = useLocation()?.pathname.replace(uuidRegex, "");

  const { programFields } = useSelector((state) => state.programFields);

  const {
    employee,
    activeKey,
    documentation,
    populatedStatuses,
    templateModalVisible,
    categoryConfiguration,
    setTemplateModalVisible,
    setCategoryConfiguration,
  } = useContext(PunonjesitContext);

  const filterDocs = (statusName) => {
    setActiveFilter((currFilter) =>
      currFilter === statusName ? "" : statusName
    );
  };

  const documentationMapping = useMemo(() => {
    const fieldObj = programFields?.reduce(
      (acc, { fieldName, fieldOptions }) => ({
        ...acc,
        [fieldName]: fieldOptions,
      }),
      {}
    );
    return fieldObj["Documentation Mapping"];
  }, [programFields]);

  const categoryName = Object.keys(documentationMapping || {}).find(
    (docCategoryName) =>
      documentationMapping[docCategoryName]?.route === formattedPath
  );

  useEffect(() => {
    if (!populatedStatuses.length) {
      return;
    }

    if (!openDocModal) {
      if (!documentation.length) {
        setTemplateModalVisible(true);
      } else {
        setTemplateModalVisible(false);
      }
    }

    setLoading(false);
  }, [documentation, openDocModal]);

  useEffect(() => {
    fetchData(apiRoutes.docConfiguration)
      .then((docConf) => {
        let categoryConf = "";
        for (let i = 0; i < docConf.length; i++) {
          const el = docConf[i];
          if (el?.categoryName === categoryName) categoryConf = el;
        }
        setCategoryConfiguration(categoryConf);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * * @EneaXharau - Added a div between LoadableComp
   * * also added styling to better center Loader
   * * made it dynamic as not to affect other components
   */
  return (
    // <div style={loading ? { height: "100vh" } : {}}>
    <div className="globalPageWrapper">
      <LoadableComp loading={loading}>
        {/* <div className="documentationViewContainer"> */}
          <StatusesPanel
            {...{
              // data: populatedStatuses,
              onClick: filterDocs,
            }}
          />
          <div className="documentationView">
            <DocumentationFileTree />
            <DocumentationListCard activeFilter={activeFilter} />
          </div>
        {/* </div> */}
        <TemplateSelectorModal
          {...{
            visible:
              templateModalVisible &&
              !!categoryConfiguration?.templatesAvailable?.length,
            setVisible: setTemplateModalVisible,
          }}
          recordId={employee?.employeeId}
          recordName={`${employee?.employeeFirstName} ${employee?.employeeLastName}`}
        />
      </LoadableComp>
    </div>
  );
};

export default memo(DocumentationView);
