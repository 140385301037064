import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import moment from "moment-timezone";
import dayjs from "../../../utils/dayjs";
import { checkWhetherEdited } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/validations";
import { EyeFilled, HistoryOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import InnerModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/InnerModal";
import {
  IconKerko,
  IconNgargo,
  // IconVeprime,
  IconExcel,
  IconNdrysho,
  RefreshIcon,
  DeleteIcon,
  ApprovedIcon,
  UnapprovedIcon,
  FilterIconButton,
  SearchIconInput,
} from "../../../assets/icons";
import {
  Input,
  Button,
  Dropdown,
  Menu,
  DatePicker,
  Select,
  message,
  Popconfirm,
  Tooltip,
  Checkbox,
} from "antd";
import FilteringModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/FilteringModal";
// import { weekFiltering } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/filtering";
import {
  approveShifts,
  deleteClockIns,
  disapproveShifts,
  editShift,
  getAllUsers,
} from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
import { matchDepartamentWithEmployee } from "./utils";
import EditShiftModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/EditShiftModal/EditShiftModal";
import AdminShiftLogs from "./adminShiftLogs/AdminShiftLogs";
import NewShiftModal from "./newShiftModal/NewShiftModal";
import LoadableComp from "./../../commonComponents/LoadableComp/LoadableComp";
import {
  // CalendarIcon,
  ClearIcon,
  DropDownIcon,
  IconCalendar,
} from "../../dashboard/assets";
// import SuccessComponent from "../../commonComponents/SuccessComponent/SuccessComponent";
import { useLocation } from "react-router-dom";
import {
  handleDateRange,
  onBranchFilter,
  onDepartmentFilter,
  onEmployeeFilter,
  onPickMonth,
  onStatusFilter,
  onWeekFilter,
} from "./gridApiMethods/filtering";
import {
  // notificationAdminToSecifikUser,
  // notificationToAllAdminAndSecifikUser,
  notificationUserToAdmin,
} from "../../../utils/sendNotification";
import { useAuth } from "../../../authentication/authHelpers";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../Konfigurimet/dynamic components/HtmlExelTabel";
import ExportToPdf from "../../Konfigurimet/dynamic components/ExportToPdf";
import { assignAccessRights } from "../../../constantsFactory/accessRights";
import Swal from "sweetalert2";
import { clockings as clockingsAction } from "../../../store/actions";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
} from "../../../helpers/API/RequestFactory";
import GeneralAdminShift from "./adminShiftLogs/GeneralAdminShiftLogs";
import EditDayPay from "./addition/EditDayPay";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../utils/MobileComponents/MobileHtmlTabel";
import MobileApprovals from "./MobileApprovals";

import "./NewApprovals.scss";
import { weekFiltering } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/filtering";

const { Option } = Select;
const getTime = (time) => {
  if (!!time) {
    return time;
  } else {
    return "";
  }
};
//region APPROVALS()
function Approvals() {
  const columnDefs = [
    {
      headerName: "Nr",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      valueGetter: "node.rowIndex + 1",
      checkboxSelection: true,
      field: "nr",
      filter: "agNumberColumnFilter",
      width: 50,
      minWidth: 50,
      maxWidth: 110,
      filterParams: { apply: true, newRowsAction: "keep" },
    },
    {
      headerName: "Statusi",
      field: "approved",
      filter: "agTextColumnFilter",
      width: 50,
      filterParams: { apply: true, newRowsAction: "keep" },
      valueGetter: ({ data }) => {
        if (data?.approved === true) {
          return "Aprovuar";
        } else {
          return "PaAprovuar";
        }
      },
      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }} onClick={() => onEditShift(data)}>
          <div variant="outlined" cursor="pointer">
            {data?.approved === true ? (
              <Tooltip
                title={`${
                  data?.totalities?.adminLog?.approvedBy
                    ? `Aprovuar nga ${
                        data?.totalities?.adminLog?.approvedBy
                      } - ${dayjs(data?.totalities?.adminLog?.createdAt).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}`
                    : ""
                }`}
              >
                <div
                  style={{
                    background: "#1DA193",
                    // marginTop: "8px",
                    width: "40px",
                    padding: "5px",
                    cursor: "pointer",
                    height: "100%",
                    marginTop: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  <ApprovedIcon />
                </div>
              </Tooltip>
            ) : data?.approved === false ? (
              <Tooltip
                title={`${
                  data?.totalities?.adminLog?.approvedBy
                    ? `Hequr Aprovimi nga ${
                        data?.totalities?.adminLog?.approvedBy
                      } - ${dayjs(data?.totalities?.adminLog?.createdAt).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}`
                    : ""
                }`}
              >
                <div
                  style={{
                    background: "#EA3943",
                    width: "40px",
                    padding: "5px",
                    cursor: "pointer",
                    height: "100%",
                    marginTop: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  <UnapprovedIcon />
                </div>
              </Tooltip>
            ) : (
              <div
                style={{
                  background: "grey",
                  marginTop: "8px",
                  cursor: "pointer",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Përcaktuar
              </div>
            )}
          </div>
        </div>
      ),
    },
    // {
    // 	headerName: "Emri",
    // 	field: "employee_details.employeeFirstName",
    // 	filterParams: {
    // 		readOnly: true,
    // 	},
    // 	// valueGetter: ({ data }) =>
    // 	// 	data?.employee_details?.employeeFirstName + " " + data?.employee_details?.employeeLastName,
    // },

    // {
    // 	headerName: "Mbiemri",
    // 	field: "employee_details.employeeLastName",
    // 	filterParams: {
    // 		readOnly: true,
    // 	},
    // },
    {
      headerName: "Punonjesi",
      filterParams: { apply: true, newRowsAction: "keep" },

      valueGetter: ({ data }) =>
        data?.employee_details?.employeeFirstName +
        " " +
        data?.employee_details?.employeeLastName,
      filterParams: { readOnly: true },
    },
    {
      headerName: "Team",
      filterParams: { apply: true, newRowsAction: "keep" },
      // filter: "agTextColumnFilter",
      field: "employee_details.employeeTeam",
      valueGetter: ({ data }) => {
        return data?.employee_details?.employeeTeam || "";
      },
      // filterParams: { readOnly: true },
    },
    // {
    // 	headerName: "Punesimi",
    // 	field: "employee_details.employeeStatus",
    // 	filterParams: {
    // 		readOnly: true,
    // 	},
    // },
    {
      headerName: "Departamenti",
      field: "employee_details.employeeDepartmentName",
      filterParams: { apply: true, newRowsAction: "keep" },
      // valueGetter: (params) => {
      // 	// console.log("DADAFWF", params.data);
      // 	try {
      // 		return matchDepartamentWithEmployee(
      // 			params.data.employee_details.employeeDepartmentId,
      // 			departments
      // 		);
      // 	} catch (err) {
      // 		console.log("createdAt--err");
      // 	}
      // },
      // cellRenderer: (params) => {
      //   try {
      //     return matchDepartamentWithEmployee(params.value, departments);
      //   } catch (err) {
      //     console.log("createdAt--err");
      //   }
      // },
    },

    {
      headerName: "Data",
      field: "data",
      filter: "agNumberColumnFilter",
      filterParams: { apply: true, newRowsAction: "keep" },
      sort: "desc",

      cellRenderer: (params) => {
        try {
          return dayjs(params.value).format("DD/MM/YYYY");
        } catch (err) {
          console.log("createdAt--err");
          return "";
        }
      },
      filterParams: {
        inRangeInclusive: true,
      },
    },
    {
      headerName: "Kategoria",
      field: "dayType",
      filterParams: { apply: true, newRowsAction: "keep" },
      hide: false,
      cellRenderer: ({ data }) => {
        switch (data?.dayType) {
          case "Regular":
            return "Normal";
          case "Sick":
            return "Sëmurë";
          case "Maternity":
            return "Leje Lindje";
          case "NationalHoliday":
            return "Festë Zyrtare";
          case "NationalHolidayWorking":
            return "Festë Zyrtare në Punë";
          case "HolidayRequest":
            return "Kërkesë Leje";
          default:
            return "";
        }
      },
    },
    {
      headerName: "Fillimi i turnit",
      field: "fillim_turni",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => {
        return (
          <div>
            <div variant="outlined" cursor="pointer">
              {console.log(data?.fillim_turni)}
              {dayjs(data?.fillim_turni).format("HH:mm")}{" "}
              {
                // checkWhetherEdited(aggridData, data?.clockingId, "editedClockIn") === true ||
                data?.clockInNote ? (
                  <EyeFilled
                    style={styleEyeIcon}
                    onClick={() => showModal(data, "clockIn")}
                  />
                ) : null
              }
            </div>
          </div>
        );
      },
      filterParams: { apply: true, newRowsAction: "keep" },
    },
    {
      headerName: "Pushimi",
      // field: "fillim_turni",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => {
        let breakTime =
          getTime(data?.employee_details?.breakTime?.[0]) +
          " - " +
          getTime(data?.employee_details?.breakTime?.[1]);
        return (
          <div>
            <div variant="outlined" cursor="pointer">
              {breakTime}
            </div>
          </div>
        );
      },
      filterParams: { apply: true, newRowsAction: "keep" },
    },
    {
      headerName: "Pushimi i paguar",
      // field: "fillim_turni",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => {
        let isBreakTimePaid = data?.employee_details?.isBreakTimePaid
          ? "Po"
          : "Jo";
        return (
          <div>
            <div variant="outlined" cursor="pointer">
              {isBreakTimePaid}
            </div>
          </div>
        );
      },
      filterParams: { apply: true, newRowsAction: "keep" },
    },
    {
      headerName: "Mbarimi i turnit",
      field: "mbarim_turni",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => (
        <div>
          <div variant="outlined" cursor="pointer">
            {dayjs(data.mbarim_turni).format("HH:mm") !== "Invalid date" ? (
              dayjs(data.mbarim_turni).format("HH:mm")
            ) : (
              <h1 style={{ color: "#EA3943" }}>–</h1>
            )}{" "}
            {checkWhetherEdited(clockins, data.clockingId, "editedClockOut") ===
              true || data?.clockOutNote ? (
              <EyeFilled
                style={styleEyeIcon}
                onClick={() => showModal(data, "clockOut")}
              />
            ) : null}
          </div>
        </div>
      ),
      filterParams: { apply: true, newRowsAction: "keep" },
    },
    {
      headerName: "Oret Totale",
      field: "oret_totale",
      filter: "agNumberColumnFilter",
      filterParams: { apply: true, newRowsAction: "keep" },

      cellRenderer: ({ data }) => (
        <div>
          <strong>{isNaN(data.oret_totale) ? "0.00" : data.oret_totale}</strong>
        </div>
      ),
    },
    {
      headerName: "Krijuar",
      // field: "oret_totale",
      // filter: "agNumberColumnFilter",
      filterParams: { apply: true, newRowsAction: "keep" },

      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined" cursor="pointer">
            <div
              style={{
                background: "#D9F7FA",
                marginTop: "8px",
                width: "60px",
                cursor: "pointer",
                height: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                // color: "white",
              }}
            >
              {!!data?.clockingLogs
                ? dayjs(data?.clockingLogs[0]?.createdAt).format("HH:mm")
                : "-"}
            </div>
          </div>
        </div>
        // <div
        // 	style={{
        // 		background: "#FAF0CA",
        // 		width: "100%",
        // 		display: "flex",
        // 		justifyContent: "center",
        // 		alignItems: "center",
        // 	}}
        // >
        // 	<div
        // 		style={{
        // 			height: 30,
        // 		}}
        // 	>
        // 		{!!data?.clockingLogs ? moment(data?.clockingLogs[0]?.createdAt).format("HH:mm") : "-"}
        // 	</div>
        // </div>
      ),
    },
    {
      headerName: "Perfunduar",
      // field: "oret_totale",
      // filter: "agNumberColumnFilter",
      filterParams: { apply: true, newRowsAction: "keep" },

      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined" cursor="pointer">
            <div
              style={{
                background: "#D9F7FA",
                marginTop: "8px",
                width: "60px",
                cursor: "pointer",
                height: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                // color: "white",
              }}
            >
              {!!data?.finishedAt
                ? dayjs(data?.finishedAt).format("HH:mm")
                : "-"}
            </div>
          </div>
        </div>
        // <div
        // 	style={{
        // 		background: "#FAF0CA",
        // 		width: "100%",
        // 		display: "flex",
        // 		justifyContent: "center",
        // 		alignItems: "center",
        // 	}}
        // >
        // 	<div
        // 		style={{
        // 			height: 30,
        // 		}}
        // 	>
        // 		{!!data?.clockingLogs ? moment(data?.clockingLogs[0]?.createdAt).format("HH:mm") : "-"}
        // 	</div>
        // </div>
      ),
    },
    {
      headerName: "employeeId",
      filter: "agTextColumnFilter",

      field: "employee_details.employeeId",
      hide: true,
      filterParams: { apply: true, newRowsAction: "keep" },
    },
    {
      headerName: "Pagesa",
      field: null,
      // filterParams: { apply: true, newRowsAction: "keep" },
      // cellRenderer: ({ data }) => (
      // 	<div
      // 		style={{
      // 			display: "flex",
      // 			// justifyContent: "center",
      // 			alignItems: "center",
      // 		}}
      // 		cursor="pointer"
      // 		onClick={() => onShiftAdminLog(data)}
      // 	>
      // 		<span
      // 			style={{
      // 				display: "flex",
      // 				justifyContent: "center",
      // 				alignItems: "center",
      // 				width: "100px",
      // 				cursor: "pointer",
      // 				height: "22px",
      // 				marginTop: "8px",
      // 				background: "#1DA193",
      // 				color: "white",
      // 				border: "none",
      // 				borderRadius: "5px",
      // 			}}
      // 			// onClick={() => onShiftAdminLog(data)}
      // 		>
      // 			Historiku
      // 		</span>
      // 	</div>
      // ),
      cellRenderer: (params) => {
        const data = params?.data;
        try {
          if (data?.approved === true) {
            return <EditDayPay data={data} getShiftData={getShiftData} />;
          } else {
            return "";
          }
        } catch (e) {
          console.error("error on cellRenderer", e);
        }
      },
    },
    {
      headerName: "Ndryshimet",
      field: null,
      filterParams: { apply: true, newRowsAction: "keep" },

      cellRenderer: ({ data }) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
          cursor="pointer"
          onClick={() => onShiftAdminLog(data)}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: "100px",
              // cursor: "pointer",
              // height: "22px",
              // marginTop: "8px",
              background: "#1DA193",
              // color: "white",
              // border: "none",
              // borderRadius: "5px",
              width: "42px",
              padding: "5px",
              cursor: "pointer",
              height: "30px",
              marginTop: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              color: "white",
            }}
            // onClick={() => onShiftAdminLog(data)}
          >
            <HistoryOutlined />
            {/* Historiku */}
          </span>
        </div>
      ),
    },
  ];
  let auth = useAuth();
  const [aggridData, setAggridData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedShift, setEditedShift] = useState(null);
  // const { users } = useSelector((state) => state.usersList);
  const [clockins, setClockins] = useState([]);
  // const [employees, setEmployees] = useState(null);
  // const [departments, setDepartments] = useState(null);
  const { clockings } = useSelector((state) => state.clockings);
  const { departments } = useSelector((state) => state.departments);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const { employees: allEmployees, activeEmployees } = useSelector(
    (state) => state.employeesList
  );
  const { users } = useSelector((state) => state.usersList);

  const employees = showActiveOnly ? activeEmployees : allEmployees;

  const [editShiftModalVisibility, setEditShiftModalVisibility] =
    useState(false);
  const [editShiftModalData, setEditShiftModalData] = useState([]);
  const [shiftLogsVisibility, setShiftLogsVisibility] = useState(false);
  const [shiftAdminLog, setShiftAdminLog] = useState(null);
  const [shiftAdminGeneralLog, setShiftAdminGeneralLog] = useState(null);
  const [newShiftVisib, setNewShiftVisib] = useState(false);
  const [userat, setUsers] = useState(null);
  const [approvedEnable, setApprovedEnable] = useState(false);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const [activeFiltering, setActiveFiltering] = useState({
    department: null,
    employee: null,
    status: null,
    month: dayjs(),
    week: [],
    employeeTeam: null,
  });
  const [loading, setLoading] = useState(true);
  const [alreadyInit, setInit] = useState(false);
  // const [currentGridPage, setCurrentGridPage] = useState(null);
  const [paginationGrid, setPaginationGird] = useState(null);

  let location = useLocation();
  const dispatch = useDispatch();

  // const { users } = useSelector((state) => state.usersList);
  const approveMenu = (
    <Menu width={200} height={200}>
      <Menu.Item key="1" height={30} onClick={() => onApprove("approve")}>
        Aprovo
      </Menu.Item>
      <Menu.Item key="2" height={30} onClick={() => onApprove("disapprove")}>
        Hiqe Aprovimin
      </Menu.Item>
    </Menu>
  );

  console.log("employees", employees);

  //region ON APPROVE
  const onApprove = async (type) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    const isThereUnfinished = selectedData?.find(
      (el) => el?.clockOutDate === null
    );
    console.log("Selected Data", selectedData);
    if (!isThereUnfinished) {
      if (selectedData?.length <= 20) {
        const hide = message.loading("Action in progress...", 0);

        if (type === "approve") {
          // if (selectedData.filter((el) => el.approved === true).length > 0) {
          // 	message.error("Ju nuk mund te zgjidhni dy statuse te ndryshme!");
          // 	gridApi.deselectAll();
          // } else
          if (selectedData.length > 0) {
            let adminlog = {
              approved: true,
              createdAt: dayjs().valueOf(),
              approvedBy:
                auth?.userAccess[0]?.given_name +
                " " +
                auth?.userAccess[0]?.family_name,
            };
            await approveShifts(selectedData, adminlog).then(() => {
              setTimeout(hide, 0);

              // selectedData?.map((sd) =>
              // 	notificationUserToAdmin(
              // 		{
              // 			notificationTitle: "Turni u Aprovua",
              // 			createdAt: moment().format("DD/MM/YYYY"),
              // 			notificationPriority: false,
              // 			notificationCategory: "Info",
              // 			notificationBody: `${auth?.userAccess[0]?.given_name} ${
              // 				auth?.userAccess[0]?.family_name
              // 			} aprovoi turnin e ${sd.employee_details.employeeFirstName} ${
              // 				sd.employee_details.employeeLastName
              // 			} për datën ${moment(sd.clockInDate).format("DD/MM/YYYY")}`,
              // 			isAnnouncement: false,
              // 			notificationUrl: "/aprovimet",
              // 			notificationFirstName: auth?.userAccess[0]?.given_name,
              // 			notificationLastName: auth?.userAccess[0]?.family_name,
              // 		},
              // 		userat?.allUsers?.Items
              // 	)
              // );

              setTimeout(() => {
                getShiftData();
                refreshActiveFilters();
              }, 2000);
              gridApi.deselectAll();
            });
          }
        }
        if (type === "disapprove") {
          if (selectedData.filter((el) => el.approved === false).length > 0) {
            message.error("Ju nuk mund te zgjidhni dy statuse te ndryshme");
            gridApi.deselectAll();
          } else if (
            selectedData.filter((el) => el.approved === false).length === 0 &&
            selectedData.length > 0
          ) {
            let adminlog = {
              approved: false,
              createdAt: dayjs().valueOf(),
              approvedBy:
                auth?.userAccess[0]?.given_name +
                " " +
                auth?.userAccess[0]?.family_name,
            };
            // console.log("selected data", selectedData);
            disapproveShifts(selectedData, adminlog);

            setTimeout(() => {
              getShiftData();
              setTimeout(hide, 0);
              refreshActiveFilters();
            }, 1400);
            gridApi.deselectAll();
          }
        }
      } else {
        message.error(
          "Ju nuk mund te zgjidhni me shume se 20 turne per aprovim!"
        );
      }
    } else {
      message.error("Ju nuk mund te zgjidhni turne te pambyllura!");
    }
  };

  const handleSelectionChanged = () => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setApprovedEnable(true);
    } else {
      setApprovedEnable(false);
    }
  };

  const onShiftAdminLog = (log) => {
    console.log({ log });
    setShiftAdminLog(log);
    setShiftLogsVisibility(true);
  };

  const onEditShift = (e) => {
    setEditShiftModalData([e]);
    setEditShiftModalVisibility(true);
  };

  const onRefresh = () => {
    setTimeout(() => {
      getShiftData();
    }, 3000);
  };

  const changeActiveFilter = (key, val) => {
    setActiveFiltering((prevState) => ({ ...prevState, [key]: val }));
  };

  const refreshActiveFilters = () => {
    console.log("refresh", activeFiltering);
    if (!!activeFiltering.employeeTeam) {
      onBranchFilter(activeFiltering.employeeTeam, gridApi);
    }
    if (!!activeFiltering.employee) {
      onEmployeeFilter(
        activeFiltering.employee,
        gridApi,
        "employee_details.employeeId"
      );
    }
    if (!!activeFiltering.department) {
      onDepartmentFilter(
        activeFiltering.department,
        gridApi,
        "employee_details.employeeDepartmentName"
      );
    }
    if (!!activeFiltering.status) {
      onStatusFilter(activeFiltering.status, gridApi, "approved");
    }
  };

  const onSaveEditSuccess = (
    clockingId,
    editedShift,
    employeeId,
    adminLog,
    res
  ) => {
    setTimeout(() => {
      getShiftData();
      refreshActiveFilters();
    }, 2000);

    // console.log("saved", clockingId, editedShift, employeeId, adminLog);
  };

  const onDelete = async () => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);

    await deleteClockIns(selectedData).then(() => {
      setTimeout(
        () => {
          getShiftData();
        },
        selectedData.length < 5 ? 1400 : 3000
      );
    });

    gridApi.deselectAll();
  };

  const handlePassword = (u) => {
    let users1 = u;
    Swal.fire({
      title: "Vendos kodin personal!",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Konfirmo",
      cancelButtonText: "Anulo",
      confirmButtonColor: "#1DA193",
      cancelButtonColor: "#EA3943",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("usersa", users1);
        if (result.value === users?.pinNumber) {
          onDelete();
          message.success("Kodi i vendosur eshte i sakte!");
        } else {
          message.error("Kodi i vendosur eshte gabim!");
          handlePassword(u);
        }
      }
    });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleSearch = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
    setAgGridSearchInput(e.target.value);
  };

  const showModal = (val, type) => {
    setIsModalVisible(true);
    setEditedShift([val, type]);
  };

  console.log("activeFiltering", activeFiltering);

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
    setAgGridSearchInput(null);
    setActiveFiltering({
      employee: null,
      month: null,
      status: null,
      employeeTeam: null,
    });
  };

  const getRespectiveData = async (monthFilter) => {
    if (dayjs(monthFilter).format("YYYY-MM") === dayjs().format("YYYY-MM")) {
      return await requestDataWithParams(
        "clocking",
        dayjs(monthFilter).subtract(1, "month").startOf("month").valueOf(),
        dayjs(monthFilter).endOf("month").add(14, "days").valueOf()
      );
    } else {
      return await requestDataWithParams(
        "clocking",
        dayjs(monthFilter).subtract(1, "month").startOf("month").valueOf(),
        dayjs(monthFilter).endOf("month").add(14, "days").valueOf()
      );
    }
  };

  const getShiftData = async (e) => {
    const monthFilter = !!e ? e : activeFiltering?.month;
    return await getRespectiveData(monthFilter).then((res) => {
      console.log("data w params", res);
      setAggridData(
        res
          ?.filter(
            (el) =>
              el.clockInDate !== null &&
              !!el?.employeeId &&
              dayjs(el.clockInDate).format("MMMM YYYY") ===
                monthFilter.format("MMMM YYYY")
          )
          .sort((a, b) => b.clockInDate - a.clockInDate)
          .map((el, idx) => ({
            ...el,
            nr: idx,
            data: el.clockInDate,
            fillim_turni: el?.clockInDate,
            mbarim_turni: el?.clockOutDate,
            clockingId: el.clockingId,
            // employeeTeam: employees.find((emp) => emp.employeeId === el.employeeId)
            // 	?.employeeTeamName,
            employee_details: employees.find(
              (emp) => emp.employeeId === el.employeeId
            ),
            oret_totale: dayjs(el?.clockOutDate)
              .diff(dayjs(el?.clockInDate), "hours", true)
              .toFixed(2),
          }))
      );
      dispatch(clockingsAction(res));
      setClockins(res);
    });
  };

  const initializeData = () => {
    setLoading(true);
    const tempData = clockings
      ?.filter(
        (el) =>
          el.clockInDate !== null &&
          dayjs().format("MMMM YYYY") ===
            dayjs(el.clockInDate).format("MMMM YYYY")
      )
      .sort((a, b) => b.clockInDate - a.clockInDate)
      .map((el, idx) => ({
        ...el,
        nr: idx,
        data: el.clockInDate,
        fillim_turni: el?.clockInDate,
        mbarim_turni: el?.clockOutDate,
        clockingId: el.clockingId,
        employee_details: employees.find(
          (emp) => emp.employeeId === el.employeeId
        ),
        oret_totale: dayjs(el?.clockOutDate)
          .diff(dayjs(el?.clockInDate), "hours", true)
          .toFixed(2),
      }));
    setAggridData(tempData);
    setClockins(tempData);
    setLoading(false);
  };

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  const onChangeMonth = async (e) => {
    const hide = message.loading(
      "Retrieving latest data, this might take a while...",
      0
    );

    changeActiveFilter("month", e);
    await getShiftData(e);
    onPickMonth(e, gridApi);
    setTimeout(hide, 0);
    // } else {
    // 	changeActiveFilter("month", e);
    // 	await getShiftData(e);
    // 	onPickMonth(e, gridApi);
    // 	setTimeout(hide, 0);
    // }
  };

  // const onPaginationChanged = (e) => {
  // 	setPaginationGird(e.api);
  // 	if (e.api?.paginationGetCurrentPage() > 0) {
  // 		setCurrentGridPage(e.api.paginationGetCurrentPage());
  // 	}
  // };

  // useMemo(async () => {
  // 	let pageNr = JSON.parse(window.localStorage.getItem("gridPageApprovals"));
  // 	let parseNr = parseInt(pageNr);
  // 	if (!!parseNr && !!paginationGrid) {
  // 		await paginationGrid?.paginationGoToPage(parseNr);
  // 	}
  // }, [paginationGrid]);

  // useMemo(async () => {
  // 	if (currentGridPage !== null && !!currentGridPage) {
  // 		window.localStorage.setItem("gridPageApprovals", currentGridPage === 1 ? 0 : currentGridPage);
  // 	}
  // }, [currentGridPage]);

  // useLayoutEffect(() => {
  // 	if (activeEmployees !== null && departments !== null) {
  // 		message.info("second time");
  // 		getShiftData();
  // 	}
  // }, [activeEmployees, departments]);

  // useEffect(() => {
  // 	if (gridApi !== null && location.state !== null) {
  // 		!!location?.state?.data?.muaji && onPickMonth(location.state.data.muaji, gridApi);
  // 		!!location?.state?.paga &&
  // 			onEmployeeFilter(location.state.paga.employeeId, gridApi, "employee_details.employeeId");
  // 		// console.log("Locationnnn", location);
  // 	}
  // }, [gridApi, location, aggridData]);

  useLayoutEffect(() => {
    if (
      !!users &&
      !!departments &&
      !!employees &&
      !!clockings &&
      alreadyInit === false
    ) {
      // initializeData();
      getShiftData();
      setInit(true);
    }
  }, [users, departments, employees, clockings]);

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // useEffect(() => {
  // 	if (!!gridApi) {
  // 		onPickMonth(moment(), gridApi);
  // 	}
  // }, [gridApi]);

  //region RETURN
  return (
    <LoadableComp loading={!!aggridData ? false : true}>
      <div className="globalPageWrapper">
        {isMobileView ? (
          <div className="new-shift-approvals">
            <div className="shiftFiltersContainer">
              <div className="more-shift-filters2">
                <Select
                  className="new-shift-filter-input"
                  onChange={(e) => {
                    onDepartmentFilter(
                      e,
                      gridApi,
                      "employee_details.employeeDepartmentName"
                    );
                    changeActiveFilter("department", e);
                  }}
                  allowClear={{ clearIcon: <ClearIcon /> }}
                  suffixIcon={<DropDownIcon />}
                  showSearch
                  value={activeFiltering?.department}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Zgjidh Departamentin"
                >
                  {departments?.map((el) => (
                    <Option value={el.departmentName}>
                      {el.departmentName}
                    </Option>
                  ))}
                  {/* <Option value="jack">Jack</Option> */}
                </Select>
                <Select
                  className="new-shift-filter-input"
                  onChange={(e) => {
                    onEmployeeFilter(e, gridApi, "employee_details.employeeId");
                    changeActiveFilter("employee", e);
                  }}
                  allowClear={{ clearIcon: <ClearIcon /> }}
                  suffixIcon={<DropDownIcon />}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={
                    !!location?.state?.paga?.employeeId
                      ? location.state.paga.employeeId
                      : null
                  }
                  showSearch
                  value={activeFiltering?.employee}
                  placeholder="Zgjidh Punonjesin"
                >
                  {employees?.map((el) => (
                    <Option value={el.employeeId}>
                      {el.employeeFirstName + " " + el.employeeLastName}
                    </Option>
                  ))}{" "}
                </Select>
                <Select
                  className="new-shift-filter-input"
                  showSearch
                  suffixIcon={<DropDownIcon />}
                  value={activeFiltering?.status}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    onStatusFilter(e, gridApi);
                    changeActiveFilter("status", e);
                  }}
                  allowClear={{ clearIcon: <ClearIcon /> }}
                  placeholder="Zgjidh statusin"
                >
                  <Option value={"Aprovuar"}>Aprovuar</Option>
                  <Option value={"PaAprovuar"}>Pa Aprovuar</Option>
                </Select>
                <Select
                  className="new-shift-filter-input"
                  showSearch
                  suffixIcon={<DropDownIcon />}
                  value={activeFiltering?.employeeTeam}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    onBranchFilter(e, gridApi, "employee_details.employeeTeam");
                    changeActiveFilter("employeeTeam", e);
                  }}
                  allowClear={{ clearIcon: <ClearIcon /> }}
                  placeholder="Zgjidh Branch"
                >
                  <Option value={"Flex Tirana"}>Flex Tirana</Option>
                  <Option value={"Flex Prishtina"}>Flex Prishtina</Option>
                </Select>

                <DatePicker
                  className="new-shift-filter-datepicker"
                  allowClear={{ clearIcon: <ClearIcon /> }}
                  defaultValue={
                    location.state !== null
                      ? dayjs(location.state.data.muaji)
                      : false
                  }
                  value={activeFiltering?.month}
                  suffixIcon={<IconCalendar />}
                  picker="month"
                  format={"MMMM YYYY"}
                  placeholder="Zgjidhni muajin"
                  onChange={(e) => {
                    onChangeMonth(e);
                  }}
                />
                <DatePicker.RangePicker
                  className="date-filter"
                  format="DD/MM/YYYY"
                  value={weekFiltering(
                    dayjs(),
                    activeFiltering?.week || "month",
                    true
                  )}
                  onChange={(e) => {
                    handleDateRange(e, gridApi);
                  }}
                />
                <Checkbox
                  checked={showActiveOnly}
                  value={showActiveOnly}
                  onChange={(e) => setShowActiveOnly(e.target.checked)}
                  className="activeEmployeesCheckbox"
                >
                  <span>Shfaq vetem Punonjesit Aktiv</span>
                </Checkbox>
                <div className="employee-filter">
                  {/* <Select showSearch allowClear placeholder="Zgjidh Kategorine">
            <Option value="jack">Jack</Option>
          </Select> */}
                </div>
              </div>
            </div>
            <div className="employee-shifts-approvals">
              <div className="ag-grid-shifts-approvals">
                <div className="ag-grid-header">
                  <button
                    className="refresh-data-btn"
                    onClick={() => getShiftData()}
                  >
                    <RefreshIcon />
                  </button>
                  <Input
                    className="searchInput"
                    placeholder="   Kërko..."
                    prefix={<SearchIconInput width={15} height={15} />}
                    onChange={handleSearch}
                    value={agGridSearchInput}
                  />
                  <div className="header-icons">
                    <FilteringModal
                      clearFilters={clearFilters}
                      onWeekFilter={(e) => {
                        onWeekFilter(e, gridApi);
                        setActiveFiltering((prev) => ({ ...prev, week: e }));
                      }}
                    />
                    <ReactHtmlTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="Aprovimet-homePage"
                      filename="aprovimet"
                      sheet="aprovimet"
                      buttonText={
                        <Tooltip
                          placement="top"
                          overlayClassName="global-icon-tooltip"
                          title={"Eksporto në eksel"}
                        >
                          <IconExcel />
                        </Tooltip>
                      }
                    />
                    <ExportToPdf
                      id={"#Aprovimet-homePage"}
                      tabelName={"aprovimet"}
                    />
                    <Tooltip
                      placement="top"
                      overlayClassName="global-icon-tooltip"
                      title={"Printo tabelën"}
                    >
                      <IconNgargo onClick={handlePrint} />
                    </Tooltip>
                    <Popconfirm
                      title="A jeni te sigurt qe deshironi te fshini kete turn?"
                      okText="Po, fshi!"
                      cancelText="Anulo!"
                      onConfirm={() => handlePassword(users)}
                      disabled={approvedEnable ? false : true}
                    >
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Fshij nje rekord"}
                      >
                        <DeleteIcon
                          style={{ marginLeft: 10, marginRight: 20 }}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                  <div className="clear-filters-shift" onClick={clearFilters}>
                    <button>
                      <FilterIconButton width={18} height={18} />
                    </button>
                  </div>{" "}
                  <div
                    className="logs-button"
                    onClick={() => setShiftAdminGeneralLog(true)}
                  >
                    <button>LOGS</button>
                  </div>{" "}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: 10,
                    }}
                  >
                    <div className="new-shift">
                      <button onClick={() => setNewShiftVisib(true)}>
                        Krijo nje hyrje
                      </button>
                    </div>
                    <Dropdown.Button
                      onClick={() => onApprove("approve")}
                      className="approve-buttons"
                      overlay={approveMenu}
                      disabled={approvedEnable ? false : true}
                    >
                      Aprovo
                    </Dropdown.Button>
                  </div>
                  <div className="refresh-data"></div>
                </div>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridComponent
                    rowData={aggridData}
                    rowSelection={"multiple"}
                    onGridReady={onGridReady}
                    paginationPageSize={20}
                    has
                    columnDefs={columnDefs}
                    suppressRowClickSelection={true}
                    onSelectionChanged={handleSelectionChanged}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      filter: true,
                    }}
                    // onPaginationChanged={onPaginationChanged}
                  />
                </div>
              </div>
              <HtmlExelTabel
                tableData={aggridData?.map(
                  ({
                    approved,
                    employee_details,
                    data,
                    clockInDate,
                    clockOutDate,
                    oret_totale,
                  }) => ({
                    approved: approved ? "Aprovuar" : "Pa Aprovuar",
                    emri: employee_details?.employeeFirstName,
                    mbiemri: employee_details?.employeeLastName,
                    departamenti: employee_details?.employeeDepartmentName,
                    data: dayjs(data).format("DD/MM/YYYY"),
                    clockInDate: dayjs(clockInDate).format("HH:mm"),
                    clockOutDate: dayjs(clockOutDate).format("HH:mm"),
                    oret_totale,
                  })
                )}
                header={[
                  "Statusi",
                  "Emri",
                  "Mbiemri",
                  "Departamenti",
                  "Data",
                  "Fillimi i turnit",
                  "Mbarimi i turnit",
                  "Orët totale",
                ]}
                id="Aprovimet-homePage"
                componentRef={componentRef}
              />
              <InnerModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                {...{ editedShift, clockins }}
              />
              {editShiftModalVisibility && (
                <EditShiftModal
                  {...{
                    editShiftModalVisibility,
                    setEditShiftModalVisibility,
                    editShiftModalData,
                    onRefresh,
                    onSaveEditSuccess,
                  }}
                />
              )}
              <AdminShiftLogs
                users={userat}
                {...{
                  setShiftAdminLog,
                  shiftLogsVisibility,
                  setShiftLogsVisibility,
                  shiftAdminLog,
                }}
              />
              {shiftAdminGeneralLog && (
                <GeneralAdminShift
                  shiftLogsVisibility={shiftAdminGeneralLog}
                  setShiftLogsVisibility={setShiftAdminGeneralLog}
                />
              )}
            </div>
          </div>
        ) : (
          <MobileApprovals
            aggridData={aggridData}
            setNewShiftVisib={setNewShiftVisib}
            activeEmployees={employees}
            location={location}
          />
        )}
      </div>
      <NewShiftModal
        {...{
          employees: employees,
          newShiftVisib,
          setNewShiftVisib,
          editShiftModalData,
          onRefresh,
        }}
      />
    </LoadableComp>
  );
}

const styleEyeIcon = {
  color: "#fff",
  padding: 3,
  borderRadius: 3,
  backgroundColor: "#1DA193",
};
export default Approvals;
