import { useContext, useMemo } from "react";
import FormCard from "../FormCard/FormCard";
import { StatusSquircle } from "./components";
import "./StatusesPanel.scss";
import PunonjesitContext from "../../punonjesit/store/PunonjesitContext";

const StatusesPanel = ({ data = [], className = "", onClick = null }) => {
  const { populatedStatuses = undefined } = useContext(PunonjesitContext);

  return (
    (<FormCard
      className={`statusesPanelContainer ${className}`.trim()}
      childrenClassName="statusesPanelContent"
    >
      {(populatedStatuses || data)?.map(
        ({ statusName, statusColor: color, value }, key) => (
          //* maps all statuses
          (<StatusSquircle {...{ key, statusName, color, value, onClick }} />)
        )
      )}
    </FormCard>)
  );
};

export default StatusesPanel;
