import {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import AgGridComponent from "../../AG-grid/AgGridComponent";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../AG-grid/ag-grid-component.scss";
import "./../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftGrid.scss";
// import InnerModal from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/InnerModal";
import "../approvals/Approvals.scss";
import "../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftGrid.scss";
import {
  IconKerko,
  IconNgargo,
  IconExcel,
  IconNdrysho,
  RefreshIcon,
  DeleteIcon,
  FilterIconButton,
} from "../../../assets/icons";
import {
  Input,
  Button,
  DatePicker,
  Select,
  //   Popconfirm,
  //   message,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import { deleteClockIns } from "../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
import LoadableComp from "./../../commonComponents/LoadableComp/LoadableComp";
import { ClearIcon, DropDownIcon, IconCalendar } from "../../dashboard/assets";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onDepartmentFilter,
  onEmployeeFilter,
  onEmployeeWageTypeFilter,
} from "../approvals/gridApiMethods/filtering";
import { useSelector } from "react-redux";
import { calculateWagePerHours } from "./utils/calculations";
import { paySubFactory } from "../PaySub/employeeCalc/employeeSubFactory";
import { useReactToPrint } from "react-to-print";
import GenerateModal from "./GenerateModal";
import NumericCellEditor from "./utils/numericCellEditor.jsx";
import EditEmployeeWage from "./editables/EditEmployeeWage";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../Konfigurimet/dynamic components/HtmlExelTabel";
import { retrieveRelevantWages } from "./utils/retrieve";
import MultiGenerate from "./multiGenerate/MultiGenerate";
import { currencyformatter } from "./utils/formatter";
import WagesHeader from "./WagesHeader";
import { wagesColumnDefs } from "./utils/columnDefs";
import DynamicFooter from "../../AG-grid/dynamicFooter/DynamicFooter";
import { useAuth } from "../../../authentication/authHelpers";
import PageNotFound from "../../commonComponents/PageNotFound";
import ExportToPdf from "../../Konfigurimet/dynamic components/ExportToPdf";
import { useDispatch } from "react-redux";
import {
  deductions,
  earnings,
  employeeRequests,
  prepayments,
  users,
  wages as wagesAction,
  clockings as clockingsAction,
} from "./../../../store/actions";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
} from "../../../helpers/API/RequestFactory";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../utils/MobileComponents/MobileHtmlTabel";
import FooterCounter from "../../commonComponents/FooterCounter/FooterCounter.jsx";
import AgGridHeader from "../../commonComponents/AgGridHeader/AgGridHeader.jsx";

const EXPORT_COLS = [
  { en: "employeeFirstName", al: "Emri" },
  { en: "employeeLastName", al: "Mbiemri" },
  { en: "employeeDepartmentName", al: "Departamenti" },
  { en: "employeePayrollType", al: "Lloji i pagesës" },
  { en: "periudha", format: "MMMM YYYY", al: "Periudha" },
  { en: "employeeSalaryPerHour", al: "Pagesa për orë" },
  { en: "wageStatus", al: "Statusi" },
  { en: "calculatedWage", al: "Paga e llogaritur" },
];

const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};
const TEAM_FIELD_ID = "2e9f7112-1775-4b69-b55e-3134333bfdf4";

function Wages() {
  const auth = useAuth();
  const { Option } = Select;
  const [columnDefs, setColumnDefs] = useState(null);
  const [viewType, setView] = useState(null);
  const [aggridData, setAggridData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  const { departments } = useSelector((state) => state.departments);
  const [clockings, setClockings] = useState([]);
  // const { clockings } = useSelector((state) => state.clockings);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [generateVisib, setGenerateVisib] = useState(false);
  const [multiGenVis, setMultiGenVis] = useState(false);
  const [multiEmpl, setMultiEmp] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [editWageVisib, setEditWageVisib] = useState({
    visibility: false,
    toEdit: null,
  });

  const { wages } = useSelector((state) => state.wages);
  const { programFields } = useSelector((state) => state.programFields);

  const teamOptions = useMemo(() => {
    if (!programFields?.length) return [];
    return (
      programFields?.find((el) => el?.fieldId === TEAM_FIELD_ID)
        ?.fieldOptions || []
    );
  }, [programFields]);

  const statusTypes = useMemo(() => {
    if (!!programFields) {
      const temp = programFields?.find((el) => el?.fieldName === "wageStatus");
      return !!temp ? temp?.fieldOptions || [] : [];
    } else return [];
  }, [programFields]);

  const onEditWage = (data) => {
    setEditWageVisib({ toEdit: data, visibility: true });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleSearch = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
    setAgGridSearchInput(e.target.value);
  };

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
    setAgGridSearchInput(null);
  };

  const categorizeStatus = (type) => {
    if (type === "Pending") {
      return "Ne pritje";
    } else if (type === "Draft") {
      return "Draft";
    } else if (type === "Finalized") {
      return "Finalizuar";
    } else {
      return "I pallogaritur";
    }
  };

  const getShiftData = useCallback(async () => {
    let processedHours = calculateWagePerHours(
      eligibleEmployeesClockIn,
      clockings,
      location?.state
    );
    // console.log("process", processedHours);
    let monthlyInstance = location?.state?.data?.muaji;
    // console.log("Monthly Instance", monthlyInstance);
    const momentInstance = {
      start: !!monthlyInstance
        ? moment(location?.state?.data?.muaji).startOf("month")
        : moment().startOf("month"),
      end: !!monthlyInstance
        ? moment(location?.state?.data?.muaji).endOf("month")
        : moment().endOf("month"),
    };
    setAggridData(
      eligibleEmployeesClockIn.map((empl, idx) => {
        let foundEmpl = processedHours.find(
          (proc) => proc.employeeId === empl.employeeId
        );
        let resu = retrieveRelevantWages(
          wages,
          empl.employeeId,
          momentInstance
        );
        // console.log("resu", resu);
        return {
          ...empl,
          oret_totale: resu?.allhours?.totalHours,
          finalized: !!resu ? resu?.finalized : false,
          calculatedWage: !!resu ? resu?.taxes?.netoPay?.netoPay || 0 : 0,
          periudha: moment(momentInstance?.start).format("MMMM YYYY"),
          time: moment(momentInstance?.start).valueOf(),
          timeStance: moment(resu?.momentInstance?.start).format("MM/DD/YYYY"),
          wageStatus: categorizeStatus(resu?.wageStatus),
        };
      })
    );

    //   setClockins(res);
  }, [eligibleEmployeesClockIn, clockings, wages, location?.state]);

  const onReload = (emplId, newValues) => {
    let tempRowData = aggridData;
    tempRowData = tempRowData.map((row) => {
      if (row?.employeeId === emplId) {
        return { ...row, ...newValues };
      } else return { ...row };
    });
    setAggridData(tempRowData);
    // console.log("Ag grid Data", aggridData);
  };

  //testing.. to delete

  const onCellValueChanged = useCallback((event) => {
    if (event?.colDef?.field === "employeeSalaryPerHour") {
      return undefined;
    }
  }, []);

  //print fille
  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onGenerate = () => {
    setGenerateVisib(true);
  };

  const onFilter = (e) => {
    onEmployeeWageTypeFilter(e, gridApi, "wageStatus");
  };

  const onMultiGen = () => {
    setMultiGenVis(true);
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setMultiEmp(
        selectedData?.map((sd) => ({
          employeeId: sd?.employeeId,
          fullName: `${sd?.employeeFirstName} ${sd?.employeeLastName}`,
        }))
      );
    } else {
      setMultiEmp([]);
    }
  };

  const Footer = () => {
    return (
      <div>
        <strong>Ledjo</strong>
      </div>
    );
  };

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  useEffect(() => {
    if (!!eligibleEmployeesClockIn && !!departments && !!auth && !!clockings) {
      getShiftData();
    }
  }, [
    eligibleEmployeesClockIn,
    departments,
    location,
    wages,
    location,
    clockings,
  ]);

  useEffect(() => {
    setColumnDefs(wagesColumnDefs(onEditWage));
  }, []);

  // useLayoutEffect(async () => {
  // 	await API.get("wages", "/wages").then((res) => {
  // 		dispatch(wagesAction(res));
  // 	});
  // }, []);

  useLayoutEffect(() => {
    const getData = async () => {
      // requestData("employees").then((res) => dispatch(employees(res, "GET_ACTIVE_EMPLOYEES")));
      // requestData("users").then((res) => dispatch(users(res)));
      // requestData("employeeRequests").then((res) => dispatch(employeeRequests(res)));
      // requestData("earnings").then((res) => dispatch(earnings(res)));
      // requestData("deductions").then((res) => dispatch(deductions(res)));
      // requestData("prepayments").then((res) => dispatch(prepayments(res)));
      // requestData("wages").then((res) => dispatch(wages(res)));
      message.success("Retrieving the latest records...");
      return await requestDataWithParams(
        "clocking",
        moment().subtract(1, "month").startOf("month").valueOf(),
        moment().endOf("month").valueOf()
      ).then((res) => {
        console.log("Clockings", res);
        setClockings(res || []);
        // dispatch(clockingsAction(res || []));
      });

      // await requestData("clocking").then((res) => {
      // 	// message.success("Record Updated...");
      // 	dispatch(clockingsAction(res || []));
      // });
    };

    getData();
    setLoading(true);

    return () => {
      setLoading(false);
    };
  }, []);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  console.log(aggridData);
  //region RETURN
  return (
    <LoadableComp loading={!loading}>
      <div className="globalPageWrapper">
        {viewType !== null && viewType !== "admin" ? (
          <PageNotFound />
        ) : isMobileView ? (
          <div className="wages-cont">
            <WagesHeader
              onFilter={onFilter}
              statuses={aggridData?.map(({ wageStatus }) => ({ wageStatus }))}
            />
            <div className="shift-approvals">
              <div className="more-shift-filters2">
                <div className="department-filter">
                  <Select
                    onChange={(e) =>
                      onDepartmentFilter(e, gridApi, "employeeDepartmentName")
                    }
                    clearIcon={<ClearIcon />}
                    suffixIcon={<DropDownIcon />}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Zgjidh Departamentin"
                  >
                    {departments?.map((el) => (
                      <Option value={el.departmentName}>
                        {el.departmentName}
                      </Option>
                    ))}
                    {/* <Option value="jack">Jack</Option> */}
                  </Select>
                </div>
                <div className="employee-filter">
                  <Select
                    onChange={(e) => onEmployeeFilter(e, gridApi, "employeeId")}
                    clearIcon={<ClearIcon />}
                    suffixIcon={<DropDownIcon />}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    allowClear
                    placeholder="Zgjidh Punonjesin"
                  >
                    {eligibleEmployeesClockIn?.map((el) => (
                      <Option value={el.employeeId}>
                        {el.employeeFirstName + " " + el.employeeLastName}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="employee-filter">
                  <Select
                    showSearch
                    clearIcon={<ClearIcon />}
                    suffixIcon={<DropDownIcon />}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      onEmployeeWageTypeFilter(
                        e,
                        gridApi,
                        "employeePayrollType"
                      )
                    }
                    allowClear
                    placeholder="Zgjidh llojin e pageses!"
                  >
                    <Option value={"Page Fikse"}>Me Page</Option>
                    <Option value={"Page bazuar ne ore"}>Me ore</Option>
                  </Select>
                </div>
                <div className="employee-filter">
                  <Select
                    showSearch
                    clearIcon={<ClearIcon />}
                    suffixIcon={<DropDownIcon />}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      onEmployeeWageTypeFilter(e, gridApi, "wageStatus")
                    }
                    allowClear
                    placeholder="Zgjidh statusin e pages"
                  >
                    <Option value="Ne pritje">Ne pritje</Option>
                    <Option value="Finalizuar">Finalizuar</Option>
                    <Option value="I pallogaritur">I pallogaritur</Option>
                    <Option value="Draft">Draft</Option>
                  </Select>
                </div>
                <div className="employee-filter">
                  <DatePicker
                    clearIcon={<ClearIcon />}
                    // suffixIcon={<CalendarIcon />}
                    // suffixIcon={<DropDownIcon />}

                    defaultValue={
                      location?.state !== null
                        ? moment(location?.state?.data?.muaji)
                        : false
                    }
                    suffixIcon={<IconCalendar />}
                    className="month-shift-picker"
                    picker="month"
                    format={"MMMM YYYY"}
                    placeholder="Zgjidhni muajin"
                    onChange={(e) => {
                      if (!!e) {
                        getShiftData();
                        navigate("/pagat/llogaritja", {
                          state: { data: { muaji: moment(e).valueOf() } },
                        });
                      } else {
                        navigate("/pagat/llogaritja", {
                          state: null,
                        });
                      }

                      // onPickMonth(e, gridApi);
                    }}
                  />
                </div>
                <div className="employee-filter">
                  <button
                    onClick={onGenerate}
                    className="paysub-btn"
                    style={{
                      border: "none",
                      width: "180px",
                      height: "30px",
                      backgroundColor: "#1DA193",
                      cursor: "pointer",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    Gjenero Individualisht
                  </button>
                </div>
                <div className="employee-filter">
                  <button
                    onClick={onMultiGen}
                    className="paysub-btn"
                    style={{
                      border: "none",
                      width: "170px",
                      height: "30px",
                      backgroundColor: "#1DA193",
                      cursor: "pointer",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    Gjenero te selektuarat
                  </button>
                </div>
              </div>
              <div className="employee-shifts">
                <div className="ag-grid-shifts">
                  <AgGridHeader
                    gridApi={gridApi}
                    gridData={aggridData}
                    items={{
                      ...ITEMS,
                      icons: {
                        ...ITEMS?.icons,
                        refresh: { onClick: getShiftData },
                      },
                    }}
                    exportTitle={`Pagat - ${moment(location?.state?.data?.muaji)
                      .locale("al")
                      .format("MMMM YYYY")}`}
                  />
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 632, width: "100%" }}
                  >
                    <AgGridComponent
                      onCellValueChanged={onCellValueChanged}
                      rowData={aggridData}
                      rowSelection={"multiple"}
                      onGridReady={onGridReady}
                      paginationPageSize={20}
                      columnDefs={columnDefs}
                      suppressRowClickSelection={true}
                      // onSelectionChanged={handleSelectionChanged}
                      // footer={<DynamicFooter />}
                    />
                  </div>
                  <FooterCounter
                    title={"Pagat totale te llogaritura"}
                    data={aggridData?.map((el) => el?.calculatedWage)}
                    style="rowFlex"
                  />
                </div>
                <HtmlExelTabel
                  tableData={rowData?.map?.(
                    ({
                      employeeFirstName,
                      employeeLastName,
                      employeePayrollType,
                      periudha,
                      employeeSalaryPerHour,
                    }) => ({
                      employeeFirstName,
                      employeeLastName,
                      employeePayrollType,
                      periudha,
                      employeeSalaryPerHour,
                    })
                  )}
                  header={[
                    "Emri",
                    "Mbiemri",
                    "Lloj i pagesës",
                    "Periudha",
                    "Pagesa për ore",
                  ]}
                  id="wages-at-pagat"
                  componentRef={componentRef}
                />
              </div>
            </div>
            {multiGenVis ? (
              <MultiGenerate
                {...{ multiGenVis, setMultiGenVis, multiEmpl, clockings }}
              />
            ) : null}
            {/* <PayStub componentRef={componentRef} /> */}
            {editWageVisib?.visibility ? (
              <EditEmployeeWage
                editable={editWageVisib}
                setVisib={setEditWageVisib}
                onSave={onReload}
              />
            ) : null}
          </div>
        ) : (
          <div className="base-mobile-div">
            <div className="header-div">
              <button
                onClick={onGenerate}
                className="paysub-btn"
                style={{
                  border: "none",
                  width: "180px",
                  height: "30px",
                  marginLeft: "20px",
                  backgroundColor: "#1DA193",
                  cursor: "pointer",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Gjenero Individualisht
              </button>
            </div>
            <MobileHtmlTabel
              tableData={aggridData?.map?.(
                ({
                  employeeFirstName,
                  employeeLastName,
                  employeePayrollType,
                  periudha,
                  employeeSalaryPerHour,
                }) => ({
                  employeeFirstName,
                  employeeLastName,
                  employeePayrollType,
                  periudha,
                  employeeSalaryPerHour: employeeSalaryPerHour,
                })
              )}
              header={[
                "Emri",
                "Mbiemri",
                "Lloj i pagesës",
                "Periudha",
                "Pagesa për ore",
              ]}
              isLink={false}
            />
          </div>
        )}
        {generateVisib && clockings ? (
          <GenerateModal {...{ generateVisib, setGenerateVisib, clockings }} />
        ) : null}
      </div>
    </LoadableComp>
  );
}

export default Wages;
