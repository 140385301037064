import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Select,
  TimePicker,
} from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IconNdrysho, WarningIcon } from "../../../../assets/icons";
import Actions from "../../wages/editables/deductAdd/Actions";
import { AmountCard } from "../../wages/warnings/CustomWarnings";
import ActionsDay from "./ActionsDay";
import "./EditDayPay.scss";
function EditDayPay({ data, getShiftData }) {
  const [visible, setVisible] = useState(false);
  const [empInfo, setEmpInfo] = useState(data);
  const [salaryPerHour, setSalaryPerHour] = useState(0);

  const { programFields } = useSelector((state) => state.programFields);
  const { Option } = Select;

  const dayType = (dayType) => {
    switch (dayType) {
      case "Regular":
        return "Normal";
      case "Sick":
        return "Sëmurë";
      case "Maternity":
        return "Leje Lindje";
      case "NationalHoliday":
        return "Feste Zyrtare";
      case "HolidayRequest":
        return "Kërkesë Leje";
    }
  };

  return (
    <>
      <div
        className="icon-ndrysho-modal-cell"
        style={{ fill: "white" }}
        onClick={() => setVisible(true)}
      >
        <IconNdrysho width={14} height={14} color="white" />
      </div>
      <Modal
        destroyOnClose={true}
        width={1250}
        className="edit-pay-day darkHeader"
        title="Ndrysho Pagesën Ditore"
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        centered={true}
        footer={[]}
      >
        <div className="new-shift-edit-content">
          <ActionsDay
            getShiftData={getShiftData}
            setVisible={setVisible}
            data={data}
            empInfo={empInfo}
            employeeInfo={empInfo?.employee_details}
            employeeId={empInfo?.employee_details?.employeeId}
            timeperiod={[
              moment(empInfo?.fillim_turni).valueOf(),
              moment(empInfo?.mbarim_turni).valueOf(),
            ]}
            // momentInstance={[empInfo?.fillim_turni, empInfo?.mbarim_turni]}
          />
        </div>
      </Modal>
    </>
  );
}

export default EditDayPay;
