import { createElement } from "react";
import { IconNgargo } from "../../../../assets/icons";
import { Tooltip } from "antd";
import moment from "moment";
import { htmlParser } from "../../../../utils";
import { formatChanges } from "../../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";
import { useReactToPrint } from "react-to-print";

const AgGridToPrint = ({ gridApi, title, tableCols }) => {
  const generateTable = () => {
    const filteredRows = [];
    gridApi.forEachNodeAfterFilter((e) => filteredRows.push(e.data));

    if (!filteredRows || filteredRows.length === 0) {
      return;
    }
    let div = document.createElement("div");
    div.innerHTML = `<div>${title}</div><br>
    <table>
      <thead>
        <tr>
          ${tableCols?.map((el) => `<th>${el?.al || el}</th>`)}
        </tr>
      </thead>
      <tbody>
        ${filteredRows?.map((row) => {
          let tableRow = [];
          tableCols?.forEach((col) => {
            let value = row[col?.en || col];
            let formattedValue = `${
              !!value
                ? Array.isArray(value)
                  ? value.join(", ")
                  : moment.isMoment(value) ||
                    value instanceof moment ||
                    moment(value).isValid() ||
                    moment(value, moment.ISO_8601).isValid()
                  ? moment(value)
                      .locale("al")
                      .format(col?.format || "DD/MM/YYYY HH:mm")
                  : typeof value === "string" || value instanceof String
                  ? !!htmlParser(value)?.props?.children
                    ? htmlParser(value)?.props.children
                    : formatChanges(value)
                  : formatChanges(value)
                : ""
            }`;
            tableRow.push(formattedValue);
          });
          return `<tr>
              ${tableRow?.map((val) => `<td>${val}</td>`)}
            </tr>`;
        })}
      </tbody>
    </table>`;

    return div;
  };
  const handlePrint = useReactToPrint({
    content: () => generateTable(),
  });
  return (
    <div className="headerIcon" onClick={handlePrint}>
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Printo"}
      >
        <IconNgargo width={18} height={18} fill="#323338" />
      </Tooltip>
    </div>
  );
};

export default AgGridToPrint;
