import { useState, useEffect, useRef } from "react";
import { DeleteFilled, EyeFilled } from "@ant-design/icons";
import { message, Tooltip, Popconfirm } from "antd";
import FilterAgGrid from "../punonjesit/components/FilterAgGrid";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import LajmerimetPrint from "./LajmerimetPrint";
import { useReactToPrint } from "react-to-print";
import AgGridComponent from "../AG-grid/AgGridComponent";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import {
  deleteAnnouncement,
  getAnnouncements,
  sortDates,
} from "./utils/methods";
import HappyHolidaysModal from "../../assets/holidays/Modals/HappyHolidaysModal";
import ModalAnnounce from "./ModalAnnounce/ModalAnnounce";
import moment from "moment-timezone";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./Announcements.scss";
import { htmlParser } from "../../utils";
import { IconEditWhite, IconExcel, IconNgargo } from "../../assets/icons";
import AnnouncementReplies from "./AnnouncementReplies/AnnouncementReplies";
import NewAnnouncement from "./NewAnnouncement/NewAnnouncement";
import CustomAgPaginator from "../punonjesit/EmployeeView/CustomAgPaginator/CustomAgPaginator";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";

const initialData = {
  announcementTitle: "",
  announcementBody: "",
  announcementUrl: "https://portal.flex.al/kryefaqja",
  announcementTarget: {
    departments: [],
  },
  announcementTeam: {
    teams: [],
  },
  endAnnouncementDate: null,
  userResponses: {},
  announcementCategory: "needAction",
  createdBy: "",
};

const FILTER_KEYS = [
  { key: "createdBy", label: "Krijuar nga", type: "string" },
  { key: "announcementTitle", label: "Titulli", type: "string" },
  { key: "announcementCategory", label: "Kategoria", type: "string" },
  { key: "createdAt", label: "Koha e krijimit", type: "date-range" },
];
const EXPORT_COLS = [
  { en: "announcementTitle", al: "Titulli" },
  { en: "announcementCategory", al: "Kategoria" },
  { en: "announcementBody", al: "Përmbajtja" },
  { en: "announcementUrl", al: "Url" },
  { en: "createdBy", al: "Krijuar nga" },
  { en: "usersResponses", al: "Përgjigjet" },
];
const ITEMS = {
  icons: {
    filter: {
      filterKeys: FILTER_KEYS,
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function Announcements() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialData);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState({
    modeType: "new",
    otherData: {},
  }); // OTHER MODES: edit, view

  const retrievedData = async () => {
    await getAnnouncements().then((r) => {
      console.log(r);
      setRowData(
        r?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      );
      setLoading(false);
    });
  };

  const retrieveEditData = (data) => {
    setData(data);
    setVisible(true);
    setMode({ modeType: "edit", otherData: data });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onReset = () => {
    setData(initialData);
    setMode({ modeType: "new", otherData: {} });
  };

  // print file
  const componentRef = useRef("Lajmerimet-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
  };

  const sortedAnnouncements = (data = []) =>
    data?.sort(
      (a, b) => moment(a?.createdAt).valueOf() - moment(b?.createdAt).valueOf()
    );

  useEffect(() => {
    retrievedData();
  }, []);

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="announcemenet-container">
          <div className="announcemenet-header">
            <button
              className="new-announcement-btn-lajmerim"
              onClick={() => setVisible(true)}
            >
              Lajmërim I Ri
            </button>
          </div>
          <div className="punonjesit-ag-grid">
            <AgGridHeader
              gridApi={gridApi}
              gridData={rowData?.map((row) => ({
                ...row,
                announcementCategory: categories[row?.announcementCategory],
                userResponses: row?.userResponses?.map((res) => res?.response),
              }))}
              items={ITEMS}
              exportTitle={"Lista e Lajmerimëve"}
            />
            {/* <div className="ag-grid-header">
              {" "}
              <div className="header-icons">
                <FilterAgGrid
                  gridApi={gridApi}
                  clearFilters={clearFilters}
                  tableData={rowData}
                  filterKeys={FILTER_KEYS}
                  replaceable={{ announcementCategory: [categories] }}
                />
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="Lista_e_Lajmerimeve"
                  sheet="lajmerimet"
                  buttonText={
                    <Tooltip
                      placement="top"
                      overlayClassName="global-icon-tooltip"
                      title={"Eksporto në Excel"}
                    >
                      <IconExcel />
                    </Tooltip>
                  }
                />
                <ExportToPdf
                  id={"#table-to-xls"}
                  tabelName={"Lista_e_Lajmerimeve"}
                />
                <Tooltip
                  placement="top"
                  overlayClassName="global-icon-tooltip"
                  title={"Printo tabelën"}
                >
                  <IconNgargo onClick={handlePrint} />
                </Tooltip>
              </div>
            </div> */}
            <div
              className="ag-theme-alpine"
              style={{
                height: 632,
                width: "100%",
                padding: 0,
                borderRadius: "10px",
              }}
            >
              <AgGridComponent
                rowData={sortedAnnouncements(rowData)}
                // rowData={aggridData}
                // rowSelection={"multiple"}
                onGridReady={onGridReady}
                // paginationPageSize={10}
                columnDefs={columnDefs({ retrieveEditData })}
                // suppressRowClickSelection={true}
                // onSelectionChanged={handleSelectionChanged}
                // onPaginationChanged={onPaginationChanged}
              />
              <CustomAgPaginator gridApi={gridApi} />
            </div>
            <LajmerimetPrint tableData={rowData} componentRef={componentRef} />
          </div>
          <NewAnnouncement
            {...{
              mode,
              data,
              gridApi,
              setData,
              onReset,
              visible,
              setVisible,
              retrievedData,
            }}
          />
        </div>
      </div>
    </LoadableComp>
  );
}

export default Announcements;

//region COLUMN DEFS
const columnDefs = ({ retrieveEditData = () => {} }) => {
  return [
    {
      headerName: "Titulli",
      field: "announcementTitle",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Kategoria",
      field: "announcementCategory",
      sortable: true,
      filter: true,
      width: 200,
      valueGetter: (params) => {
        return categories[params?.data?.announcementCategory];
      },
    },
    {
      headerName: "Përmbajtja",
      field: "announcementBody",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: (params) => {
        return <>{htmlParser(params?.data?.announcementBody)}</>;
      },
    },
    {
      headerName: "Url",
      field: "announcementUrl",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Krijuar ne",
      field: "createdAt",
      sort: "desc",
      filter: "agDateColumnFilter",
      valueGetter: ({ data }) => data?.createdAt,
      // comparator: (date1, date2) => sortDates(date1, date2, "YYYY-MM-DD"),
      hide: true,
    },

    {
      headerName: "Krijuar Nga",
      field: "createdBy",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Përgjigjet",
      field: "userResponses",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: (params) => {
        return (
          <AnnouncementReplies
            userResponses={params?.data?.usersResponses || {}}
            id={params?.data?.announcementId}
            title={params?.data?.announcementTitle}
          />
        );
      },
    },
    {
      headerName: "Shiko/Fshi",
      field: "actions",
      sortable: true,
      filter: true,
      // width: 200,
      cellRenderer: (params) => {
        return (
          <div
            className="announcements-actions"
            style={{
              display: "flex",
              height: "40px",
              alignItems: "center",
              gap: 10,
            }}
          >
            <span
              style={{
                backgroundColor: "#1DA193",
                width: "30px",
                height: "30px",
                fontSize: "22px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                color: "white",
                justifyContent: "center",
                borderRadius: "5px",
                cursor: "pointer",
                fill: "#fff !important",
              }}
              onClick={() => retrieveEditData(params?.data)}
            >
              <IconEditWhite />
            </span>
            {/* <Button onClick={() => retrieveEditData(params?.data)}>Edit</Button> */}
            {/* <Tooltip title="Actions">
							<PreviewModal {...{ params }} />
						</Tooltip> */}
            <Popconfirm
              title="Je i sigurt"
              // description="Je i sigurt që don me fshi lajmërimin?"
              onConfirm={async () => {
                await deleteAnnouncement(params?.data?.announcementId)
                  .then(() => {
                    params.api.applyTransaction({ remove: [params?.data] });
                    message.success("Announcement deleted successfully");
                  })
                  .catch((e) => {
                    message.error("Error deleting announcement");
                  });
              }}
              okText="Yes"
              cancelText="No"
            >
              <span
                style={{
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  fontSize: "20px",
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fill: "#fff !important",
                }}
              >
                <DeleteFilled className="announcements-actions__delete" />
              </span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
};

//region PREVIEW MODAL
const PreviewModal = ({ params }) => {
  const [visible, setVisible] = useState(false);
  console.log("params", params);
  return (
    <>
      <span
        style={{
          cursor: "pointer",
          width: 28,
          height: 32,
          marginRight: 10,
          fontSize: 28,
          // backgroundColor: "#f5f5f5",
          padding: 5,
          borderRadius: 5,
          color: "#1DA193",
        }}
        onClick={() => setVisible(true)}
      >
        <EyeFilled
          style={{
            color: "#fff",
            padding: 3,
            borderRadius: 3,
            backgroundColor: "#1DA193",
          }}
        />
      </span>
      {visible && (
        <ModalAnnounce
          {...{
            previewMode: true,
            previewAnnouncement: structuredClone(params.data),
            setPreview: setVisible,
          }}
        />
      )}
    </>
  );
};
export const data = [
  [
    "Lloji",
    "Numri i pergjigjeve",
    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Konfirmoi", 8.94, "#b87333", null],
  ["Anashkaloi", 10.49, "silver", null],
  ["Refuzoi", 19.3, "gold", null],
];

export const categories = {
  importantAnnouncement: "Lajmërim",
  needAction: "Lajmërim që ka nevojë për përgjigje",
  otherWish: "Urim",
};
