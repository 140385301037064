import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { paySubFactory } from "../../PaySub/employeeCalc/employeeSubFactory";
import PayStub from "../../PaySub/PaySub";
import moment from "moment-timezone";
import { Button, Modal } from "antd";
import { API } from "aws-amplify";
import { useReactToPrint } from "react-to-print";
import "./PersonalPaycheck.scss";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

export default function PersonalPaycheckPreview({
  employeeInfo,
  momentInstance,
}) {
  const { clockings } = useSelector((state) => state.clockings);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const [modalPreview, setModalPreview] = useState(false);
  const { prepayments } = useSelector((state) => state.prepayments);
  const { programFields } = useSelector((state) => state.programFields);
  const payStubObj = useMemo(() => {
    if (
      !!employeeInfo &&
      !!programFields &&
      !!clockings &&
      !!employeeRequests &&
      !!prepayments
    ) {
      const test13 = new paySubFactory({
        type: "allEarnings",
        employee: employeeInfo || null,
        clockings: clockings.filter((el) => el?.approved === true),
        employeeRequests: employeeRequests,
        momentInstance: momentInstance,
        prepayments: prepayments,
        programFields: programFields,
      });

      return test13;
    }
    return null;
  }, [
    employeeInfo,
    clockings,
    employeeRequests,
    modalPreview,
    prepayments,
    momentInstance,
    programFields,
  ]);
  const getDocTitle = () => {
    if (!!employeeInfo && !!momentInstance) {
      let fullName =
        employeeInfo?.employeeFirstName + " " + employeeInfo?.employeeLastName;
      let date = moment(momentInstance.start).format("MMMM_YYYY");
      return `${fullName}_${date}`;
    }
  };
  const componentRef = useRef("payStub-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${getDocTitle()}`,
  });
  return (
    <div>
      <MondayButton
        onClick={() => setModalPreview(true)}
        className="mondayButtonGreen"
      >
        Shiko Paraprakisht Pagen
      </MondayButton>
      <Modal
        destroyOnClose={true}
        width={870}
        className="edit-employee-wage darkHeader"
        title={`Parashikimi i Pages  - ${
          employeeInfo?.employeeFirstName + " " + employeeInfo?.employeeLastName
        } `}
        open={modalPreview}
        onOk={() => setModalPreview(false)}
        onCancel={() => setModalPreview(false)}
        centered={true}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            onClick={() => setModalPreview(false)}
          >
            Mbyll Faqen
          </MondayButton>,
          <MondayButton
            className="mondayButtonGreen"
            disabled={payStubObj?.valid === false ? true : false}
            onClick={() => handlePrint()}
          >
            Printo
          </MondayButton>,
        ]}
      >
        {!!payStubObj && (
          <PayStub componentRef={componentRef} payStubObj={payStubObj} />
        )}
      </Modal>
      <div></div>
    </div>
  );
}
