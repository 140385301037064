const ColorPalette = ({ value = "", onChange, colorPalette = [] }) => {
  return (
    (<div className="colorPalette">
      <div className="selectedColor" style={{ backgroundColor: value }}>
        Status
      </div>
      <div className="colorPalette">
        {colorPalette.map((backgroundColor, idx) => (
          //* maps color for each doc status
          (<div
            {...{
              key: idx,
              className: `colorSquare ${!!value ? "selected" : ""}`,
              style: { backgroundColor },
              onClick: () => onChange(backgroundColor),
            }}
          />)
        ))}
      </div>
    </div>)
  );
};

export default ColorPalette;
