import React, { useState } from "react";
import { ClockInIcon, ClockOutIcon } from "../../assets";
import { Input, Popconfirm, TimePicker } from "antd";
import moment from "moment-timezone";
import {
  getDisabledAfterHours,
  getDisabledAfterMinutes,
} from "../../utils/disabledTimeFilters";
import ClockOutModal from "./clockOutModal/ClockOutModal";
import { HollowDotsSpinner } from "react-epic-spinners";
import { ConfirmIcon, HomeIcon } from "../../../../assets/icons";
import { HomeOutlined } from "@ant-design/icons";
import Clock from "react-live-clock";

const { TextArea } = Input;

function ClockOutActive({
  time,
  editedTime,
  apiData,
  customTime,
  autoSuggestion,
  onCustomEndTime,
  setCustomTime,
  notes,
  onClockOut,
  onShiftNote,
  setLoadingColor,
  loadShiftData,
  resetValues,
  onClockForgot,
  setNewShift,
  shiftConfiguration,
}) {
  const [clockOutVisible, setClockOutVisible] = useState(false);
  const [startTime, setStartTime] = useState(
    apiData[apiData.length - 1]?.clockInDate
  );
  const [lastShift, setLastShift] = useState(
    apiData[apiData.length - 1] || null
  );
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "250px",
          }}
        >
          <HollowDotsSpinner color={"red"} size={24} />
        </div>
      ) : (
        <div className="clock-out-active">
          <div className="time-indicator">
            {/* <h4 style={{ color: "#EA3943" }}>{moment(time).format("HH:mm")}</h4> */}
            <h4 style={{ color: "#EA3943" }}>
              {" "}
              {/* <Clock
                format={"HH:mm"}
                ticking={true}
                timezone={"Europe/Tirane"}
              /> */}
            </h4>
          </div>
          {!(lastShift?.clockOutDate === null && loading === false) && (
            <ConfirmIcon style={{ marginBottom: "10px" }} />
          )}
          {lastShift?.clockOutDate === null && loading === false && (
            <div
              className="clock-in-info"
              style={{
                // marginTop: editedTime ? "-10px" : "10px",
                justifyContent: "center",
              }}
            >
              <span>
                Turni ka filluar në orën
                <strong> {moment(startTime).format("HH:mm")}!</strong>{" "}
              </span>{" "}
            </div>
          )}
          {/* <i className="relativeTime">
{" "}
({moment(apiData[apiData.length - 1]?.clockInDate).fromNow()})
</i> */}
          {lastShift?.clockOutDate === null && loading === false ? (
            <div className="clock-out-action">
              <div className="clock-out">
                {/* <ClockOutIcon className="clock-out-icon" /> */}
                {/* <Popconfirm
							title={`A jeni të sigurt që dëshironi të përfundoni turnin në këtë orar?`}
							onConfirm={onClockOut}
							// onCancel={cancel}
							disabled={
								moment().diff(moment(lastShift?.clockInDate), "minutes", true) < 5 ? true : false
							}
							okText="Po, përfundo."
							cancelText="Jo, anullo."
						> */}
                <button
                  className="clock-out-btn"
                  onClick={() => setClockOutVisible(true)}
                  // style={
                  // 	moment().diff(moment(lastShift?.clockInDate), "minutes", true) < 5
                  // 		? { cursor: "not-allowed" }
                  // 		: null
                  // }
                >
                  {" "}
                  Mbaro Turnin
                </button>

                <div className="start-shift-cont">
                  {/* <span>
										<TimePicker
											className="timepick"
											defaultValue={time}
											value={customTime ? null : editedTime !== null ? editedTime : autoSuggestion}
											format={"HH:mm"}
											allowClear={false}
											onChange={onCustomEndTime}
											onOpenChange={(boolVal) => setCustomTime(boolVal)}
											disabledHours={getDisabledAfterHours}
											disabledMinutes={getDisabledAfterMinutes}
											disabled={
												moment(apiData[apiData.length - 1]?.clockInDate).format("DD/MM/YYYY") ===
												moment().format("DD/MM/YYYY")
													? false
													: true
											}
										/>
									</span> */}
                </div>
              </div>
              <div
                style={{
                  width: "160px",
                  backgroundColor: "#1D3445",
                  marginTop: "10px",
                  height: "30px",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Ora Tani:{" "}
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/Tirane"}
                />
              </div>
              {editedTime && (
                <span className="warning-clock-out-edited">
                  Orari i mbarimit të turnit është ndryshuar.
                </span>
              )}
              {!editedTime && (
                <span
                  style={{
                    marginTop: "20px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "21.79px",
                  }}
                >
                  Punë të mbarë!
                </span>
              )}

              {editedTime && (
                <div
                  className="justify-note-wrapper"
                  style={{ marginTop: "-5px" }}
                >
                  <TextArea
                    // autoSize={false}
                    value={notes?.clockOutNote}
                    onChange={(e) => onShiftNote(e, "clockOutNote")}
                    classname="justify-note"
                    placeholder="Shkruaj një shënim këtu..."
                    autoSize={{ minRows: 2, maxRows: 2 }}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="clock-out-action-finish">
              <div className="clock-out-info">
                {/* <ClockOutIcon className="clock-out-icon" /> */}
                <span>
                  {" "}
                  Turni u mbyll në orën{" "}
                  <strong>
                    {apiData[apiData.length - 1]?.clockOutDate !== null
                      ? moment(
                          apiData[apiData.length - 1]?.clockOutDate
                        ).format("HH:mm")
                      : "--"}
                    !
                  </strong>
                </span>
              </div>
              <h1>
                Mirupafshim!
                {/* <HomeOutlined style={{ color: "#FCC94A" }} /> */}
              </h1>
            </div>
          )}
        </div>
      )}
      {clockOutVisible && (
        <ClockOutModal
          {...{
            onClockForgot,
            clockOutVisible,
            setClockOutVisible,
            lastShift,
            apiData,
            setLoading,
            setLoadingColor,
            loadShiftData,
            resetValues,
            setNewShift,
            setLastShift,
            shiftConfiguration,
          }}
        />
      )}
    </>
  );
}

export default ClockOutActive;
