import { useState, useEffect, useContext } from "react";
import "./WeeklyMonthly.scss";
import { CalendarIcon, ScheduleCalendar } from "../../assets";
import { DatePicker, Skeleton } from "antd";
import moment from "moment-timezone";
import Calculations from "./Calculations.jsx";
import { NotifContext } from "../notifications/context/notifContext";

const weekFormat = "DD/MM/YYYY";
const monthFormat = "MM/YYYY";

const WeeklyMonthly = () => {
  let trLocale = require("moment/locale/sq");
  const [date, setDate] = useState(moment());
  const [viewType, setViewType] = useState("monthly");
  const handleCalendarChange = (value) => {
    // console.log(
    //   "Start",
    //   moment(value).startOf("week").locale("sq").format("LL"),
    //   "End",
    //   moment(value).endOf("week")
    // );
    setDate(value);
  };
  const { isFetching } = useContext(NotifContext);

  const customWeekStartEndFormat = (value) =>
    `${moment(value).startOf("week").format(weekFormat)} - ${moment(value)
      .endOf("week")
      .format(weekFormat)}`;

  function onChange(date, dateString) {
    // console.log(date, dateString);
    setDate(date);
  }

  useEffect(() => {
    if (date === null || date === undefined) {
      setDate(moment());
    }
  }, [date]);

  return (
    <>
      <div className="weekly-monthly">
        <div className="weekly-header">
          <div className="summary-calendar-icon">
            <ScheduleCalendar />
          </div>
          <div className="info-container">
            <div className="info-actions">
              <span style={{ fontWeight: 500, fontSize: 16, zIndex: 3 }}>
                Përmbledhje e orëve,{" "}
                {viewType === "weekly" ? "javore" : "mujore"}
              </span>
              {viewType === "weekly" ? (
                <button
                  className="monthly-btn"
                  onClick={() => setViewType("monthly")}
                >
                  <span className="muj">Javore</span>
                </button>
              ) : (
                <button
                  className="monthly-btn-javore"
                  onClick={() => setViewType("weekly")}
                >
                  <span>Mujore</span>
                </button>
              )}
            </div>
            {viewType === "weekly" ? (
              <div className="weeks-info">
                {moment(date).startOf("week").format("LL")}
                <span className="small-circle"></span>
                <span className="dashes">- - - - - - - - -</span>
                <span className="circle"></span>{" "}
                {moment(date).endOf("week").format("LL")}
              </div>
            ) : (
              <div className="weeks-info">
                {moment(date).format("MMMM YYYY")}
              </div>
            )}
          </div>
        </div>
        {viewType === "weekly" ? (
          <div className="weekly-picker">
            <DatePicker
              popupClassName="calendar-pick"
              defaultValue={date}
              format={customWeekStartEndFormat}
              picker="week"
              placeholder="Selekto datat për filtrim"
              onChange={handleCalendarChange}
              suffixIcon={<CalendarIcon />}
              style={{ height: 30 }}
            />
          </div>
        ) : (
          <div className="monthly-picker">
            <DatePicker
              onChange={onChange}
              defaultValue={date}
              format={monthFormat}
              style={{ height: 30 }}
              picker="month"
              popupClassName="calendar-pick"
              placeholder="Selekto muajin që dëshiron të filtrosh"
              // onChange={handleCalendarChange}
              suffixIcon={<CalendarIcon />}
            />
          </div>
        )}
        <Calculations {...{ date, viewType }} />
      </div>
    </>
  );
};

export default WeeklyMonthly;
