import React from "react";
import moment from "moment-timezone";
import "./ShiftLogCard.scss";
import { htmlParser } from "../../../../utils/htmlParser";
import { Tooltip } from "antd";
function ShiftLogCard({ content }) {
  // console.log("contentshiftlogcard", content);
  return (
    <div className="shift-log-card-wrapper">
      <div className="header">
        <span className="header-title">
          {content?.adminName} {content?.adminLastName} ({content?.userRole})
          <Tooltip
            title={`${moment
              .unix(content?.createdAt)
              .format("HH:mm:ss |  DD/MM/YYYY")}`}
          >
            <span style={{ marginLeft: "5px" }}>
              ndryshoi turnin {moment.unix(content?.createdAt).fromNow()}
            </span>
          </Tooltip>
          {!!content?.ipResponse && (
            <Tooltip
              title={`IP: ${content?.ipResponse?.IPv4}, ${content?.ipResponse?.city}, ${content?.ipResponse?.country_name} `}
            >
              <span style={{ marginLeft: "5px" }}>
                {" "}
                ({content?.ipResponse?.IPv4})
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <div className="content">
        <div className="content-shifts">
          <div className="original-shift">
            <span style={{ display: "flex", justifyContent: "center" }}>
              Turni Origjinal
            </span>
            <span
              className="shift-format"
              style={{ backgroundColor: "#EA3943" }}
            >
              {moment.unix(content.oldShift[0].clockInDate).format("HH:mm")} -{" "}
              {moment
                .unix(content.oldShift[0].clockOutDate)
                .format("HH:mm | DD/MM/YYYY") !== "Invalid date"
                ? moment
                    .unix(content.oldShift[0].clockOutDate)
                    .format("HH:mm | DD/MM/YYYY")
                : "--"}
            </span>
            {content?.oldShift[0]?.dayType !== content?.newShift[0]?.dayType &&
              !!content?.oldShift[0]?.dayType &&
              !!content?.newShift[0]?.dayType && (
                <span
                  className="shift-format"
                  style={{ backgroundColor: "#1D3445", marginTop: "5px" }}
                >
                  {getTypeTranslation(content?.oldShift[0]?.dayType)}
                </span>
              )}
          </div>
          <div className="new-shift">
            <span style={{ display: "flex", justifyContent: "center" }}>
              Turni i ri{" "}
            </span>
            <span
              className="shift-format"
              style={{ backgroundColor: "#1DA193" }}
            >
              {content.hasOwnProperty("newShift")
                ? moment
                    .unix(content?.newShift[0]?.clockInDate)
                    .format("HH:mm") +
                  " - " +
                  moment
                    .unix(content.newShift[0].clockOutDate)
                    .format("HH:mm DD/MM/YYYY")
                : null}
            </span>
            {content?.oldShift[0]?.dayType !== content?.newShift[0]?.dayType &&
              !!content?.oldShift[0]?.dayType &&
              !!content?.newShift[0]?.dayType && (
                <span
                  className="shift-format"
                  style={{ backgroundColor: "#1D3445", marginTop: "5px" }}
                >
                  {getTypeTranslation(content?.newShift[0]?.dayType)}
                </span>
              )}
          </div>
        </div>
        {!!content?.note && !content?.employee && (
          <div className="content-note">
            <span className="sticky-title">Shenim</span>
            {/* <span className="note-text">{content?.note.trim()}</span> */}
            <span className="note-text">
              {" "}
              {!!content?.note && htmlParser(content?.note.trim())}
            </span>
          </div>
        )}
        {!!content?.employee && (
          <div className="content-note">
            <span className="sticky-title">PUNONJESI</span>
            {/* <span className="note-text">{content?.note.trim()}</span> */}
            <span className="note-text"> {content?.employee}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShiftLogCard;

const getTypeTranslation = (type) => {
  switch (type) {
    case "Regular":
      return "Normal";
    case "Sick":
      return "Sëmurë";
    case "Maternity":
      return "Leje Lindje";
    case "NationalHoliday":
      return "Festë Zyrtare";
    case "HolidayRequest":
      return "Kërkesë Leje";
    default:
      return "";
  }
};
