import React, { useState } from "react";
import { Row, Col, Button, Select, Modal, Tooltip } from "antd";
import { IconFilter } from "../../../../assets/icons";
import { htmlParser } from "../../../../utils";
import { CaretDownFilled } from "@ant-design/icons";

function FilterPaisjetOrarit({
  gridApi,
  /*tableData,*/ clearFilters,
  descriptionduplicateCheck,
  ipduplicateCheck,
  duplicateCheck,
}) {
  const { Option } = Select;
  const [visibleFilter, setVisibleFilter] = useState(false);
  function closeFilter() {
    clearFilters();
    setVisibleFilter(false);
  }
  const onSelectChange = async (e, field) => {
    await gridApi.getColumnFilterInstance(field).then((comp) => {
      comp.setModel({
        type: "contains",
        filter: e,
      });
    });
    gridApi.onFilterChanged();
  };

  return (
    <div>
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Filter i avancuar"}
        style={{ opacity: 1.0 }}
      >
        <IconFilter
          className="header-icons"
          onClick={() => setVisibleFilter(true)}
        />{" "}
      </Tooltip>
      <Modal
        className="global-filter-modal"
        title="Kërkim i Avancuar"
        destroyOnClose
        open={visibleFilter}
        onOk={() => setVisibleFilter(false)}
        onCancel={() => setVisibleFilter(false)}
        // destroyOnClose={true}
        footer={[
          <Button onClick={closeFilter} className="charge-close-btn">
            Mbyll
          </Button>,
          <Button
            onClick={() => setVisibleFilter(false)}
            className="charge-search-btn"
          >
            Kërko
          </Button>,
        ]}
      >
        <Row gutter={10} style={{ width: "100%" }}>
          <Col className="coln-2" span={8}>
            <h3>Emri paisjes:</h3>
            <Select
              showSearch
              variant={false}
              className="filter-dropdown-global"
              style={{
                background: "#F5F5F7",
                border: 0,
                borderRadius: 5,
                width: "100%",
              }}
              suffixIcon={<CaretDownFilled />}
              placeholder="Emri"
              onChange={(e) => onSelectChange(e, "name")}
            >
              {duplicateCheck?.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {Data}
                  </Option>
                )
              )}
            </Select>
          </Col>

          <Col className="coln-1" span={8}>
            <h3>Përshkrimi paisjes:</h3>
            <Select
              showSearch
              variant={false}
              className="filter-dropdown-global"
              style={{
                background: "#F5F5F7",
                border: 0,
                borderRadius: 5,
                width: "100%",
              }}
              suffixIcon={<CaretDownFilled />}
              placeholder="Përshkrimin"
              onChange={(e) => onSelectChange(e, "description")}
            >
              {descriptionduplicateCheck?.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {htmlParser(Data)}
                  </Option>
                )
              )}
            </Select>
          </Col>

          <Col className="coln-3" span={8}>
            <h3>Ip adresa:</h3>
            <Select
              showSearch
              variant={false}
              className="filter-dropdown-global"
              style={{
                background: "#F5F5F7",
                border: 0,
                borderRadius: 5,
                width: "100%",
              }}
              suffixIcon={<CaretDownFilled />}
              placeholder="Adresa Ip"
              onChange={(e) => onSelectChange(e, "ip")}
            >
              {ipduplicateCheck?.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {Data}
                  </Option>
                )
              )}
            </Select>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default FilterPaisjetOrarit;
