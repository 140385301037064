import { Calendar, Form } from "antd";
import React, { useContext, useState } from "react";
import dayjs from "../../../../../utils/dayjs";
import { Typography, Button } from "antd";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import "./CalendarInput.scss";
import Time from "./Time";
import { InterviewsContext } from "../../../data";

const { Title } = Typography;

const CalendarInput = ({ form }) => {
  const { kandidatiSelektuar, isEditable, temporaryArray } =
    useContext(InterviewsContext);

  const editableDate = !!isEditable
    ? temporaryArray?.applicantInterviewDate ||
      kandidatiSelektuar?.applicantInterviewDate
    : dayjs().set("hour", 10).set("minute", 0);
  const [currentDate, setCurrentDate] = useState(dayjs(editableDate));
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedTime, setSelectedTime] = useState(dayjs(editableDate));

  const onPrevMonth = () => {
    const updateMonth = currentDate.subtract(1, "month");
    setCurrentDate(updateMonth);
  };
  const onNextMonth = () => {
    const updateMonth = currentDate.add(1, "month");
    setCurrentDate(updateMonth);
  };

  const headerRender = ({ value, type, onChange }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "5px 5px 0 0 ",
        }}
      >
        <div>
          <Title level={4} style={{ margin: 0 }}>
            {value.format("MMMM YYYY")}
          </Title>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            style={{
              backgroundColor: "#1264A3",
              color: "white",
              borderRadius: "5px",
              height: "auto",
            }}
            onClick={onPrevMonth}
            icon={<CaretLeftOutlined />}
          />
          <Button
            style={{
              backgroundColor: "#1264A3",
              color: "white",
              borderRadius: "5px",
              height: "auto",
            }}
            onClick={onNextMonth}
            icon={<CaretRightOutlined />}
          />
        </div>
      </div>
    );
  };

  const onSelectCalendar = (e) => {
    const date = e
      .set("hour", selectedTime?.get("hour") || 10)
      .set("minute", selectedTime?.get("minute") || 0);
    setSelectedDate(date);

    form.setFieldValue("applicantInterviewDate", date);
  };
  const handleTime = ({ name, value }) => {
    if (!!name && !!value) {
      const date = (selectedDate || currentDate).set(name, value);
      setSelectedDate(date);

      form.setFieldValue("applicantInterviewDate", date);
    }
  };

  const cellRender = (current) => {
    // const yesterday = moment().subtract(1, "days");
    return current && current.unix() < dayjs().subtract(1, "minute").unix();
    // return current && current < moment();
  };

  const dateFullCellRender = (current) => {
    const isSelected = current.isSame(selectedDate, "day");

    return (
      <div className={`date-cell ${isSelected ? "selected-date" : ""}`}>
        {current.date()}
      </div>
    );
  };

  return (
    <div className="interviewsCalendar">
      <Form.Item name="applicantInterviewDate">
        <div className="interviewsCalendarContainer">
          <Calendar
            fullscreen={false}
            mode="date"
            value={currentDate}
            headerRender={headerRender}
            fullCellRender={dateFullCellRender}
            disabledDate={cellRender}
            onSelect={onSelectCalendar}
          />
          <Time time={handleTime} {...{ selectedTime, setSelectedTime }} />
        </div>
      </Form.Item>
    </div>
  );
};

export default CalendarInput;
