import moment from "moment";

export const chartDateForMonth = (data = [], month = null) =>
  data?.filter(
    (item) => moment(item?.applicantInterviewDate).get("month") === month
  )?.length || 0;

export const statusInterviewData = (data = [], key = "", status = "") => {
  return (
    data?.filter((item) => {
      if (Array.isArray(item?.[key])) {
        return item?.[key]?.includes(status);
      }
      return item?.[key] === status;
    })?.length || 0
  );
};

export const months = [
  { name: "Janar", value: moment().month(0) },
  { name: "Shkurt", value: moment().month(1) },
  { name: "Mars", value: moment().month(2) },
  { name: "Prill", value: moment().month(3) },
  { name: "Maj", value: moment().month(4) },
  { name: "Qershor", value: moment().month(5) },
  { name: "Korrik", value: moment().month(6) },
  { name: "Gusht", value: moment().month(7) },
  { name: "Shtator", value: moment().month(8) },
  { name: "Tetor", value: moment().month(9) },
  { name: "Nentor", value: moment().month(10) },
  { name: "Dhjetor", value: moment().month(11) },
  { name: "Te gjithe muajt", value: "All" },
];

export const years = [
  moment().subtract(2, "year").format("YYYY"),
  moment().subtract(1, "year").format("YYYY"),
  moment().format("YYYY"),
];

const monthNames = [
  "Muaji",
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const colorsOfPlatform = [
  "#0A66C2",
  "#FF9800",
  "#1DA193",
  "#FFC107",
  "#FA8532",
  "#E91E63",
  "#795548",
  "#00BCD4",
  "#673AB7",
  "#F44336",
  "#009688",
  "#FFEB3B",
  "#3F51B5",
  "#FF5722",
  "#9B59B6",
  "#2ECC71",
  "#00BCD4",
  "#795548",
];

export const getChartColor = (index = 0) => {
  const colors = ["#EA3943", "#20AC9D", "#FCC94A", "#FCC94A"];
  return colors?.[index % colors?.length];
};

export const dataForCategories = (chartData = []) =>
  categories?.map?.((category, index) => [
    category,
    chartData?.["Aplikimet"]?.candidates?.length,
    getChartColor(index),
  ]);

export const categories = [
  "Architecture & Engineering",
  "Sales Management",
  "Finance & Accounting",
  "IT & Software Development",
];

const dataRows = (chartData = []) =>
  monthNames?.map?.((month, index) => {
    const monthIndex = index - 1;
    return [
      month,
      chartDateForMonth(
        chartData?.["Intervista te planifikuara"]?.candidates,
        monthIndex
      ),
      barChartColors(
        chartDateForMonth(
          chartData?.["Intervista te planifikuara"]?.candidates,
          monthIndex
        )
      ),
      null,
    ];
  });

const barChartColors = (nr) => {
  if (nr <= 20) {
    return "#20AC9D";
  } else if (nr > 20 && nr <= 40) {
    return "#FCC94A";
  } else {
    return "#EA3943";
  }
};

export const charts = (chartData = {}, chartLablels = []) => [
  {
    topTitle: "Intervista te planifikuara",
    selectOptions: months,
    years,
    props: {
      options: {
        legend: { position: "none" },
        hAxis: {
          title: "Intervista te planifikuara",
        },
      },
      chartType: "BarChart",
      height: 320,
      data: [
        [
          "Muaji",
          "Intervista te planifikuara",
          { role: "style" },
          {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
          },
        ],
        ...dataRows(chartData),
      ],
    },
  },
  {
    topTitle: "Aplikimet",
    selectOptions: months,
    years,
    props: {
      options: {
        legend: {
          position: "top",
          alignment: "center",
          maxLines: 200,
        },
      },
      height: 320,
      chartType: "PieChart",
      data: [
        ["Pozicioni", "Number of applicants"],
        ...chartLablels
          ?.find((items) => items.formItemName === "applicantDepartment")
          ?.options?.map((item) => {
            return [
              item,
              statusInterviewData(
                chartData?.["Aplikimet"]?.candidates,
                "applicantDepartment",
                item
              ),
            ];
          }),
      ],
    },
  },
  {
    topTitle: "Pranuar/Skualifikuar",
    props: {
      options: {
        legend: {
          position: "top",
          alignment: "center",
          maxLines: 200,
        },
        is3D: true,
        slices: {
          0: { color: "#EA3943" },
          1: { color: "#FA8532" },
          2: { color: "#FCC94A" },
          3: { color: "#20AC9D" },
        },
      },
      height: 320,
      chartType: "PieChart",
      data: [
        ["Intervistat", "Statusi"],
        ...chartLablels
          ?.find((items) => items.formItemName === "applicantStatus")
          ?.options?.map((item) => {
            return [
              item,
              statusInterviewData(
                chartData?.["Pranuar/Skualifikuar"]?.candidates,
                "applicantStatus",
                item
              ),
            ];
          }),
      ],
    },
    selectOptions: months,
    years,
  },
  {
    topTitle: "Platformat",
    selectOptions: months,
    years,
    props: {
      options: {
        chartArea: { width: "60%" },

        legend: "none",
        hAxis: {
          title: "Intervista te planifikuara",
        },
        vAxis: {
          textStyle: {
            fontSize: 12,
          },
        },
      },
      chartType: "BarChart",
      height: 320,
      data: [
        ["Platformat", "", { role: "style" }],
        ...chartLablels
          ?.find((items) => items.formItemName === "applicantPlatform")
          ?.options?.map((item, index) => {
            return [
              item,
              statusInterviewData(
                chartData?.["Platformat"]?.candidates,
                "applicantPlatform",
                item
              ),
              colorsOfPlatform[index],
            ];
          }),
      ],
    },
  },
  {
    topTitle: "Team",
    props: {
      options: {
        legend: {
          position: "top",
          alignment: "center",
          maxLines: 200,
        },
        is3D: true,

        slices: {
          0: { color: "#2E64C0" },
          1: { color: "#C3D6D2" },
        },
      },
      height: 320,
      chartType: "PieChart",
      data: [
        ["Intervistat", "Team"],
        ...chartLablels
          ?.find((items) => items.formItemName === "applicantTeam")
          ?.options?.map((item) => {
            return [
              item,
              statusInterviewData(
                chartData?.["Team"]?.candidates,
                "applicantTeam",
                item
              ),
            ];
          }),
      ],
    },
    selectOptions: months,
    years,
  },
  {
    topTitle: "Ditët në provë",
    selectOptions: months,
    years,
    props: {
      options: {
        legend: {
          position: "none",
        },
      },
      chartType: "ColumnChart",
      height: 320,
      data: [
        ["Ditët në provë", "", { role: "style" }],
        [
          "1 Ditë",
          2,
          "red",
          // statusInterviewData(
          //   chartData?.["Platformat"]?.candidates,
          //   "applicantPlatform",
          //   "Linkedin"
          // ),
        ],
        [
          "2 Ditë",
          1,
          "black",
          // statusInterviewData(
          //   chartData?.["Platformat"]?.candidates,
          //   "applicantPlatform",
          //   "Instagram"
          // ),
        ],
        [
          "3 Ditë",
          6,
          "green",
          // statusInterviewData(
          //   chartData?.["Platformat"]?.candidates,
          //   "applicantPlatform",
          //   "Flex"
          // ),
        ],
        [
          "4 Ditë",
          2,
          "pink",
          // statusInterviewData(
          //   chartData?.["Platformat"]?.candidates,
          //   "applicantPlatform",
          //   "Referuar"
          // ),
        ],
        [
          "6 Ditë",
          4,
          "Blue",
          // statusInterviewData(
          //   chartData?.["Platformat"]?.candidates,
          //   "applicantPlatform",
          //   "Referuar"
          // ),
        ],
      ],
    },
  },
];
