import Modal from "antd/lib/modal/Modal";
import "./LlojetKerkesaveLogs.scss";
import { Button } from "antd";
import AgGridComponent from "../../../../AG-grid/AgGridComponent";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { htmlParser } from "../../../../../utils";
import { ModalXIcon } from "../../../../../assets/icons";

const LlojetKerkesaveLogs = ({ data, isVisible, setIsVisible, logsName }) => {
  const [tableData, setTableData] = useState([]);
  const authorFormatter = (params) => {
    if (params.value) {
      const author = `${params.value.employeeFirstName} ${params.value.employeeLastname}`;
      return author;
    }
    return "";
  };
  const dateFormatter = (params) => {
    if (params.value) {
      const formattedDate = moment(params.value).format("DD/MM/YYYY HH:mm ");
      return formattedDate;
    }
    return "";
  };

  const columnDefs = [
    {
      headerName: "Autori",
      field: "author",
      valueFormatter: authorFormatter,
    },

    {
      headerName: "Koha e ndryshimit",
      field: "changeDate",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Activity",
      field: "activity",
    },
    {
      headerName: "Titulli i kërkesës",
      field: "statusTitle",
    },
    {
      headerName: "Përshkrimi i kërkesës",
      field: "statusDescription",
      cellRenderer: ({ value }) => htmlParser(value),
    },
  ];

  const onFirstDataRendered = useCallback((param) => {
    param.api.autoSizeAllColumns(false);
  }, []);

  useEffect(() => {
    let values = [];
    let newData = data?.fieldOptions?.find((item) => item.name === "Logs");
    if (newData) {
      newData?.value?.forEach((item) => {
        values.push({ ...item, ...item?.currentData });
      });
    }
    setTableData(values);
  }, [isVisible]);

  return (
    <Modal
      className="paisjetOraritLogs"
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
      title="Paisjet Logs"
      closeIcon={<ModalXIcon />}
      width={900}
      footer={[
        <Button className="decline" onClick={() => setIsVisible(false)}>
          Mbyllni faqen
        </Button>,
      ]}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
      >
        <AgGridComponent
          columnDefs={columnDefs}
          customDefaultColDef={{ flex: undefined }}
          rowData={tableData}
          overlayLoadingTemplate="Historiku është bosh!"
          autoSizeStrategy={{ type: "fitCellContents" }}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </Modal>
  );
};

export default LlojetKerkesaveLogs;
