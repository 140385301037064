import { message } from "antd";
import dayjs from "../../../utils/dayjs";
export const getDisabledAfterHours = () => {
  let hours = [];
  for (let i = dayjs().hour() + 1; i < 24; i++) {
    hours.push(i);
  }
  return hours;
};

// export const getDisabledAfterCustomHours = (selectedHour) => {
//   let hours = [];
//   for (let i = moment(selectedHour).hour() + 1; i < 24; i++) {
//     hours.push(i);
//   }
//   return hours;
// };

export const getDisabledAfterMinutes = (selectedHour) => {
  var minutes = [];
  if (selectedHour === dayjs().hour()) {
    for (let i = dayjs().minute() + 1; i < 60; i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const getDisabledBeforeHours = () => {
  let hours = [];

  for (let i = 0; i < dayjs().hour() + 1; i++) {
    hours.push(i);
  }
  return hours;
};

export const getDisabledAfterCustomHours = (selectedHour) => {
  let hours = [];
  for (let i = dayjs(selectedHour).hour() + 1; i < 24; i++) {
    hours.push(i);
  }
  return hours;
};

export const getDisabledBeforeCustomHours = (momentInst) => {
  let hours = [];
  for (let i = 0; i < dayjs(momentInst).hour(); i++) {
    hours.push(i);
  }
  return hours;
};
export const getDisabledBeforeCustomMinutes = (selectedHour, inst) => {
  let minutes = [];
  if (dayjs(selectedHour).hour() === inst) {
    for (let i = 0; i < dayjs(selectedHour).minute() + 5; i++) {
      minutes.push(i);
    }
  }
  return minutes;
};
export const getDisabledAfterCustomMinutes = (selectedHour, inst) => {
  var minutes = [];
  if (dayjs(selectedHour).hour() === inst) {
    for (let i = dayjs(selectedHour).minute() - 4; i < 60; i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const getDisabledAllHours = () => {
  let hours = Array.from(Array(24).keys()).filter(
    (el) => el !== dayjs().hour()
  );

  if (dayjs().minute() - 5 < 0) {
    hours = hours.filter((el) => el !== dayjs().hour() - 1);
  }
  return hours;
};

// export const getDisabledButGracePeriodMins = (inst, selectedHour) => {
//   let minutes = Array.from(Array(60).keys()).filter((el) => {
//     if (moment().hour() === selectedHour) {
//       if (moment().minute() - el < 5 && moment().minute() - el >= 0) {
//         return null;
//       } else {
//         return el;
//       }
//     }
//   });
//   return minutes;
// };
export const getDisabledButGracePeriodMins = (inst, selectedHour) => {
  let minutes = [];
  // console.log("Selected", moment().minute() - 5);
  if (selectedHour === dayjs().hour() && dayjs().minute() - 5 >= 0) {
    minutes = [
      ...Array.from(Array(60).keys()).filter(
        (el) => dayjs().minute() - el < 0 || dayjs().minute() - el >= 5
      ),
    ];
  } else {
    let indicator = 5 - dayjs().minute();
    minutes = [
      ...Array.from(Array(60).keys()).filter((el) => el <= 60 - indicator),
    ];
  }
  return minutes;
};

/// Disabled Functions for Clock out time on Clock Out Modal!
export const getDisabledClockOutHours = (momentInst) => {
  let hours = [];
  let upper_limit = dayjs("14:30", "HH:mm").valueOf();
  let moment_now = dayjs().valueOf();
  for (let i = 0; i < dayjs(momentInst).hour(); i++) {
    hours.push(i);
  }
  if (moment_now >= upper_limit) {
    let hours_2 = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    hours_2.forEach((num) => {
      if (!hours.includes(num)) {
        hours.push(num);
      }
    });
  }
  // console.log("Hours", hours);
  return hours;
};

export const getDisabledClockOutMinutes = (selectedHour, inst) => {
  let minutes = [];
  if (dayjs(selectedHour).hour() === inst) {
    for (let i = 0; i < dayjs(selectedHour).minute() + 5; i++) {
      minutes.push(i);
    }
  }
  if (dayjs(selectedHour).hour() === "21") {
    minutes = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29,
    ];
  }
  return minutes;
};
