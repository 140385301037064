import { IconKerko } from "../../../../assets/icons";
import { useState } from "react";
import { Input } from "antd";
import MondayButton from "../../MondayButton/MondayButton";

const SearchAgGrid = ({ gridApi, handleClean }) => {
  const [input, setInput] = useState(null);

  const handleSearchChange = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
    setInput(e.target.value);
  };

  return (
    <div className="headerSearch">
      <div className="icon">
        <IconKerko width={16} height={16} fill="#111" />
      </div>
      <Input
        className="headerInput"
        placeholder="Kërko"
        onChange={handleSearchChange}
        value={input}
      />
      <MondayButton
        className="mondayButtonRed headerButton"
        onClick={() => {
          handleClean();
          setInput(null);
        }}
      >
        Pastro
      </MondayButton>
    </div>
  );
};

export default SearchAgGrid;
