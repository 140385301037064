import MondayButton from "../../../../../../commonComponents/MondayButton/MondayButton";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Delete, SchedulingWhite } from "../../../../assets";
import "./ActionButtons.scss";

const ActionButtons = ({
  isEditing,
  onDelete,
  onCancel,
  onSave,
  onKeylogs,
  saveButtonText = "Ruaj Dokumentin",
  writeRight = true,
}) => {
  return (
    <div
      className={`docViewActionButtons ${
        !isEditing || !writeRight ? "notEditing" : ""
      }`.trim()}
    >
      {!!isEditing && !!writeRight && (
        <MondayButton
          {...{
            className: "mondayButtonRed",
            Icon: <Delete />,
            onClick: onDelete,
          }}
        >
          Fshi Dokumentin
        </MondayButton>
      )}
      {!!isEditing && !!writeRight && (
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            Icon: <SchedulingWhite width={20} height={20} />,
            onClick: onKeylogs,
          }}
        >
          Regjistri i Dokumentacionit
        </MondayButton>
      )}

      <MondayButton
        {...{
          className: "mondayButtonGrey",
          Icon: <CloseOutlined />,
          onClick: onCancel,
        }}
      >
        Anulo
      </MondayButton>

      {!!writeRight && (
        <MondayButton
          {...{
            className: "mondayButtonBlue",
            Icon: <CheckOutlined />,
            onClick: onSave,
          }}
        >
          {saveButtonText}
        </MondayButton>
      )}
    </div>
  );
};

export default ActionButtons;
