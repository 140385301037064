import { useMemo } from "react";
import "./CandidatesConfigKanban.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { apiPut } from "../../../DocumentationConfiguration/utils/api";
import { useSelector } from "react-redux";
import { Badge, message, Tooltip } from "antd";
import { Delete } from "../../../../assets/icons";

const CandidatesConfigKanban = ({
  endpoint,
  setRowData,
  setChanges,
  onItemClick,
  rowData = [],
  programField,
  setDeletedFields,
  groupBy = "step",
}) => {
  const data = useMemo(
    () => Object.groupBy(rowData, (row) => [row[groupBy]]),
    [rowData, groupBy]
  );
  //region DRAG HANDLER
  const dragEndHandler = async (value) => {
    const { source, destination } = value;
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    } else {
      const sourceCol = source.droppableId.split("-")[1];
      const destinationCol = destination.droppableId.split("-")[1];

      // selected item
      let draggedRow = data[sourceCol][source.index];

      draggedRow[groupBy] =
        typeof destinationCol === "number"
          ? parseInt(destinationCol)
          : destinationCol;

      //delete item from source
      const newSrcColData = data[sourceCol].filter(
        (_, i) => i !== source.index
      );

      let srcColToSend = newSrcColData;
      let destColToSend;

      if (sourceCol === destinationCol) {
        //add to source with new index
        srcColToSend = newSrcColData.toSpliced(
          destination.index,
          0,
          draggedRow
        );
      } else {
        //add to destination with new index
        destColToSend = data[destinationCol].toSpliced(
          destination.index,
          0,
          draggedRow
        );
      }

      const toSend = [];
      for (const [groupBy, arr] of Object.entries(data)) {
        if (groupBy == sourceCol) {
          toSend.push(srcColToSend);
        } else if (groupBy == destinationCol) {
          toSend.push(destColToSend);
        } else {
          toSend.push(arr);
        }
      }
      const swalProps = {
        toast: true,
        timer: 3000,
        showConfirmButton: false,
        position: "bottom-end",
      };
      try {
        Swal.fire({
          title: "Ju lutem prisni...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await apiPut(endpoint, programField?.fieldId, {
          fieldOptions: [...toSend.flat(1)],
        }).then(() => {
          setRowData(toSend.flat(1));
          Swal.close();
          Swal.mixin({
            ...swalProps,
          }).fire({
            icon: "success",
            title: `${draggedRow?.label} u zhvendos me sukses`,
          });
        });
      } catch (err) {
        message.error("Error ne perditesimin e konfigurimit te kandidateve!");
        Swal.mixin({
          ...swalProps,
        }).fire({
          icon: "error",
          title: "Perditesimi nuk u krye!",
        });
        console.error("Error:", err);
      }
    }
  };
  // region ON DELETE
  const onItemDelete = (e, item) => {
    e.stopPropagation();

    setRowData((prev) => prev.filter((el) => el.fieldId !== item.fieldId));
    setDeletedFields((prev) => [...prev, { ...item, deletedField: true }]);
    setChanges(true);
  };
  //region RETURN
  return (
    <DragDropContext
      onDragStart={() => {}}
      onDragEnd={(val) => dragEndHandler(val)}
    >
      <div className="kanbanContainer">
        {Object.entries(data)?.map(([step, items], idx) => (
          <Droppable droppableId={`droppable-${step}`} key={idx}>
            {(provided, snapshot) => (
              <div
                className="kanbanColumn"
                {...provided?.droppableProps}
                ref={provided?.innerRef}
              >
                <div className="kanbanColHeader">stepi numer {step}</div>
                <div className="kanbanColContent">
                  {items?.map((item, i) => (
                    <Draggable
                      draggableId={`draggable-${step}-${i}`}
                      index={i}
                      key={i}
                    >
                      {(provided, snapshot) => (
                        <Tooltip
                          title={"Click to edit"}
                          placement="top"
                          mouseEnterDelay={0.5}
                        >
                          <div
                            className="kanbanItem"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            onClick={() => onItemClick(item)}
                          >
                            <div
                              className="deleteItem"
                              onClick={(e) => onItemDelete(e, item)}
                            >
                              <Delete width={22} height={22} fill="#ea3943" />
                            </div>
                            <div className="itemLabels">
                              <span className="itemLabel">{item?.label}</span>
                              <span className="itemFormName">
                                {`(${item?.formItemName})`}
                              </span>
                            </div>
                            {!!item?.required ? (
                              <Badge.Ribbon text={"required"} color="red" />
                            ) : null}
                          </div>
                        </Tooltip>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default CandidatesConfigKanban;
