import React from "react";
import "./AddLeaveStatus.scss";
import { Button } from "antd";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";

function AddLeaveStatus({ status, onClick }) {
  return (
    <MondayButton
      onClick={onClick}
      className={`leaveStatus ${
        status === "Refuzuar"
          ? "mondayButtonRed"
          : status === "Pending"
          ? "mondayButtonYellow"
          : status === "Aprovuar"
          ? "mondayButtonGreen"
          : "mondayButtonDarkGrey"
      }`}
      disabled={status === "Përfunduar"}
      size="small"
    >
      {status}
    </MondayButton>
  );
}

export default AddLeaveStatus;
