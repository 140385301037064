import "./EmailTemplate.scss";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import EmailTemplateModal from "./EmailTemplateModal/EmailTemplateModal";
import { useSelector } from "react-redux";
import { columnDefs } from "./EmailTemplateHeader";
import { TickIcon, XIcon } from "../../../assets/icons";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import { PlusOutlined } from "@ant-design/icons";

const EmailTemplate = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const [gridApi, setGridApi] = useState(null);
  const [selectedCategory] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [changes, setChanges] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const gridData = useRef([]);
  const gridContainerRef = useRef();

  const TableData = useMemo(
    function () {
      return programFields?.find((el) => el?.fieldName === "Email Templates");
    },
    [programFields]
  );

  //find the email templates
  const QuestionProgramField = programFields.find(
    ({ fieldName }) => fieldName === "Email Templates"
  );

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  //get the aggrid rowid
  const getRowId = useCallback((param) => {
    return param?.emailTemplateId;
  }, []);

  /**
   *
   * @param {Object[]} data List of elements to update in grid
   * @param {string} action Actions: "add", "remove", "update"
   */
  const refreshTable = (data, action) => {
    if (!["add", "remove", "update"].includes(action)) {
      return;
    }
    if (action === "add") {
      gridApi.applyTransaction({
        [action]: Array.isArray(data) ? data : [data],
        addIndex: 0,
      });
    } else {
      gridApi.applyTransaction({
        [action]: Array.isArray(data) ? data : [data],
      });
    }
  };

  //funciton to edit a item in aggrid
  const handleEdit = (data) => {
    setEditData(data);
    setIsModalVisible(true);
  };

  //function to delete a item in aggrid
  const handleDelete = (data) => {
    refreshTable(data, "remove");
    setChanges(true);
  };
  //useEffect to populate the aggrid
  useEffect(() => {
    gridData.current = TableData?.fieldOptions;
  }, [TableData?.fieldOptions]);

  return (
    (<div className="emailTemplateContainer">
      <div className="fristRowContainer">
        <MondayButton
          className="mondayButtonGreen"
          Icon={<PlusOutlined />}
          onClick={() => handleOk()}
        >
          New Template
        </MondayButton>
      </div>
      <div className="ag-theme-alpine agContainer" ref={gridContainerRef}>
        <AgGridReact
          {...{
            gridApi,
            getRowNodeId: getRowId,
            columnDefs: columnDefs({ handleEdit, handleDelete }),
            rowData: gridData.current,
            defaultColDef: {
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              // flex: 1,
              minWidth: 100,
              filter: true,
            },
            rowHeight: 45,
            headerHeight: 45,
            rowGroupPanelShow: "always",
            pagination: true,
            animateRows: true,
            paginationPageSize: !selectedCategory ? 11 : undefined,
            onGridReady,
          }}
        />
      </div>
      <div className="konfigurimi-footer">
        <MondayButton
          {...{
            className: `whiteIconBackground ${
              changes ? "mondayButtonRed " : "mondayButtonGray"
            }`,
            Icon: <XIcon height={10} width={10} />,
            style: { display: "inline" },
            disabled: !changes,
            onClick: () => setIsConfirmModalVisible("Cancel"),
          }}
        >
          Cancel Changes
        </MondayButton>
        <MondayButton
          {...{
            className: `${changes ? "mondayButtonGreen " : "mondayButtonGray"}`,
            Icon: <TickIcon height={17} width={17} />,
            style: { display: "inline" },
            disabled: !changes,
            onClick: () => setIsConfirmModalVisible("Confirm"),
          }}
        >
          Save Changes
        </MondayButton>
      </div>
      {isModalVisible && (
        <EmailTemplateModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          QuestionProgramField={QuestionProgramField}
          editData={editData}
          setEditData={setEditData}
          refreshTable={refreshTable}
          setChanges={setChanges}
        />
      )}
      {!!isConfirmModalVisible && (
        <ConfirmModal
          isConfirmModalVisible={isConfirmModalVisible}
          setIsConfirmModalVisible={setIsConfirmModalVisible}
          TableData={TableData}
          setChanges={setChanges}
          gridApi={gridApi}
          QuestionProgramField={QuestionProgramField}
          programFields={programFields}
        />
      )}
    </div>)
  );
};

export default EmailTemplate;
