import React, { useEffect, useState } from 'react'
import MondayButton from '../../commonComponents/MondayButton/MondayButton'
import { TickIcon, XIcon } from '../../../assets/icons'
import { PlusOutlined } from '@ant-design/icons'
import InputComponent from '../../InputComponent/InputComponent'
import { Form, Modal, Select, Tooltip } from 'antd'
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

export const NewFieldModal = ({
    showCreateModal,
    setShowCreateModal,
    rowData,
    setChanges,
    setRowData,
    fieldCreatedBy,
}) => {
    const [form] = Form.useForm()
    const [selectedType, setSelectedType] = useState(null)
    const [options, setOptions] = useState([])
    const [newOpt, setNewOpt] = useState("")
    const [rules, setRules] = useState()

    const rulesObject = {
        email: [{
            type: "email",
            message: "Ju lutem shkruani email-in e sakte",
            required: true,
        }],
        number: [{
            type: "number",
            message: "Ju lutem shkruani numrin e sakte",
            required: true,
        }],

        phoneNumber:
            [{
                type: "number",
                pattern: "^355(?:67|68|69)\\d{7}$",
                message: "Ju lutem shkruani numrin ne formatin 355 67/68/69 1234567",
                required: true,
            }]


    }

    const rulesFormatter = (value, object) => {
        if (object.hasOwnProperty(value)) {
            return (
                object[value]
            )
        }
        else {
            return []
        }
    }


    const rulesValueFormatter = (value, object) => {
        for (let key in object) {
            if (_.isEqual(value, object[key])) {
                return key
            }
        }

    }


    const onClose = () => {
        setSelectedType(null)
        setOptions([])
        setNewOpt("")
        setShowCreateModal(undefined)
        form.resetFields()
        setRules([])
    }
    useEffect(() => {
        if (showCreateModal?.fieldId) {
            form.setFieldsValue({ ...showCreateModal, rules: !!showCreateModal?.rules?.length ? rulesValueFormatter(showCreateModal.rules, rulesObject) : null })
            setSelectedType(showCreateModal.type)
            setOptions(showCreateModal.options || [])
            setRules(!!showCreateModal?.rules?.length ? rulesValueFormatter(showCreateModal.rules, rulesObject) : null)
        }
    }, [showCreateModal])

    const addOption = () => {
        if (
            newOpt &&
            newOpt?.length &&
            !options?.some?.((el) => el?.toLowerCase() === newOpt?.toLowerCase())
        ) {
            setOptions(options.concat(newOpt))
            setNewOpt(undefined)
        }
    }

    const onSave = async () => {
        let values = form.getFieldsValue();
        let formField = {
            ...{
                fieldId: showCreateModal.fieldId,
                label: values.label,
                formItemName: values.formItemName,
                placeholder: values.type === "select" ? `Selektoni ${values.label}...` : `Shkruani ${values.label}...`,
                options: options,
                required: values.type === "customquill" ? false : values.required,
                type: values.type,
                step: parseInt(values.step),
                rules: rulesFormatter(rules, rulesObject),
            }
        }

        try {
            if (!showCreateModal.fieldId) {
                console.log("Create Field")
                formField = { ...formField, fieldId: uuidV4(), deletedField: false, createdAt: moment().valueOf(), createdBy: fieldCreatedBy }
                // gridApi.applyTransaction({ add: [formField] })
                setRowData(prev => ([...prev, formField]))
                setChanges(true)
                onClose()
                
            } else {
                console.log("Edit Field")
                setRowData((prev) =>
                  prev.map((el) =>
                    el?.fieldId === formField?.fieldId
                      ? { ...formField }
                      : el
                  )
                );
                // gridApi.applyTransaction({ update: [formField] })
                setChanges(true)
                onClose()
            }
        } catch (error) {
            console.error("Error at NewFieldModal ", error)
        }
    }


    return (
        <Modal
            open={showCreateModal}
            title={showCreateModal?.fieldId ? "Edit Field" : "New Field"}
            destroyOnClose={true}
            centered
            closeIcon={<XIcon />}
            onCancel={() => {
                setShowCreateModal(undefined)
                onClose()
            }}
            className='newQuestionForFleetModal lightHeader'
            footer={[
                <>
                    <MondayButton className='mondayButtonRed whiteIconBackground' Icon={<XIcon width={10} height={10} />} onClick={() => {
                        setShowCreateModal(undefined)
                    }}>Cancel</MondayButton>
                    <MondayButton Icon={<TickIcon width={18} height={18} />} onClick={() => form?.validateFields().then(onSave)} className='mondayButtonGreen'>Save</MondayButton>
                </>
            ]}
        >
            <Form layout="vertical" form={form}>
                <InputComponent
                    className='labelInput'
                    type='input'
                    label={(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                                *
                            </span>
                            Name
                        </div>
                    )}
                    placeholder='Enter the name of the question...'
                    formItemName="label"
                    rules={[
                        {
                            required: true,
                            message: "Please input the name of the question!"
                        },
                        {
                            validator: async (_, value) => {
                                const isDuplicate = rowData.filter((detail) => showCreateModal.fieldId ? detail.label !== showCreateModal.label : detail).some((detail) => detail.label.toLowerCase() === value.toLowerCase())
                                console.log(isDuplicate)
                                if (isDuplicate) {
                                    throw new Error("Field name already exists")
                                }
                            },

                        }
                    ]}
                />
                <InputComponent
                    className='labelInput'
                    type='input'
                    label={(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                                *
                            </span>
                            Form Item Name
                        </div>
                    )}
                    placeholder='Enter the name of the question...'
                    formItemName="formItemName"
                    rules={[
                        {
                            required: true,
                            message: "Please input the name of the question!"
                        },

                    ]}
                />
                <InputComponent
                    type='select'
                    label={(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                                *
                            </span>
                            Required
                        </div>
                    )}
                    rules={[
                        {
                            required: true,
                            message: "Please choose if required or not!"
                        },
                    ]}
                    formItemName="required"
                    className='requiredSelect'
                    placeholder='Select if question is not required or not'
                    customOptions={[
                        {
                            key: 0,
                            value: true,
                            label: "Yes",
                        },
                        {
                            key: 1,
                            value: false,
                            label: "No",
                        },

                    ]}
                />
                <InputComponent
                    type='select'
                    label={(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                                *
                            </span>
                            Step
                        </div>
                    )}
                    rules={[
                        {
                            required: true,
                            message: "Please choose which step it belongs to!"
                        },
                    ]}
                    formItemName="step"
                    className='requiredSelect'
                    placeholder='Select step for this question'
                    customOptions={[
                        {
                            key: 0,
                            value: 1,
                            label: "Step 1",
                        },
                        {
                            key: 1,
                            value: 2,
                            label: "Step 2",
                        },

                    ]}
                />
                <InputComponent
                    type="select"
                    label={(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                                *
                            </span>
                            Type
                        </div>
                    )}
                    rules={[
                        {
                            required: true,
                            message: "Please select question type!"
                        }
                    ]}
                    className='requiredSelect'
                    placeholder='Select type of question'
                    formItemName="type"
                    onSelect={(e) => setSelectedType(e)}
                    customOptions={[
                        {
                            key: 0,
                            value: "input",
                            label: "Input",
                        },
                        {
                            key: 1,
                            value: "radio",
                            label: "Radio",
                        },
                        {
                            key: 2,
                            value: "datepicker",
                            label: "Datepicker",
                        },
                        {
                            key: 3,
                            value: "select",
                            label: "Select",
                        },
                        {
                            key: 4,
                            value: "phone",
                            label: "Phone",
                        },
                        {
                            key: 5,
                            value: "timepicker",
                            label: "Timepicker",
                        },
                        {
                            key: 6,
                            value: "quill",
                            label: "Text Area",
                        },
                        {
                            key: 7,
                            value: "customquill",
                            label: "Text Area with Radio Button",
                        },
                        {
                            key: 8,
                            value: "tagSelect",
                            label: "Select with tags",
                        },

                    ]}
                />
                {(selectedType === "radio" || selectedType === "select" || selectedType === "tagSelect") && (
                    <div className='addOptionContainer'>
                        <InputComponent
                            className="addOptionInput"
                            // formItemName="inputOptions"
                            type="input"
                            label="Add Option"
                            placeholder='Add Option...'
                            value={newOpt}
                            onChange={(e) => setNewOpt(e.target.value)}
                            onPressEnter={addOption}

                        />
                        <Tooltip
                            title={
                                !!newOpt?.length === 0 ? "Option needs a name" : !!options?.some(el => el?.toLowerCase() === newOpt?.toLowerCase())
                                    ? "This option already exists"
                                    : ""
                            }
                        >
                            <div

                                onClick={addOption}
                                className={` ${!!newOpt?.length === 0 || !!options?.some?.(
                                    el => el?.toLowerCase() === newOpt?.toLowerCase()
                                )
                                    ? "disabled"
                                    : "addButton"
                                    }`}
                            >

                                <PlusOutlined
                                    style={{
                                        color: "#FFF",
                                        width: 23,
                                        height: 23,
                                        fontSize: "22.5px",
                                    }}
                                />
                            </div>
                        </Tooltip>

                    </div>
                )}
                {!!options?.length &&
                    <div className='optionSection'>
                        {
                            options.map((option, index) => {
                                return (
                                    <div key={index} className='optionContainer'>
                                        <InputComponent
                                            className={`optionContainer ${option} `}
                                            type="input"
                                            value={option}
                                        />
                                        <div
                                            onClick={() => {
                                                document
                                                    .getElementsByClassName(`${option}`)[0]
                                                    .classList.add("deletingOption");
                                                setTimeout(() => {
                                                    setOptions(options.filter((el) => el !== option));
                                                }, 350);
                                            }}
                                            className="removeButton"
                                        >
                                            <XIcon className='whiteIconBackground' style={{ fill: "#fff" }} />
                                        </div>

                                    </div>

                                )
                            })}
                    </div>}
                {(selectedType === "input") && (

                    <InputComponent
                        type="select"
                        label={(
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <span
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    *
                                </span>
                                Zgjidhni Rules
                            </div>
                        )}
                        rules={[
                            {
                                required: false,
                                message: "Please select question type!"
                            }
                        ]}
                        className='requiredSelect'
                        placeholder='Select type of question'
                        formItemName="rules"
                        value={rules}
                        onSelect={(e) => setRules(e)}
                        customOptions={[
                            {
                                key: 0,
                                value: "email",
                                label: "Email Rule",
                            },
                            {
                                key: 1,
                                value: "number",
                                label: "Number rule",
                            },

                        ]}
                    />
                )}
            </Form>

        </Modal >
    )
}
