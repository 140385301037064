import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./GeneratePage.scss";
import { DatePicker, message, Skeleton } from "antd";
import moment from "moment-timezone";
import { Select } from "antd";
import GeneratePageHeader from "./GeneratePageHeader";
import ReportContent from "./ReportContent/ReportContent";
import { useDispatch, useSelector } from "react-redux";
import { processFactory } from "../processFactory/processFactory";
import { reportsConstants } from "./constants";
import LoadableComp from "./../../commonComponents/LoadableComp/LoadableComp";
import { API } from "aws-amplify";
import { ReportProvider, useReportsData } from "./reportContext/reportHelpers";
import { requestDataWithParams } from "../../../helpers/API/RequestFactory";
import { clockings as clockingsAction } from "../../../store/actions";
import { useRedux } from "../../../hooks/useRedux";
import useMemoCompare from "../../../hooks/useMemoCompare";

const { Option } = Select;
const { RangePicker } = DatePicker;

function GeneratePage({}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const { clockings } = useSelector((state) => state.clockings);
  const { employees } = useSelector((state) => state.employeesList);
  const [activeFilter, setActiveFilter] = useState(null);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { wages } = useSelector((state) => state.wages);
  const [view, setView] = useState(null);
  const [customFilter, setCustomFilter] = useState(null);
  const { prepayments } = useSelector((state) => state.prepayments);
  const [manualColumns, setManualColumns] = useState(null);
  const { drawerFilter } = useReportsData();
  const [fetching, setFetching] = useState(false);
  const [reportClockings, setReportClockings] = useRedux(
    "reportClockings",
    [],
    false
  );
  const dispatch = useDispatch();

  const contentData = useMemo(() => {
    if (!!state && !!activeFilter && !loading) {
      switch (state) {
        case "approvals":
          return (
            reportClockings?.length > 0 &&
            processFactory(
              "approvals",
              [],
              activeFilter,
              reportsConstants["approvals"],
              manualColumns,
              employees
            )
          );
          break;
        case "supervisorApprovals":
          return processFactory(
            "supervisorApprovals",
            [],
            activeFilter,
            reportsConstants["supervisorApprovals"],
            manualColumns
          );
          break;
        case "clockingTypesReports":
          const clockingTypesReports = processFactory(
            "clockingTypesReports",
            [],
            activeFilter,
            reportsConstants["clockingTypesReports"],
            manualColumns,
            employees
          );
          console.log("clockingTypesReports", clockingTypesReports);
          return clockingTypesReports;
        case "requests":
          return processFactory(
            "requests",
            employeeRequests.map((el) => ({
              ...el,
              employeeDetails: employees.find((e) => e.userSub === el.userSub),
            })),
            activeFilter,
            reportsConstants["requests"],
            manualColumns
          );
          break;
        case "bankForwardPay":
          setView("month");
          return processFactory(
            "bankForwardPay",
            wages.map((el) => ({
              ...el,
              employeeDetails: employees.find(
                (e) => e.employeeId === el.employeeId
              ),
            })),
            activeFilter,
            reportsConstants["bankForwardPay"],
            manualColumns
          );
          break;
        case "prepayments":
          return processFactory(
            "prepayments",
            prepayments.map((el) => ({
              ...el,
              employeeDetails:
                employees.find((e) => e.employeeId === el.employeeId) || {},
            })),
            activeFilter,
            reportsConstants["prepayments"],
            manualColumns
          );
          break;
        case "detailedTaxes":
          return processFactory(
            "detailedTaxes",
            wages.map((el) => ({
              ...el,
              employeeDetails: employees.find(
                (e) => e.employeeId === el.employeeId
              ),
            })),
            activeFilter,
            reportsConstants["detailedTaxes"],
            manualColumns
          );
          break;
        case "employeesList":
          return processFactory(
            "employeesList",
            employees,
            activeFilter,
            reportsConstants["employeesList"],
            manualColumns
          );
          break;
        case "suspensions":
          return processFactory(
            "suspensions",
            employees,
            activeFilter,
            reportsConstants["suspensions"],
            manualColumns
          );
          break;
        default:
          return null;
          break;
      }
    }
  }, useMemoCompare([loading, activeFilter, customFilter, state, manualColumns]));

  const onGenerate = (filter, val) => {
    const arr = contentData || { rowData: [] };
    arr.rowData = arr?.rowData?.filter((el) => el.some((v) => v === val)) || [];
    setCustomFilter(arr);
  };

  const retrieveFilters = async (e) => {
    setFetching(true);
    switch (state) {
      case "approvals":
        message.loading({ content: "Loading...", key: "approvals" });
        await getReportsClockings(e?.timeInterval)
          .then((r) => {
            setReportClockings(
              r
                .map((el) => ({
                  ...el,
                  employeeDetails: employees.find(
                    (e) => e.employeeId === el.employeeId
                  ),
                }))
                .sort((a, b) => b.clockOutDate - a.clockOutDate)
            );
            setFetching(false);
            setActiveFilter(e);
            message.destroy();
          })
          .catch((e) => {
            setFetching(false);
            message.destroy();
          });

        break;
      case "clockingTypesReports":
        message.loading({ content: "Loading...", key: "approvals" });
        await getReportsClockings(e?.timeInterval)
          .then((r) => {
            setReportClockings(
              r
                .map((el) => ({
                  ...el,
                  employeeDetails: employees.find(
                    (e) => e.employeeId === el.employeeId
                  ),
                }))
                .sort((a, b) => b.clockOutDate - a.clockOutDate)
            );
            setFetching(false);
            setActiveFilter(e);
            message.destroy();
          })
          .catch((e) => {
            setFetching(false);
            message.destroy();
          });

        break;
      case "supervisorApprovals":
        await getReportsClockings(e?.timeInterval)
          .then((r) => {
            setReportClockings(
              r

                .map((el) => ({
                  ...el,
                  employeeDetails: employees.find(
                    (e) => e.employeeId === el.employeeId
                  ),
                }))
                .sort((a, b) => b.clockOutDate - a.clockOutDate)
            );
            setFetching(false);
            message.destroy();
            setActiveFilter(e);
          })
          .catch((e) => {
            setFetching(false);
            message.destroy();
          });
        break;
      default:
        setActiveFilter(e);
        setFetching(false);
        return null;
    }
    // message.destroy();=
  };

  const onCustomSort = (filter, val) => {};

  const retrieveColumns = (e) => {
    setManualColumns(e);
  };

  const onReport = async (e) => {
    await API.get("reportsLogs", "/reportsLogs").then((res) =>
      console.log(res)
    );
  };

  useLayoutEffect(() => {
    if (!!drawerFilter && !!activeFilter) {
      onGenerate(drawerFilter?.filter, drawerFilter?.value);
    }
  }, [drawerFilter, activeFilter]);

  useLayoutEffect(() => {
    if (
      !!clockings &&
      !!employees &&
      !!employeeRequests &&
      !!wages &&
      !!prepayments
    )
      setLoading(false);
  }, [clockings, employees, employeeRequests, wages, prepayments]);

  // useEffect(async () => {
  // 	if (!!activeFilter?.timeInterval) {
  // 		switch (state) {
  // 			case "approvals":
  // 				await getReportsClockings(activeFilter?.timeInterval)
  // 					.then((r) => {
  // 						setReportClockings(
  // 							r
  // 								.map((el) => ({
  // 									...el,
  // 									employeeDetails: employees.find((e) => e.employeeId === el.employeeId),
  // 								}))
  // 								.sort((a, b) => b.clockOutDate - a.clockOutDate)
  // 						);
  // 					})
  // 					.catch((e) => setLoading(false));

  // 				break;
  // 			default:
  // 				setLoading(false);

  // 				return null;
  // 		}
  // 	}
  // }, [state, activeFilter, employees]);
  console.log("activefilter", reportClockings);

  return (
    // <ReportProvider>
    // </ReportProvider>
    <LoadableComp loading={loading}>
      {/* <button onClick={onReport}>REPORT </button> */}
      <div className="globalPageWrapper">
        <div className="generate-page">
          <GeneratePageHeader
            {...{
              retrieveFilters,
              onGenerate,
              contentData,
              retrieveColumns,
              setLoading,
            }}
            secondLevelFilter={customFilter}
            resetCustom={() => setCustomFilter(null)}
            view={view}
            onCustomSort={onCustomSort}
            src={state}
          />
          {
            <ReportContent
              contentData={contentData}
              secondLevelFilter={customFilter}
              infos={reportsConstants}
              page={state}
              activeFilter={activeFilter}
            />
          }
        </div>
      </div>
    </LoadableComp>
  );
}

export default GeneratePage;
export const getReportsClockings = async (
  timeInterval,
  employeeId = false,
  dayType = false
) => {
  return new Promise(async (resolve, reject) => {
    let filterClockingObj = {
      limiter: moment(timeInterval[0]).startOf("day").valueOf(),
      endLimiter: moment(timeInterval[1])
        .add(14, "days")
        .endOf("day")
        .valueOf(),
    };
    if (employeeId) {
      filterClockingObj.employeeId = employeeId;
    }
    if (dayType) {
      filterClockingObj.dayType = dayType;
    }
    await API.get(`clocking`, `/clocking`, {
      queryStringParameters: {
        ...filterClockingObj,
        // limiter: moment(timeInterval[0]).startOf("day").valueOf(),
        // endLimiter: moment(timeInterval[1])
        //   .add(14, "days")
        //   .endOf("day")
        //   .valueOf(),
        // limiter: moment("01/1/2022", "DD/MM/YYYY").valueOf(),
        // start: params,
        // end: moment().valueOf(),
      },
    })
      .then((r) => resolve(r))
      .catch((error) => console.error(error));
  });
};
