import dayjs from "../../../../../../utils/dayjs";
export const weekFiltering = (momentInstance, filterType, instance = false) => {
  const getValuesOf = (array) => {
    if (!instance) {
      return array?.map((el) => dayjs(el).valueOf());
    } else {
      return array;
    }
  };

  if (momentInstance !== null) {
    let start = dayjs(momentInstance).startOf("month").valueOf();

    switch (filterType) {
      case "firstWeek":
        return getValuesOf([dayjs(start).weekday(0), dayjs(start).weekday(6)]);
      case "secondWeek":
        return getValuesOf([dayjs(start).weekday(7), dayjs(start).weekday(13)]);
      case "thirdWeek":
        return getValuesOf([
          dayjs(start).weekday(14),
          dayjs(start).weekday(20),
        ]);
      case "fourthWeek":
        return getValuesOf([
          dayjs(start).weekday(21),
          dayjs(start).weekday(27),
        ]);
      case "fifthWeek":
        return getValuesOf([
          dayjs(start).weekday(28),
          dayjs(start).endOf("month"),
        ]);
      case "month":
        return getValuesOf([
          dayjs(start).startOf("month"),
          dayjs(start).endOf("month"),
        ]);
      default:
        return getValuesOf([dayjs(start).weekday(0), dayjs(start).weekday(6)]);
    }
  }
};
