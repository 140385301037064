import React from "react";
import Cardkonfig from "../../dynamic components/Card";
import EmployeOfTheWeekContent from "./EmployeOfTheWeekContent";

function EmployeOfWeekCard() {
  return (
    // <div className="emp-month-card">
    // </div>
    (<Cardkonfig
      contentChild={<EmployeOfTheWeekContent />}
      width="100%"
      height={"calc(100% - 35px"}
      // title="Detajet e kompanisë/subjektit"
    />)
  );
}

export default EmployeOfWeekCard;
