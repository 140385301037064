import moment from "moment-timezone";
import { groupByKey } from "../../../../utils/loadash/methods";
import { paySubFactory } from "../../../Pagat/PaySub/employeeCalc/employeeSubFactory";

/**
 * The Start of a sequence of chained functions which handles the logic behind shift calculation, sorting, grouping
 * @param {Array} dataArr which contains the data to be processed
 * @param {Time} dayInstance is a moment instance which will filter the shifts accordingly
 * @returns iterative to another function
 */

export const filterByDay = (dataArr, dayInstance) => {
  // console.log(
  //   "Day Instance",
  //   moment(dayInstance.start).format("DD/MM/YYYY HH:mm"),
  //   "end",
  //   moment(dayInstance.end).format("DD/MM/YYYY HH:mm")
  // );
  if (dataArr.length > 0) {
    const filtered = dataArr
      .filter(
        (shift) =>
          shift.clockInDate > dayInstance.start &&
          shift.clockInDate < dayInstance.end &&
          shift.clockOutDate !== null
      )
      .sort((a, b) => a.clockInDate - b.clockInDate);
    // console.log("filtered", filtered);

    return groupAndCalculateHours(dayInstance, filtered);
  }
  return [];
};

export const groupAndCalculateHours = (dayInstance, filteredArr) => {
  let result = [];
  let hours = 0;
  let overtime = false;
  let markedOvertime = false;

  filteredArr.map((el) => {
    const duration = moment(el.clockOutDate).diff(
      moment(el.clockInDate),
      "hours",
      true
    );

    if (duration > 0) {
      result.push({
        ...el,
        clockInDate: el.clockInDate,
        clockOutDate: el.clockOutDate,
        type: overtime === true || hours > 8 ? "extra" : "usual",
      });
    }
  });
  // console.log("result", result, "hours", hours);
  return getMissingHours(result);
};

export const getMissingHours = (arr) => {
  let res = [];
  let data = arr || [];

  if (data.length > 0) {
    let sorted = data.sort((a, b) => a.clockInDate - b.clockInDate);
    let temp = calculateBreakHours(sorted);

    data = temp?.remains;
    res = [...temp?.res];
    sorted.map((el, idx) => {
      if (idx < sorted.length - 1) {
        res.push({
          ...el,
          clockInDate: moment(sorted[idx].clockOutDate).valueOf(),
          clockOutDate: moment(sorted[idx + 1].clockInDate).valueOf(),
          type: "missing",
        });
      }
    });
    res = data
      .concat(res)
      .sort((a, b) => a.clockInDate - b.clockInDate)
      .map((el) => ({
        ...el,
        duration: moment(el.clockOutDate)
          .diff(moment(el.clockInDate), "hours", true)
          .toFixed(2),
      }));
    let test = {};

    // console.log(
    //   "Test",
    //   res.map((el) => ({
    //     ...el,
    //     clockInDate: moment(el.clockInDate).format("HH:mm"),
    //     clockOutDate: moment(el.clockOutDate).format("HH:mm"),
    //   }))
    // );
    return res;
  } else return [];
};

export const calculatePercentages = (dataSrc) => {
  // console.log("Data Source", dataSrc);
  let totalHours = 0;
  let workedHours = 0;
  let calculated = [];
  if (dataSrc.length > 0) {
    totalHours = dataSrc
      .map((el) => parseFloat(el.duration))
      .reduce((a, b) => a + b)
      .toFixed(2);
    workedHours = dataSrc
      .filter((el) => el?.type !== "missing")
      .map((el) => parseFloat(el.duration))
      .reduce((a, b) => a + b)
      .toFixed(2);

    if (totalHours > 0) {
      calculated = dataSrc.map((el) => ({
        ...el,
        percentage: (el.duration * 100) / totalHours,
      }));
    }
  }
  // console.log(calculated);
  return [calculated, workedHours];
};

export const initializeShift = (data, momentInit, type) => {
  let timeFilter = {
    start: moment(momentInit).startOf(type).valueOf(),
    end: moment(momentInit).endOf(type).valueOf(),
  };
  if (!!data) {
    if (data.length > 0) {
      const shifts = data
        .filter(
          (el) =>
            el.clockInDate !== null &&
            el.clockOutDate !== null &&
            el.clockInDate > timeFilter.start &&
            el.clockInDate < timeFilter.end
        )
        .map((el) => ({
          ...el,
          dateGroup: moment(el.clockInDate).format("DD/MM/YYYY"),
          clockInDate:
            el?.clockingCategory !== "midnightReset"
              ? el?.clockInDate
              : moment(el.clockInDate).add("1", "days").valueOf(),
          clockOutDate:
            el?.clockingCategory !== "midnightReset"
              ? el?.clockOutDate
              : moment(el.clockOutDate).add("1", "days").valueOf(),
        }));

      if (shifts.length > 0) {
        const grouped = groupByKey(shifts, "dateGroup");
        // console.log("GROUPED", grouped);
        let toAssign = [];
        for (const [_, val] of Object.entries(grouped)) {
          let secondGrouped = groupByKey(val, "employeeId");
          for (const [_, v] of Object.entries(secondGrouped)) {
            // console.log("second grouped", secondGrouped);
            toAssign.push(filterByDay(v, timeFilter));
          }
        }
        // console.log("to assign", toAssign);
        toAssign.sort((a, b) => b[0]?.clockInDate - a[0]?.clockInDate);
        return toAssign;
      }
    }
  }
  return [];
};

export const filterCustomShifts = (data, timeArr, type) => {
  let timeFilter = {
    start: moment(timeArr[0]).startOf(type).valueOf(),
    end: moment(timeArr[1]).endOf(type).valueOf(),
  };
  if (data.length > 0) {
    let shifts = data
      .filter(
        (el) =>
          el.clockInDate !== null &&
          el.clockOutDate !== null &&
          el.clockInDate > timeFilter.start &&
          el.clockInDate < timeFilter.end
      )
      .map((el) => ({
        ...el,
        dateGroup: moment(el.clockInDate).format("DD/MM/YYYY"),
      }));

    if (shifts.length > 0) {
      const grouped = groupByKey(shifts, "dateGroup");
      let toAssign = [];
      for (const [_, val] of Object.entries(grouped)) {
        let secondGrouped = groupByKey(val, "employeeId");
        for (const [_, v] of Object.entries(secondGrouped)) {
          // console.log("second grouped", secondGrouped);
          toAssign.push(filterByDay(v, timeFilter));
        }
      }
      // console.log("To assign", toAssign);
      // console.log("grouped", grouped);
      toAssign.sort((a, b) => b[0].clockInDate - a[0].clockInDate);

      return toAssign;
      // setFetched(true);
    }
  }
  return [];
};

/// To be used in the future if needed to separate the shifts

const getDateGroup = (clockShift) => {
  let clockInDate = clockShift?.clockInDate;
  let clockOutDate = clockShift?.clockOutDate;
  let refTime__lower = moment(clockInDate);
  let refTime__upper = moment(clockInDate);

  refTime__lower.set({
    hour: "00",
    minute: "00",
    second: "00",
  });
  refTime__upper.set({
    hour: "08",
    minute: "00",
    second: "00",
  });

  if (clockInDate >= refTime__lower && clockInDate <= refTime__upper) {
    return moment(clockInDate).subtract(1, "days").format("DD/MM/YYYY");
  } else {
    return moment(clockInDate).format("DD/MM/YYYY");
  }
};

const calculateBreakHours = (sorted) => {
  let res = [];
  let remains = [];
  if (Array.isArray(sorted)) {
    sorted?.forEach((sortedEl) => {
      let breakStart = moment(sortedEl.clockOutDate);
      let breakInterval = sortedEl?.totalities?.breakTime || [];
      breakStart.set({
        hour: moment(breakInterval[0], "HH:mm").format("HH"),
        minute: moment(breakInterval[0], "HH:mm").format("mm"),
        second: "00",
      });
      let breakEnd = moment(sortedEl.clockOutDate);
      breakEnd.set({
        hour: moment(breakInterval[1], "HH:mm").format("HH"),
        minute: moment(breakInterval[1], "HH:mm").format("mm"),
        second: "00",
      });

      if (
        moment(sortedEl?.clockOutDate).valueOf() >=
          moment(breakEnd).valueOf() &&
        moment(sortedEl?.clockInDate).valueOf() <=
          moment(breakStart).valueOf() &&
        breakInterval.length > 1
      ) {
        res.push({
          ...sortedEl,
          clockInDate: moment(sortedEl?.clockInDate).valueOf(),
          clockOutDate: moment(breakStart).valueOf(),
        });

        res.push({
          ...sortedEl,
          clockInDate: moment(breakStart).valueOf(),
          clockOutDate: moment(breakEnd).valueOf(),
          type: "breakTime",
          isBreakTime: true,
        });
        res.push({
          ...sortedEl,
          clockInDate: moment(breakEnd).valueOf(),
          clockOutDate: moment(sortedEl?.clockOutDate).valueOf(),
        });
      } else {
        remains.push(sortedEl);
      }
    });
  }
  return { res, remains };
};
