import { Switch } from "antd";

const SwitchAgGrid = ({ checked, onSwitch, onChecked, onUnChecked }) => {
  console.log(onSwitch);
  return (
    <div className="headerSwitch" onClick={onSwitch}>
      <Switch checked={!checked} />
      <span className="span">{checked ? onChecked : onUnChecked}</span>
    </div>
  );
};

export default SwitchAgGrid;
