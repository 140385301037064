import React, { useState, useEffect } from "react";
import { Button, Row, Form, message } from "antd";
import { API } from "aws-amplify";
import "./info_rreth_kompanis.scss";
import moment from "moment-timezone";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import DragContainer from "../../../dashboard/AdminDashboard/Draggable/DragContainer";
import { useAuth } from "../../../../authentication/authHelpers";
import { v4 as uuidv4 } from "uuid";
import PaisjetOraritLogs from "../../SistemiSettings/SistemiPaisjetOrarit/PaisjetOraritLogs";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

function InformacioneRrethKompanis() {
  const [tableData, setTableData] = useState(null);
  const [fields, setFields] = useState(null);
  const [emplWeekMonth, setEmplWeekMonth] = useState(null);
  const [form] = Form.useForm();
  const { userAccess } = useAuth();
  const [isVisible, setIsVisible] = useState(false);

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  // save the data for company information
  const handleSave = async (val) => {
    let teDhenat = compareObjects(fields[0]["Detajet e Kompanise"], val);
    let informacione = compareObjects(fields[0]["Informacione Kontakti"], val);

    await API.put(
      "programFields",
      "/programFields/29e1f884-bda2-45b5-91a8-c8f2df13f3c1",
      {
        body: {
          fieldOptions: {
            "Informacione Kontakti": {
              emri: val.emri,
              mbiemri: val.mbiemri,
              numriTelefonit: val.numriTelefonit,
              fax: val.fax,
              email: val.email,
              logs: fields[0]["Informacione Kontakti"]?.logs
                ? informacione?.length !== 0
                  ? [...fields[0]["Informacione Kontakti"]?.logs, informacione]
                  : fields[0]["Informacione Kontakti"]?.logs
                : [informacione],
            },
            "Detajet e Kompanise": {
              nusi: val.nusi,
              shteti: val.shteti,
              qyteti: val.qyteti,
              dataRegjistrimit: val.dataRegjistrimit,
              emriSubjektit: val.emriSubjektit,
              kodiPostar: val.kodiPostar,
              adresa: val.adresa,
              subjektEmail: val.subjektEmail,
              logs: fields[0]["Detajet e Kompanise"]?.logs
                ? teDhenat.length !== 0
                  ? [...fields[0]["Detajet e Kompanise"]?.logs, teDhenat]
                  : fields[0]["Detajet e Kompanise"]?.logs
                : [teDhenat],
            },
          },
        },
      }
    ).then(() => {
      // handleSaveEmpMonth();
      message.success("Të dhënat u regjistruan me sukses !", 10);
      getProgramFields();
    });
  };

  // get data from api
  const getProgramFields = () => {
    API.get("programFields", "/programFields").then((r) => setTableData(r));
  };

  // find te needed objecet form the table programFields
  const tedhenatKompanis = () => {
    const kompaniaFieldOptions = tableData?.filter(
      (data) => data.fieldName === "Te dhenat e kompanise"
    );
    const fieldOptions = kompaniaFieldOptions?.map((data) => data.fieldOptions);
    setFields(fieldOptions);
  };

  // find te needed objecet form the table programFields
  const EmpMonthWeek = () => {
    const kompaniaFieldOptions = tableData?.find?.(
      (data) => data?.fieldName === "Employees of the Week & Month"
    );
    setEmplWeekMonth(kompaniaFieldOptions?.fieldOptions);
  };

  // fill up the form with the values that the form have saved in DB
  useEffect(() => {
    if (!!fields) {
      form.setFieldsValue({
        nusi: fields[0]["Detajet e Kompanise"]?.nusi,
        shteti: fields[0]["Detajet e Kompanise"]?.shteti,
        emriSubjektit: fields[0]["Detajet e Kompanise"]?.emriSubjektit,
        qyteti: fields[0]["Detajet e Kompanise"]?.qyteti,
        dataRegjistrimit: moment(
          fields[0]["Detajet e Kompanise"]?.dataRegjistrimit
        ),
        kodiPostar: fields[0]["Detajet e Kompanise"]?.kodiPostar,
        // emriSubjektit: fields[0]["Detajet e Kompanise"]?.emriSubjektit,
        adresa: fields[0]["Detajet e Kompanise"]?.adresa,
        subjektEmail: fields[0]["Detajet e Kompanise"]?.subjektEmail,
        emri: fields[0]["Informacione Kontakti"]?.emri,
        mbiemri: fields[0]["Informacione Kontakti"]?.mbiemri,
        numriTelefonit: fields[0]["Informacione Kontakti"]?.numriTelefonit,
        fax: fields[0]["Informacione Kontakti"]?.fax,
        email: fields[0]["Informacione Kontakti"]?.email,
      });
    }
  }, [form, fields]);

  //function to compare 2 object and return the differences
  function compareObjects(previousObject, currentObject) {
    const keys1 = Object.keys(previousObject);
    const keys2 = Object.keys(currentObject);
    let changesArray = [];

    const commonKeys = keys1.filter((key) => keys2.includes(key));
    if (commonKeys.length === 0) {
    }
    commonKeys.forEach((key) => {
      if (typeof currentObject[key] === "object") {
        if (!!currentObject[key]?._isAMomentObject) {
          if (
            moment(currentObject[key]).valueOf() !==
            moment(previousObject[key]).valueOf()
          ) {
            let changedData = {
              id: uuidv4(),
              activity: "Ndryshuar",
              author: username,
              changeDate: Date.now(),
              field: key,
              previousData: moment(previousObject[key]).format(
                "DD/MM/YYYY HH:mm"
              ),
              currentData: moment(currentObject[key]).format(
                "DD/MM/YYYY HH:mm"
              ),
            };
            changesArray.push(changedData);
          }
        }
      } else {
        if (previousObject[key] !== currentObject[key]) {
          let changedData = {
            id: uuidv4(),
            activity: "Ndryshuar",
            author: username,
            changeDate: Date.now(),
            field: key,
            previousData: previousObject[key],
            currentData: currentObject[key],
          };
          changesArray.push(changedData);
        }
      }
    });
    if (changesArray !== 0) {
      return changesArray;
    } else {
      return false;
    }
  }

  // set default value for the employe of week and employe of month
  useEffect(() => {
    if (!!emplWeekMonth) {
      form.setFieldsValue({
        nameMonth: emplWeekMonth?.month?.name,
        nameWeek: emplWeekMonth?.current?.name,
        mesazhPublik:
          emplWeekMonth?.current?.mesazhPublik ||
          "Jemi krenarë për ty! Urime për punën e bërë mirë!",
        mesazhPublikMonth:
          emplWeekMonth?.month?.mesazhPublikMonth ||
          "Jemi krenarë për ty! Urime për punën e bërë mirë!",
      });
    }
  }, [form, emplWeekMonth]);

  // show ProgramFields data
  useEffect(() => {
    getProgramFields();
  }, []);

  // execute the test function when the page is renderd
  useEffect(() => {
    if (!!tableData) {
      tedhenatKompanis();
    }
  }, [tableData]);

  useEffect(() => {
    if (!!tableData) {
      EmpMonthWeek();
    }
  }, [tableData]);

  return (
    <LoadableComp loading={!!tableData ? false : true}>
      <div className="info-rreth-kompanis" style={{ justifyContent: "center" }}>
        <Form name="konfigForm" onFinish={handleSave} form={form}>
          <Row
            className="responsive-info"
            style={{
              justifyContent: "center",
              height: "calc(100vh - 120px)",
              overflow: "auto",
            }}
          >
            <DragContainer view={"settingsInfoRrethKompanis"} />
          </Row>
          <div className="info-rreth-kompanis-footer">
            <MondayButton
              className="mondayButtonBlue"
              onClick={() => setIsVisible(true)}
            >
              Shfaq Historikun
            </MondayButton>
            <Form.Item>
              <Button className="mondayButtonGreen" htmlType="submit">
                Ruaj ndryshimet
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isVisible && (
        <PaisjetOraritLogs
          data={fields}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          logsName={"InfoKompanis"}
        />
      )}
    </LoadableComp>
  );
}

export default InformacioneRrethKompanis;
