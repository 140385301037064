import { useContext, useEffect, useMemo, useState } from "react";
import "./GeneralInfo.scss";
import { useSelector } from "react-redux";
import RenderDynamicComponents from "../../../../RenderDynamicComponents/RenderDynamicComponents";
import { Form } from "antd";
import { InterviewsContext } from "../../../data";
import dayjs from "../../../../../utils/dayjs";
import { FormItems } from "../Components/FormItems";
import CalendarInput from "../DateTime/CalendarInput";

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const GRID = ["input", "select", "datepicker", "phone"];
const TEXTAREA = ["customquill", "tagselect"];
const SIDEBAR = [
  "datepicker",
  //   "applicantInterviewDate",
  //   "applicantPayment",
];

const GeneralInfo = ({ form, step }) => {
  const { programFields } = useSelector((state) => state.programFields);
  const { departments } = useSelector((state) => state.departments);

  const [formItems, setFormItems] = useState([]);

  const selectedDepartment = Form.useWatch("applicantDepartment", form);

  //Ketu filtrojme te candidatet per te gjetur se cilet prej tyre e kane intervisten ne diten e perzgjedhur
  // const selectedDateCandidateArray = useMemo(() => {
  //   return candidates.filter(
  //     (items) =>
  //       moment(items?.applicantInterviewDate).format("YYYY-MM-DD") ===
  //         moment(kandidatiSelektuar?.applicantInterviewDate).format(
  //           "YYYY-MM-DD"
  //         ) && kandidatiSelektuar?.applicantId !== items.applicantId
  //   );
  // }, [candidates, kandidatiSelektuar]);
  // console.log(selectedDateCandidateArray);

  // const disabledTimesArray = selectedDateCandidateArray.map((obj) =>
  //   moment(obj["applicantInterviewDate"]).format("HH:mm ")
  // );

  //birthday disabled date
  const disabledDate = (current) => {
    return current && current.unix() > dayjs().unix() - 568025136;
  };

  const setInitialFormItems = () => {
    let departmentOptions = [];
    departments?.forEach(({ departmentName }) => {
      departmentOptions.push(departmentName);
    });
    let fields = programFields
      ?.find(({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID)
      ?.fieldOptions?.filter(
        (el) =>
          !el?.deletedField &&
          el?.formItemName !== "applicantDisqualificationReason"
      );
    fields?.forEach((item) => {
      if (item.formItemName === "applicantDepartment") {
        let options = [];
        departments?.forEach(({ departmentName }) => {
          options.push(departmentName);
        });
        item.options = options;
      }
      if (item.formItemName === "applicantBirthday") {
        item.disabledDate = (e) => disabledDate(e);
      }
      item.rules = (item.rules || []).map((rule) => {
        if (
          rule.hasOwnProperty("pattern") &&
          typeof rule?.pattern === "string"
        ) {
          const { pattern, ...rest } = rule;

          return {
            ...rest,
            async validator(_, value) {
              const rg = new RegExp(pattern);

              if (!rg.test(value)) {
                return Promise.reject(rest?.message);
              }

              return Promise.resolve();
            },
          };
        } else {
          return rule;
        }
      });
    });
    setFormItems(fields);
  };

  const setRoles = () => {
    let options = [];
    departments
      ?.find(({ departmentName }) => departmentName === selectedDepartment)
      ?.departmentRoles?.forEach(({ name }) => {
        options.push(name);
      });
    setFormItems((prev) =>
      prev?.map((el) => {
        if (el.formItemName === "applicantRoles") {
          if (!!options.length) {
            return {
              ...el,
              options,
              disabled: false,
              placeholder: "Selektoni Roli...",
              mode: "multiple",
            };
          } else {
            return {
              ...el,
              disabled: true,
              placeholder: "Zgjidh departamentin fillimisht",
            };
          }
        } else {
          return el;
        }
      })
    );
  };

  useEffect(() => {
    if (!!selectedDepartment) {
      setRoles();
    } else {
      setInitialFormItems();
    }
  }, [programFields, selectedDepartment, step]);

  // const formItemData = useMemo(() => {
  //   return FormItems({
  //     applicantReferenceStatus,
  //     applicantTrainingStatus,
  //     // handlePassword,
  //     // showPassword,
  //     form,
  //     disabledTimesArray,
  //     selectedDateCandidateArray,
  //     selectedDate: kandidatiSelektuar?.applicantInterviewDate,
  //   });
  // }, [
  //   applicantTrainingStatus,
  //   applicantReferenceStatus,
  //   // showPassword,
  //   disabledTimesArray,
  //   selectedDateCandidateArray,
  //   kandidatiSelektuar?.applicantInterviewDate,
  // ]);

  return (
    <>
      {step === 1 && (
        <div className="generalInfo">
          <div className="personalInfo">
            <div className="gridInfos">
              {RenderDynamicComponents(
                formItems?.filter(
                  (el) => GRID.includes(el.type) && el?.step == step
                ),
                { form }
              )}
            </div>
            <div className="textareaInfos">
              {RenderDynamicComponents(
                formItems?.filter(
                  (el) => TEXTAREA.includes(el.type) && el?.step == step
                ),
                { form }
              )}
            </div>
          </div>
          <div className="sidebarInfos">
            {RenderDynamicComponents(
              formItems?.filter(
                (el) => SIDEBAR.includes(el.type) && el?.step == step
              ),
              { form }
            )}
            <CalendarInput form={form} />
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="questions">
          {RenderDynamicComponents(
            formItems?.filter((el) => el?.step === step),
            { form }
          )}
        </div>
      )}
    </>
  );
};

export default GeneralInfo;
