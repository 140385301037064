import { API } from "aws-amplify";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { CloseIcon } from "../../../assets/icons";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { openInNewTab } from "../../Documentation/utils/openInNewTab";
import { UploadIcon } from "../../Documentation/View/assets";
import { fileIcons } from "../../Documentation/View/documentationViewData";
import { driveApi } from "../../DriveRequest";
import dayjs from "../../../utils/dayjs";
import { GPicker } from "../../GPicker";
import { _CREATE_HEADERS } from "../../DriveRequest/driveStatic";
import { message } from "antd";
import { useSelector } from "react-redux";

const DATE_FORMAT = "DD/MM/YYYY hh:mm a";
const URL_FILES = "https://www.googleapis.com/drive/v3/files";
/**
 *
 * @param {Array} uploadedFiles Array of the uploaded files.
 * @param {Function} onDelete On Delete button click callback function.
 * @param {Function} onPickerSuccess On file upload callback function.
 * @param {Function} accessToken API access token.
 * @param {Function} folderId Id of the target folder.
 */

const GoogleDriveUpload = ({
  uploadedFiles = [],
  onPickerSuccess = () => {},
  disabled = false,
  emplyeesObj,
  setCurrentFile,
  adminUpload,
  uploadImages,
}) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const [folderId, setFolderId] = useState([]);

  useEffect(() => {
    if (!!hotCredentials?.length) {
      const parentFolder = hotCredentials.find(
        (el) => el?.credentialId === "6"
      );
      setFolderId(parentFolder.credentialValue);
    }
  }, [hotCredentials]);

  const driveRequest = driveApi({ accessToken });

  const deleteDriveItem = () => {
    if (emplyeesObj?.googleDriveFileId?.length > 0) {
      return fetch(`${URL_FILES}/${emplyeesObj.googleDriveFileId}`, {
        method: "DELETE",
        headers: _CREATE_HEADERS(accessToken),
      });
    }
  };

  const addKeyForPhoto = (fileID) => {
    if (emplyeesObj?.employeeId !== "" || !!emplyeesObj?.employeeId) {
      API.put("employees", `/employees/${emplyeesObj?.employeeId}`, {
        body: {
          googleDriveFileId: fileID,
        },
      }).then(async (r) => {
        deleteDriveItem();
        adminUpload !== true &&
          (await driveRequest
            .getImageSrc(fileID)
            .then((image) =>
              window.localStorage.setItem("UserPhoto", image?.src)
            ));
      });
    } else {
      message.error("missing employeeId");
    }
  };

  return (
    <>
      <div
        className="docUploader-photo"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="uploadSection">
          <GPicker
            token={accessToken}
            onAuthenticate={() => {}}
            createPicker={(google, oauthToken) => {
              let uploadView = new google.picker.DocsUploadView()
                .setIncludeFolders(true)
                .setParent(folderId);
              if (!!uploadImages) {
                uploadView.setMimeTypes("image/png,image/jpeg,image/jpg");
              }
              const picker = new window.google.picker.PickerBuilder()
                .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
                .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                .enableFeature(google.picker.Feature.MINE_ONLY)
                .enableFeature(google.picker.Feature.NAV_HIDDEN)
                .addView(uploadView)
                .setOAuthToken(oauthToken)
                .setDeveloperKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo")
                .setCallback(async (data) => {
                  if (
                    data.action === google.picker.Action.PICKED &&
                    !!data.docs.length
                  ) {
                    onPickerSuccess({
                      uploadedFiles: data.docs,
                    });
                  }
                  if (
                    data.action === google.picker.Action.PICKED &&
                    !!data.docs.length
                  ) {
                    addKeyForPhoto(data?.docs?.[0]?.id);
                  }
                });
              picker.build().setVisible(true);
            }}
          >
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                Icon: <UploadIcon />,
                // disabled: disabled || !folderId || !accessToken,
              }}
            >
              Ngarko një foto
            </MondayButton>
          </GPicker>
        </div>
        <div className="uploadedFilesSection">
          {sortBy(uploadedFiles, "lastEditedUtc")
            .reverse()
            ?.map(
              ({
                url,
                name = "",
                id,
                uploadedBy = "",
                uploadedAt,
                type,
                mimeType,
              }) => {
                const fileExtension = name.split(".").pop();

                return (
                  <div className="fileCard" key={id}>
                    <div className="uploadedFile">
                      <span className="filesIcon">
                        {fileIcons({ type: fileExtension }) ||
                          fileIcons({ type: "image" })}
                      </span>
                      <span
                        className="fileName"
                        onClick={() => {
                          if (
                            type !== "photo" &&
                            mimeType !== "application/pdf"
                          )
                            openInNewTab(url);
                          else {
                            driveRequest.getImageSrc(id).then((file) => {
                              setCurrentFile({ ...file, url, mimeType });
                            });
                          }
                        }}
                      >
                        {name}
                      </span>
                      {!disabled && (
                        <span
                          className="closeIconContainer"
                          // onClick={() => onDelete(id)}
                        >
                          <CloseIcon className="closeIcon" />
                        </span>
                      )}
                    </div>
                    <div className="fileAuthor">
                      <span>{uploadedBy}</span>
                      <span>{dayjs(uploadedAt).format(DATE_FORMAT)}</span>
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </div>
    </>
  );
};

export default GoogleDriveUpload;
