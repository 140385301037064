export const onFilter = async ({
  gridApi,
  column,
  current,
  type = "equals",
}) => {
  const currentValues = Array.isArray(current)
    ? { values: [...current] }
    : { filter: current };

  type === "set"
    ? await gridApi.getColumnFilterInstance(column).then((comp) => {
        comp.getChildFilterInstance(1).setModel({
          type,
          ...currentValues,
        });
      })
    : await gridApi.getColumnFilterInstance(column).then((comp) => {
        comp.setModel({
          type,
          ...currentValues,
        });
      });
  gridApi.onFilterChanged();
};

export const onDateFilter = async ({ gridApi, column, dateArray }) => {
  await gridApi.getColumnFilterInstance(column).then((comp) => {
    comp.setModel({
      type: "inRange",
      filterType: "date",
      dateForm: dateArray[0]?.format("YYYY-MM-DD hh:mm:ss"),
      dateTo: dateArray[1]?.format("YYYY-MM-DD hh:mm:ss"),
    });
  });
  gridApi.onFilterChanged();
};

export const clearFilter = async ({ gridApi, column }) => {
  await gridApi.getColumnFilterInstance(column)?.then((comp) => {
    comp.setModel(null);
  });
  gridApi.onFilterChanged();
};

export const clearAllFilters = ({ filters, gridApi }) => {
  filters.map((column) => clearFilter({ gridApi, column }));
};
