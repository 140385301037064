import "./ShowContactDetails.scss";
import React, { useContext, useEffect, useState } from "react";
import {
  CloseIcon,
  InfoIcon,
  CalendarIcon,
  TimeIcon,
  UserIcon,
} from "../Image";
import { Typography, Button, Modal, Space, message } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import QuillRenderer from "../Components/QuillRenderer";
import QuestionMark from "../Image/QuestionMark";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ShowContactDetailsSecondModal from "../ShowContactDetailsSecondModal/ShowContactDetailsSecondModal";
import { ModalXIcon, NewUploadIcon } from "../../../../../assets/icons";
import AddContactDocUploader from "../AddContactDocUploader/AddContactDocUploader";
import { sortBy } from "lodash";
import { fileIcons } from "../../../../Documentation/View/documentationViewData";
import { openInNewTab } from "../../../../Documentation/utils/openInNewTab";
import { useAuth } from "../../../../../authentication/authHelpers";
import { RedCloseIcon } from "../../../../Documentation/View/assets";
import MultipleFilePrevieModal from "../../../../dashboard/AdminDashboard/AdminCards/Problems/MultipleFilePreviewModal/MultipleFilePreviewModal";
import { InterviewsContext } from "../../../data";
import { htmlParser } from "../../../../../utils";
import dayjs from "../../../../../utils/dayjs";
// import { cancelScheduledEvent } from "../../../../../utils/calendlyEvents";

const { Text, Link } = Typography;

const ShowContactDetails = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const [previewModalVisible, setPreviewModalVisible] = useState();
  const [currentFile, setCurrentFile] = useState({});
  const auth = useAuth();
  const CANDIDATE_CONFIGURATION_FIELD_ID =
    "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";
  const INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID =
    "345dgdf-456457fgjvbmn-96789khjghj";

  const {
    onDelete,
    isEditable,
    driveRequest,
    setIsChanged,
    uploadedFiles,
    temporaryArray,
    parentFolderId,
    onPickerSuccess,
    setTemporaryArray,
    tempUploadedFiles,
    kandidatiSelektuar,
    googleDriveFolderId,
    setTempUploadedFiles,
    setGoogleDriveFolderId,
    showSecondLayoutStatus,
    setShowSecondLayoutStatus,
  } = useContext(InterviewsContext);

  //region BUILD KEYLOGS
  const updatedFields = (originalObject, changedObject) => {
    const excludedFields = ["applicantEventURI", "googleDriveFolderId"];
    const { fieldOptions = [] } = programFields?.find(
      ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
    );
    let changes = [];
    let constants = {
      activity: "Ndryshuar",
      author:
        auth.userAccess[0]?.given_name + " " + auth.userAccess[0]?.family_name,
      changeDate: dayjs().valueOf(),
    };
    for (const key in changedObject) {
      if (changedObject[key] !== null && !excludedFields.includes(key)) {
        if (Array.isArray(changedObject[key])) {
          if (key === "googleDriveFiles") {
            for (let i = 0; i < changedObject[key].length; i++) {
              if (changedObject[key][i] !== originalObject[key][i]) {
                changes.push({
                  ...constants,
                  field: key,
                  oldValue: originalObject[key][i],
                  newValue: changedObject[key][i],
                });
              }
            }
          } else {
            if (
              JSON.stringify(changedObject[key]) !==
              JSON.stringify(originalObject[key])
            ) {
              changes.push({
                ...constants,
                field: key,
                newValue: changedObject[key]?.join(", ") || changedObject[key],
                oldValue:
                  originalObject[key]?.join(", ") || originalObject[key],
              });
            }
          }
        } else if (
          new Date(changedObject[key]).toDateString() !==
          new Date(originalObject[key]).toDateString()
        ) {
          if (key === "applicantInterviewDate") {
            const interviewDateValues = applicantInterviewDateKeylog({
              originalObject,
              changedObject,
              key,
            });
            for (let i = 0; i < interviewDateValues.length; i++) {
              const { key, value, format } = interviewDateValues[i];
              changes.push({
                ...constants,
                field: key,
                oldValue: dayjs(originalObject[key]).format(format),
                newValue: dayjs(value).format(format),
              });
            }
          } else {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
          }
        } else {
          if (changedObject[key] !== originalObject[key]) {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
          }
        }
      } else if (
        originalObject[key] !== null &&
        !excludedFields.includes(key)
      ) {
        changes.push({
          ...constants,
          field: key,
          oldValue: originalObject[key],
          newValue: null,
        });
      }
    }

    return translateObjectFields({ fieldOptions, changes });
  };

  //region DRIVE FOLDER
  const getDriveFolder = async () => {
    let folderIdOnCreate = "";
    const folderName = `${kandidatiSelektuar?.applicantFirstName} ${
      kandidatiSelektuar?.applicantLastName
    }-${dayjs().valueOf()}`;

    const { fieldOptions } = programFields?.find(
      ({ fieldId }) => fieldId === INTERVIEW_DOCS_PERMISSION_LIST_FIELD_ID
    );

    folderIdOnCreate = await createDriveFolder({
      driveRequest,
      parentId: parentFolderId,
      folderName,
      defaultPermissions: fieldOptions || [],
    });
    setGoogleDriveFolderId(folderIdOnCreate);
  };

  useEffect(() => {
    // on manual create mode we generate a folder Id
    if (!googleDriveFolderId) {
      getDriveFolder();
    }
  }, []);

  useEffect(() => {
    const fields = !!isEditable
      ? updatedFields(kandidatiSelektuar, temporaryArray)
      : [];
    setTemporaryArray((prev) => ({
      ...prev,
      googleDriveFolderId,
      keylog: [...(kandidatiSelektuar?.keylog || []), ...(fields || [])],
    }));
  }, [kandidatiSelektuar, googleDriveFolderId]);

  //constants
  let applicantFullName =
    temporaryArray?.applicantFirstName +
    " " +
    temporaryArray?.applicantLastName;

  let textFormater =
    applicantFullName.length > 20
      ? applicantFullName.slice(0, 20) + "..."
      : applicantFullName;

  //region RETURN
  return (
    <>
      <div className="previewCandidate">
        <div className="container-header">
          <img src={InfoIcon} alt="Info Icon" />
          <span className="header-text">
            Kjo është pamja paraprake për kandidatin <span>{textFormater}</span>
            , lexoni edhe një herë të gjitha të dhënat para së të konfirmoni.
          </span>
        </div>
        <div className="container-content">
          <div className="left-side-content">
            <div className="first-content-container">
              <div className="left-side-container">
                <div className="first-row-first-container">
                  <p>{textFormater}</p>
                </div>
                <p>
                  <span>Pozicioni:</span> {temporaryArray?.applicantDepartment}
                </p>
                <p>
                  <span>Rolet:</span>{" "}
                  {Array.isArray(temporaryArray?.applicantRoles)
                    ? temporaryArray?.applicantRoles?.join(", ")
                    : temporaryArray?.applicantRoles}
                </p>
                <p>
                  <span>Datëlindja:</span>
                  {temporaryArray?.applicantBirthday &&
                    dayjs(temporaryArray?.applicantBirthday)?.format(
                      "D/M/YYYY"
                    )}{" "}
                </p>
                <p>
                  <span>Nr. Personal</span>{" "}
                  {temporaryArray?.applicantPersonalNumber}
                </p>
                <p>
                  <span>Nr. Celulari</span>{" "}
                  {temporaryArray?.applicantPhoneNumber}
                </p>
              </div>
              <div className="right-side-container">
                <div
                  className="custom-status-style"
                  style={{
                    backgroundColor:
                      temporaryArray?.applicantStatus === "Skualifikuar"
                        ? "#EA3943"
                        : temporaryArray?.applicantStatus ===
                          "Listuar për intervistë"
                        ? "#FCC94A"
                        : temporaryArray?.applicantStatus === "Në proçes"
                        ? "#FCC94A"
                        : "#20AC9D",
                  }}
                >
                  {temporaryArray?.applicantStatus}
                </div>
                <div className="input-row-style">
                  <img src={CalendarIcon} alt="Calendar Icon" />
                  <p>
                    <span>Data e intervistës: </span>
                    {dayjs(temporaryArray?.applicantInterviewDate)?.format(
                      "D/M/YYYY"
                    )}
                  </p>
                </div>
                <div className="input-row-style">
                  <img src={TimeIcon} alt="Time Icon" />
                  <p>
                    <span>Ora e intervistës: </span>
                    {dayjs(temporaryArray?.applicantInterviewDate)?.format(
                      "HH:mm",
                      {
                        trim: false,
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="second-content-container">
              <p>
                <span>Adresa e banimit: </span>
                {temporaryArray?.applicantAddress}
              </p>
              <p>
                <span>Data e mundshme e fillimit: </span>
                {!!temporaryArray?.applicantStartDate
                  ? dayjs(temporaryArray?.applicantStartDate).format(
                      "DD/MM/YYYY"
                    )
                  : "S'ka datë"}
              </p>
              <div className="input-password-type">
                <p>
                  <span>Norma e pagës: </span>
                  {temporaryArray?.applicantPayment}
                </p>
              </div>
              <Link
                onClick={() => {
                  setShowSecondLayoutStatus(!showSecondLayoutStatus);
                }}
              >
                Kliko këtu për të shfaqur avantazhet dhe disavantazhet e
                kandidatit
              </Link>
              <div className="diviner-line"></div>
              <p>
                <span>Niveli i diplomës: </span>
                {temporaryArray?.applicantDiplomaLevel}
              </p>
              <p>
                <span>Universiteti: </span>
                {temporaryArray?.applicantUniversity}
              </p>
              <p>
                <span>Dega: </span>
                {temporaryArray?.applicantUniversityField}
              </p>
              <p>
                <span>Platforma: </span>
                {temporaryArray?.applicantPlatform}
              </p>
              <div className="quill-content-container">
                <div className="quill-container">
                  <span>Trajnime dhe kualifikime të tjera: </span>

                  {htmlParser(temporaryArray?.applicantTraining)}

                  {/* <QuillRenderer value={temporaryArray?.applicantTraining} /> */}
                </div>
                <div className="quill-container">
                  <span>Referenca të mëparshme: </span>
                  {htmlParser(temporaryArray?.applicantReference)}

                  {/* <QuillRenderer value={temporaryArray.applicantReference} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="right-side-content">
            <div className="upload-kandidati-button">
              <NewUploadIcon width={90} height={90} />
              <div className="upload-kandidati-button-text-container">
                <span className="upload-kandidati-button-text">
                  Hidhni dokumentat këtu ose klikoni butonin e mëposhtëm
                </span>

                <AddContactDocUploader
                  {...{
                    onPickerSuccess(param) {
                      onPickerSuccess(param);
                      setTempUploadedFiles([
                        ...tempUploadedFiles,
                        ...param.uploadedFiles.map(({ id }) => id),
                      ]);
                      setIsChanged(true);
                    },
                    uploadedFiles,
                    parentFolderId: googleDriveFolderId,
                  }}
                />
              </div>
            </div>

            {!!uploadedFiles?.length ? (
              <div className="uploadedFilesSection">
                {sortBy(uploadedFiles, "lastEditedUtc")
                  .reverse()
                  ?.map(({ url, name = "", id, type, mimeType }) => {
                    const fileExtension = name.split(".").pop();

                    return (
                      <div className="fileCard" key={id}>
                        <div className="uploadFileName">
                          <span className="uploadedFileIcon">
                            {fileIcons({ type: fileExtension }) ||
                              fileIcons({ type: "image" })}
                          </span>
                          <span
                            className="fileName"
                            onClick={() => {
                              if (
                                type !== "photo" &&
                                mimeType !== "application/pdf" &&
                                mimeType !== "image/png"
                              )
                                openInNewTab(url);
                              else {
                                setPreviewModalVisible(true);
                                driveRequest.getImageSrc(id).then((file) => {
                                  setCurrentFile({
                                    ...file,
                                    url,
                                    mimeType,
                                    id,
                                  });
                                });
                              }
                            }}
                          >
                            {name}
                          </span>
                        </div>

                        <RedCloseIcon
                          height={18}
                          width={14}
                          className="closeIcon"
                          onClick={() => {
                            onDelete(id);
                            setIsChanged(true);
                          }}
                        />
                      </div>
                    );
                  })}
                <MultipleFilePrevieModal
                  {...{
                    visible: previewModalVisible,
                    setVisible: () => {
                      setCurrentFile({});
                      setPreviewModalVisible(false);
                    },
                    setCurrentFile,
                    file: currentFile,
                    uploadedFiles,
                    driveRequest,
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* </Modal> */}
      <ShowContactDetailsSecondModal
        showSecondLayoutStatus={showSecondLayoutStatus}
        setShowSecondLayoutStatus={setShowSecondLayoutStatus}
        temporaryArray={temporaryArray}
      />
    </>
  );
};

export default ShowContactDetails;
// export const fields = {
//   applicantAddress: "Adresa e banimit",
//   applicantAdvantages: "Avantazhet",
//   applicantBirthday: "Datëlindja",
//   applicantDiplomaLevel: "Niveli i diplomës",
//   applicantDisadvantages: "Disavantazhet",
//   applicantEmail: "Email",
//   applicantFirstName: "Emër",
//   applicantInterviewDate: "Data e intervistës",
//   applicantLastName: "Mbiemër",
//   applicantPayment: "Norma e pagës",
//   applicantPersonalNumber: "Nr.Personal",
//   applicantPhoneNumber: "Nr.Celulari",
//   applicantPlatform: "Platforma",
//   applicantReference: "Referenca",
//   applicantStartDate: "Data e mundshme e fillimit",
//   applicantTeam: "Skuadra",
//   applicantStatus: "Statusi",
//   applicantTraining: "Trajnime dhe kualifikime të tjera",
//   applicantUniversity: "Universiteti",
//   googleDriveFiles: "Dokumentet",
//   applicantRoles: "Rolet",
//   applicantDepartment: "Departamenti",
// };
export const translateObjectFields = ({ fieldOptions, changes }) => {
  const fieldLabels = new Map(
    fieldOptions.map(({ formItemName, label }) => [formItemName, label])
  );
  const rest = changes
    .filter((el) => !["keylog"].includes(el?.field))
    .map((log) => {
      const translatedField = fieldLabels.get(log?.field) || log?.field;
      return {
        ...log,
        field: translatedField,
      };
    });
  return rest;
};
export const applicantInterviewDateKeylog = ({
  originalObject,
  changedObject,
  key,
}) => {
  const dataFormats = ["DD/MM/YYYY", "HH:mm"];
  let values = [];
  for (let i = 0; i < dataFormats.length; i++) {
    const format = dataFormats[i];
    if (
      dayjs(originalObject?.[key]).format(format) !==
      dayjs(changedObject?.[key]).format(format)
    ) {
      const formattedValue = dayjs(changedObject[key]).format(format);
      values.push({ key, value: formattedValue, format });
    }
  }
  return values;
};
export async function createDriveFolder({
  driveRequest,
  parentId,
  folderName,
  resetValues,
  defaultPermissions = [],
}) {
  let result = "";
  await driveRequest
    .getFolderIdOrCreate({
      name: folderName,
      parent: parentId,
      defaultPermissions,
    })
    .then((res) => (result = res))
    .catch((err) => {
      message.error({
        key: 0,
        content: "Nuk u lidh dot me Google Drive",
        duration: 2,
      });
      console.log("Error: ", err);
      setTimeout(() => {
        if (resetValues && typeof resetValues === "function") {
          resetValues();
        }
      }, 600);
    });
  return result;
}
