import { useMemo } from "react";
import "./FooterCounter.scss";

const FooterCounter = ({ title, data, style = "columnFlex", derivedData }) => {
  const count = useMemo(() => {
    return data
      ?.map((el) => parseFloat(el))
      ?.reduce((acc, curr) => acc + curr, 0);
  }, [data]);
  const derivedCount = useMemo(() => {
    if (!derivedData) return null;
    return derivedData
      ?.map((el) => parseFloat(el))
      ?.reduce((acc, curr) => acc + curr, 0);
  }, [derivedData]);
  return (
    <div className="footerCounterWrapper">
      <div className={`footerCounter ${style}`}>
        <span>{title}</span>
        <span>{count.toFixed(2)}</span>
        {!!derivedCount ? <span>{`(${derivedCount.toFixed(2)})`}</span> : null}
      </div>
    </div>
  );
};

export default FooterCounter;
