import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import {
  DayOffIcon,
  Envelope,
  HolidayIcon,
  MaternityLeaveIcon,
} from "../../assets";
import "./UserRequest.scss";
import { useAuth } from "../../../../authentication/authHelpers";
// import AddRequestModal from "./AddRequestModal";
import EmployeLeaveRequesDetail from "./EmployeLeaveRequesDetail";
import {
  requestAllPages,
  requestData,
} from "../../../../helpers/API/RequestFactory";
import { HollowDotsSpinner } from "react-epic-spinners";
const UserRequest = () => {
  let auth = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisibleModal] = useState(false);
  const [allRequests, setAllRequests] = useState([]);
  const [userRequests, setUserRequests] = useState([]);
  const [noEmpId, setNoEmpId] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  //Percakton ikonen ne varesi te llojit te kerkeses
  const getIcon = (reqType) => {
    switch (reqType) {
      case "Leje lindje":
        return (
          <MaternityLeaveIcon
            width={18}
            height={18}
            style={{ marginTop: 2, marginLeft: 2 }}
          />
        );
      case "Pushime":
        return (
          <HolidayIcon
            width={18}
            height={18}
            style={{ marginTop: 2, marginLeft: 2 }}
          />
        );
      case "Dite Personale":
        return (
          <DayOffIcon
            width={18}
            height={18}
            style={{ marginTop: 2, marginLeft: 2 }}
          />
        );
      default:
        return;
    }
  };
  //Percakton ngjyren ne varssi te statusit te kerkeses
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#1DA193";
      case "Refuzuar":
        return "#EA3943";
      case "Pending":
        return "#FCC94A";
      default:
        return "#FCC94A";
    }
  };
  const excludeWeekends = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };
  const headerLabels = [
    "Data e krijimit",
    "Statusi",
    "Data e fillimit",
    "Data e mbarimit",
    "Dite pa fundjave",
  ];

  useEffect(() => {
    const fetchData = async () => {
      // const requests = await API.get("employeeRequests", "/employeeRequests");
      const requests = await requestData("employeeRequests");

      setAllRequests(requests);
      // setData(fieldsData.fieldOptions);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (allRequests?.length > 0) {
      //Merr lejet qe i perkasin userit te loguar
      let usersInfo = allRequests?.filter(
        (r) => r.userSub === auth.user.attributes.sub
      );
      setUserRequests(usersInfo);
    }
  }, [allRequests]);
  useEffect(() => {
    if (auth.employeeId === null) {
      setNoEmpId(true);
    }
  }, [auth]);

  return (
    (<div
      className="user-request"
      style={{
        height: "100%",
        width: "100%",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {/* {noEmpId === true ? ( */}
      <>
        {userRequests?.length <= 0 ? (
          <div className="noData">
            <Envelope />
            <p className="request-info">Nuk ka asnjë kërkesë për të shfaqur.</p>
          </div>
        ) : (
          // <div
          //   style={{
          //     position: "absolute",
          //     top: "27%",
          //     right: "20%",
          //   }}
          // >
          //   <HollowDotsSpinner color={"#1DA193"} size={24} />
          // </div>
          (<>
            <div className="headersLabel">
              {headerLabels?.map((el) => {
                return <div className="headerTips">{el}</div>;
              })}
            </div>
            <>
              <div className="userRequestsData">
                {userRequests?.map((el) => {
                  return (
                    <div className="requests-item">
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {moment(el?.createdAt).format("DD/MM/YYYY")}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "150px",
                            backgroundColor: getColor(el?.requestStatus),
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            borderRadius: 5,
                            position: "relative",
                          }}
                        >
                          {el?.requestStatus}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {moment(el?.requestPeriod[0]).format("DD/MM/YYYY")}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {moment(el?.requestPeriod?.slice(-1)[0]).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >{`${excludeWeekends(
                        new Date(
                          moment(el?.requestPeriod[0]).format("YYYY/MM/DD")
                        ),
                        new Date(
                          moment(el?.requestPeriod?.slice(-1)[0]).format(
                            "YYYY/MM/DD"
                          )
                        )
                      )}`}</div>
                    </div>
                  );
                })}
              </div>
            </>
            {/* <div className="values">
              {values?.map(
                ({
                  createdAt,
                  status,
                  requestStartPeriod,
                  requestEndPeriod,
                  days,
                }) => {
                  return (
                    <>
                      <div className="createdAt">
                        <span>{createdAt}</span>
                        <div
                          style={{
                            backgroundColor: getColor(
                              userRequests.requestStatus
                            ),
                            width: 80,
                            display: "flex",
                            justifyContent: "center",
                            color: "#fff",
                            borderRadius: 5,
                          }}
                        >
                          <span>{status}</span>
                        </div>
                        <div
                          style={{
                            width: 80,
                            backgroundColor: "#a4a0a0",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 5,
                            position: "relative",
                            right: 20,
                          }}
                          className="requestStartPeriod"
                        >
                          <span>{requestStartPeriod}</span>
                        </div>
                        <div
                          style={{
                            width: 80,
                            backgroundColor: "#a4a0a0",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 5,
                          }}
                        >
                          <span>{requestEndPeriod}</span>
                        </div>

                        <div className="days">
                          <span>{days}</span>
                        </div>
                      </div>
                    </>
                  );
                }
              )}
            </div> */}
            {/* <div className="addLeaveBtn">
              <Button className="add-request" onClick={showModal} disabled>
                Dërgo një kërkesë për leje
              </Button>
            </div> */}
          </>)
          // <div className="requests-container">
          //   {/* dfgsd */}
          //   {userRequests?.map((req) => {
          //     return (
          //       <>
          //         <div
          //           className="request-line"
          //           onClick={() => setVisibleModal(true)}
          //           key={req.requestId}
          //         >
          //           <div
          //             className="icon-container"
          //             style={{ backgroundColor: getColor(req.requestStatus) }}
          //           >
          //             {getIcon(req.requestType)}
          //           </div>
          //           <h4 className="request-date" style={{ marginLeft: 2 }}>
          //             Kerkese eshte derguar me:
          //             {moment(req.createdAt).format("MM/DD/YYYY")}
          //           </h4>
          //           <div
          //             className="request-type"
          //             style={{ backgroundColor: getColor(req.requestStatus) }}
          //           >
          //             {req.requestStatus}
          //           </div>
          //         </div>
          //         <EmployeLeaveRequesDetail
          //           userRequests={req}
          //           setVisibleModal={setVisibleModal}
          //           open={visible}
          //         />
          //       </>
          //     );
          //   })}
          // </div>
        )}{" "}
      </>
      {/* ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 60,
          }}
        >
          <Alert
            type="error"
            description="Ju nuk mund te shtoni pushime!"
            showIcon
          />
        </div>
      )} */}
      {/* <AddRequestModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        setUserRequests={setUserRequests}
        userRequests={userRequests}
        setAllRequests={setAllRequests}
      /> */}
    </div>)
  );
};
export default UserRequest;
