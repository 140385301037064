import React, { useContext, useEffect, useState } from "react";
import "./ListaEkandidateve.scss";
import FilterComponent from "../MainTabs/FilterComponent";
import { Badge, Button } from "antd";
import Kandidati from "./Kandidati";
import { useMediaQuery } from "react-responsive";
import {
  CheckIcon,
  ClockIcon,
  InfoBluecIcon,
  IntervistatBigIconGreen,
} from "../../../assets/icons";
import dayjs from "../../../utils/dayjs";
import { InterviewsContext } from "../data";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { API } from "aws-amplify";
import { candidates as candidatesReducer } from "../../../store/actions";
const ListaEkandidateve = () => {
  const { candidates } = useSelector((state) => state.candidates);
  // const [candidates, setCandidates] = useState(null);
  const location = useLocation();
  const {
    intervistat,
    kandidatiSelektuar,
    setKandidatiSelektuar,
    setCreateUserPage,
    setGoogleDriveFolderId,
    activeFilters,
  } = useContext(InterviewsContext);

  const colors = {
    "Listuar për intervistë": "#FCC94A",
    "Në proçes": "#FCC94A",
    Pranuar: "#20AC9D",
    Skualifikuar: "#EA3943",
    Draft: "#787677",
  };

  //UseEffect qe shikon location State
  useEffect(() => {
    if (!!location?.state?.kandidatiSelektuarId) {
      setKandidatiSelektuar(
        candidates.find(
          (item) => item.applicantId === location?.state?.kandidatiSelektuarId
        )
      );
    }
  }, [location?.state]);

  const selectedCandidate = !!Object.keys(kandidatiSelektuar).length > 0;

  const isNotDesktop = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });
  const responsiveClasses = !!isNotDesktop && !!selectedCandidate;

  return (
    <>
      {candidates?.length > 0 ? (
        <div
          className="listaEkandidateve"
          style={
            isNotDesktop
              ? !selectedCandidate
                ? { gridTemplateColumns: "1fr 1fr" }
                : { gridTemplateColumns: "minmax(0, 1fr) minmax(0, 3fr)" }
              : {}
          }
        >
          <div
            className="teGjithKandidatet"
            style={
              !!isMobile
                ? !selectedCandidate
                  ? { height: "max-content" }
                  : { maxHeight: "250px" }
                : {}
            }
          >
            <FilterComponent />
            <div className="kandidatet">
              {intervistat?.map((intervista) => {
                const {
                  applicantFirstName = "",
                  applicantLastName = "",
                  applicantStatus = "",
                  applicantDepartment = "",
                  createdAt = "",
                } = intervista;
                let applicantFullName =
                  applicantFirstName + " " + applicantLastName;
                let textFormat =
                  applicantFullName.length > 30
                    ? applicantFullName.slice(0, 30) + "..."
                    : applicantFullName;
                return (
                  <div
                    key={intervista?.applicantId}
                    className="applicant"
                    onClick={() => {
                      setGoogleDriveFolderId(intervista?.googleDriveFolderId);
                      setKandidatiSelektuar(intervista);
                    }}
                    style={{
                      backgroundColor:
                        kandidatiSelektuar?.applicantId ===
                        intervista?.applicantId
                          ? "#f1f5fd"
                          : "",
                    }}
                  >
                    <div className="applicantNameStatus">
                      <span
                        className="applName"
                        style={{
                          fontSize:
                            kandidatiSelektuar?.applicantId ===
                            intervista?.applicantId
                              ? 18
                              : "",
                        }}
                      >
                        {textFormat}
                      </span>
                      <Badge
                        text={responsiveClasses ? "" : applicantStatus}
                        color={colors?.[applicantStatus]}
                      />
                    </div>
                    {responsiveClasses ? null : (
                      <>
                        <span>{applicantDepartment}</span>
                        <div className="interviewDate">
                          <span className="clockIcon">{<ClockIcon />}</span>
                          <span>
                            Ka aplikuar në datën{" "}
                            {createdAt && dayjs(createdAt).format("DD/MM/YYYY")}
                          </span>
                        </div>
                        {!intervista?.applicantReApplied ? (
                          <div className="interviewDate">
                            <InfoBluecIcon
                              height={12}
                              width={12}
                              fill="#323338"
                            />
                            <span>Aplikimi i parë</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {Object.keys(kandidatiSelektuar).length === 0 ? null : (
            <div className="kandidatiContent">
              <Kandidati />
            </div>
          )}
        </div>
      ) : (
        <div className="noKandidads">
          <div className="noData">
            <IntervistatBigIconGreen />
            <Button
              className="addKandidat"
              onClick={() => {
                setCreateUserPage(true);
              }}
            >
              Shtoni një kandidat
            </Button>
            <span className="txtKandidat">
              Aktualisht nuk ka asnjë kandidat, nëse dëshironi të shtoni një,
              klikoni butonin e mësipërm.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ListaEkandidateve;
