import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Modal, Select, Skeleton, List, Avatar } from "antd";
import { Chart } from "react-google-charts";
import moment from "moment";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { onResend } from "../utils/methods";
import "./AnnouncementReplies.scss";

const AnnouncementReplies = ({
  userResponses = {},
  announcementId: id,
  title,
}) => {
  const responses = Object.keys(userResponses)?.length || 0;
  const [visible, setVisible] = useState(false);
  const [chartData, setChartData] = useState([
    [
      "Lloji",
      "Numri i pergjigjeve",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    ["Konfirmoi", 0, "#b87333", null],
    ["Anashkaloi", 0, "silver", null],
    ["Refuzoi", 0, "gold", null],
  ]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [activeFiltering, setActiveFiltering] = useState({
    type: false,
    user: false,
    department: false,
  });
  const { employees } = useSelector((state) => state.employeesList);
  const employeesResponses = useMemo(() => {
    let res = [];
    let departments = [];
    if (visible) {
      // activeEmployees?.forEach((e) => {
      // 	res.push({
      // 		employeeName: e?.employeeFirstName + " " + e?.employeeLastName,
      // 		response: "confirm",
      // 		updatedAt: moment().valueOf(),
      // 		employeeId: e.employeeId,
      // 		announcementId: "",
      // 	});
      // });
      for (const [key, value] of Object.entries(userResponses)) {
        const employee = employees?.find((e) => e?.employeeId === key);
        if (!departments?.includes(employee?.employeeDepartmentName)) {
          departments.push(employee?.employeeDepartmentName);
        }
        res.push({
          employeeName:
            employee?.employeeFirstName + " " + employee?.employeeLastName,
          response: value?.response || "No response",
          updatedAt: value?.updatedAt || "",
          employeeId: key,
          announcementId: id,
          employeeDepartmentName: employee?.employeeDepartmentName,
        });
      }
      const tempChart = [
        [
          "Lloji",
          "Numri i pergjigjeve",
          { role: "style" },
          {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
          },
        ],
        ["Konfirmoi", 0, "#539165", null],
        ["Anashkaloi", 0, "#B9E9FC", null],
        ["Refuzoi", 0, "#E74646", null],
      ];

      res?.forEach((r) => {
        if (r?.response === "confirm") {
          tempChart[1][1] += 1;
        } else if (r?.response === "skip") {
          tempChart[2][1] += 1;
        } else if (r?.response === "refuse") {
          tempChart[3][1] += 1;
        }
      });
      //   console.log("tempChart", tempChart);
      setChartData(tempChart);
    }
    setAllDepartments(departments);
    return activeFiltering?.user ||
      activeFiltering?.type ||
      activeFiltering?.department
      ? res?.filter((re) => {
          let condition = true;
          if (activeFiltering?.user) {
            if (condition) condition = re?.employeeId === activeFiltering?.user;
          }
          if (activeFiltering?.type) {
            if (condition) condition = re?.response === activeFiltering?.type;
          }
          if (activeFiltering?.department) {
            if (condition)
              condition =
                re?.employeeDepartmentName === activeFiltering?.department;
          }
          return condition;
        })
      : res;
  }, [visible, userResponses, employees, activeFiltering]);

  const onActiveFiltering = (key, val) => {
    if (key === "clear") {
      setActiveFiltering({
        ...activeFiltering,
        type: false,
        user: false,
        department: false,
      });
    } else {
      setActiveFiltering({ ...activeFiltering, [key]: val });
    }
  };

  const getbgcolor = (item) => {
    // console.log("item", item);
    if (item?.response === "confirm") return "#539165";
    else if (item?.response === "skip") return "#B9E9FC";
    else return "#1D3445";
  };

  return (
    <>
      <div
        disabled={responses < 1}
        style={{
          backgroundColor: responses > 0 ? "#1DA193" : "#E74646",
          cursor: responses > 0 ? "pointer" : "not-allowed",
          marginTop: 3,
          // padding: 5,
          borderRadius: 5,
          color: "#ffff",
          width: 32,
          height: 32,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          setVisible(true);
        }}
      >
        {responses || 0}
      </div>
      {visible ? (
        <Modal
          open={visible}
          // height={"100%"}
          width={"1200px"}
          title={title}
          destroyOnClose={true}
          centered={true}
          onCancel={() => setVisible(false)}
          footer={null}
          className="userResponsesModal lightHeader"
        >
          <div className="charty">
            {chartData && (
              <Chart
                chartType="BarChart"
                // width="100%"
                height="350px"
                data={chartData}
                options={options2}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: 20,
            }}
          >
            <MondayButton
              className="mondayButtonRed"
              Icon={<CloseCircleFilled />}
              onClick={() => {
                return onActiveFiltering("type", "refuse");
              }}
            >
              Refuzoi
            </MondayButton>
            <MondayButton
              className="mondayButtonBlue"
              Icon={<CloseCircleOutlined />}
              onClick={() => {
                return onActiveFiltering("type", "skip");
              }}
            >
              Anashkaloi
            </MondayButton>
            <MondayButton
              className="mondayButtonGreen"
              Icon={<CheckCircleFilled />}
              onClick={() => {
                return onActiveFiltering("type", "confirm");
              }}
            >
              Konfirmoi
            </MondayButton>
            <Select
              placeholder="Zgjidh departamentin"
              style={{ width: 250, borderRadius: 5 }}
              value={activeFiltering?.department || undefined}
              onChange={(selected) => {
                return onActiveFiltering("department", selected);
              }}
              options={allDepartments?.map((e) => {
                return { value: e, label: e };
              })}
            />
            <Select
              placeholder="Zgjidh punonjesin"
              style={{ width: 250, borderRadius: 5 }}
              value={activeFiltering?.user || undefined}
              onChange={(selected) => {
                return onActiveFiltering("user", selected);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              options={employees?.map((e) => {
                return {
                  value: e?.employeeId,
                  label: e?.employeeFirstName + " " + e?.employeeLastName,
                };
              })}
            />
            {Object.keys(activeFiltering).some(
              (e) => activeFiltering?.[e] !== false
            ) && (
              <MondayButton
                className="mondayButtonGrey"
                Icon={<CloseCircleFilled />}
                onClick={() => {
                  return onActiveFiltering("clear");
                }}
              >
                Fshi filtrat
              </MondayButton>
            )}
          </div>
          <List
            className="demo-loadmore-list"
            style={{
              overflowY: "auto",
            }}
            // loading={initLoading}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={employeesResponses}
            renderItem={(item) => (
              <List.Item
                actions={
                  item?.response === "refuse"
                    ? [
                        <a
                          key="list-loadmore-more"
                          onClick={async () => {
                            let test = await onResend(
                              item?.employeeId,
                              item?.announcementId
                            );
                            console.log("test", test);
                            setVisible(false);
                          }}
                        >
                          ridergo
                        </a>,
                      ]
                    : []
                }
              >
                <Skeleton avatar loading={false} active={false}>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: getbgcolor(item),
                        }}
                      >
                        {item?.employeeName?.[0]?.toUpperCase()}
                      </Avatar>
                    }
                    style={{ color: "black", borderRadius: 5 }}
                    title={
                      <a href="https://portal.flex.al/punonjesit">
                        {item?.employeeName}
                      </a>
                    }
                    description={`${options?.[
                      item?.response
                    ]?.toLowerCase()} kete lajmerim per here te fundit ne - ${moment(
                      item?.updatedAt
                    ).format("DD/MM/YYYY HH:mm")}`}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default AnnouncementReplies;

const options = {
  confirm: "Konfirmoi",
  refuse: "Refuzoi",
  skip: "Anashkaloi",
};
const options2 = {
  title: "Grafiku i pergjigjeve te punonjesve",
  // width: 700,
  height: 400,
  bar: { groupWidth: "95%" },
  legend: { position: "none" },
};
