import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { Row, Col, Input, Button, Tooltip } from "antd";
import {
  IconKerko,
  DeleteIcon,
  // IconFilter,
  IconNdrysho,
  IconExcel,
  IconNgargo,
} from "../../../../assets/icons";
import "./paisjet_orarit.scss";
import PaisjetOraritPostModal from "./PaisjetOraritPostModal";
import { API } from "aws-amplify";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import FilterPaisjetOrarit from "./FilterPaisjetOrarit";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { htmlParser } from "../../../../utils";
import { osName, osVersion, deviceType } from "react-device-detect";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../dynamic components/HtmlExelTabel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobilePaisjetOrarit from "./MobilePaisjetOrarit";
import { v4 as uuidv4 } from "uuid";
import { programFields as programFieldsReducer } from "../../../../store/actions";
import { LogsIcon } from "../../../Documentation/View/assets";
import PaisjetOraritLogs from "./PaisjetOraritLogs";
import FilterAgGrid from "../../../punonjesit/components/FilterAgGrid";

function SistemiPaisjetOrarit() {
  // are the columnDefs that are showed in the AgGrid
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      valueGetter: "node.rowIndex + 1",
      flex: 1.5,
    },
    {
      headerName: "Pajisjet e orarit",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 3,
    },
    {
      headerName: "Pershkrimi per pajisjen",
      field: "description",
      filter: "agTextColumnFilter",
      flex: 5,
      cellRenderer: ({ value }) => htmlParser(value),
    },
    {
      headerName: "Ip për pajisjen",
      field: "ip",
      filter: "agTextColumnFilter",
      flex: 5,
    },
    {
      headerName: "Sistemi",
      field: "sistemi",
      filter: "agTextColumnFilter",
      flex: 5,
    },
    {
      headerName: "Shfletuesi",
      field: "shfletuesi",
      filter: "agTextColumnFilter",
      flex: 5,
    },
    {
      headerName: "",
      field: "",
      filter: "agTextColumnFilter",
      flex: 3,
      // displays the icons in ag grid and with params.data we take the object of the clicked row
      cellRenderer: (params) => (
        <div className="iconat-paisjet">
          <div variant="outlined" cursor="pointer" className="icon-edit">
            <IconNdrysho
              onClick={() => handleUpdate(params.data)}
              width={14}
              height={14}
            />
          </div>
          <GlobalPopupKonfirm
            Button={
              <div variant="outlined" cursor="pointer" className="icon-delete">
                <DeleteIcon
                  width={17}
                  height={17}
                  // onClick={() => handleRequestDelete(params.node.data.id)}
                />
              </div>
            }
            title={"Jeni të sigurt që do të fshini këtë rekord?"}
            onConfirm={() => handleRequestDelete(params.node.data.id)}
            okText={"Po"}
            cancelText={"Jo"}
          />
        </div>
      ),
    },
  ];
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState(null);
  const [visible, setVisible] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  //get the table programFields with redoux
  const { programFields } = useSelector((state) => state.programFields);
  const [dataLista, setDataLista] = useState("");
  const [shouldShowError, setShouldShowError] = useState(false);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);

  // find the nedded objec in the table
  const filterData = useMemo(() => {
    return programFields?.find(
      (data) => data?.fieldId === "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b"
    );
  }, [programFields]);

  // console.log("filterData", filterData);

  // the state with the initial values
  const [initialValue, setInitialValue] = useState({
    // id: uuidv4(),
    name: "",
    description: "",
    ip: "",
    shfletuesi: deviceType,
    sistemi: `${osName} ${osVersion}`,
  });

  // opens the modal to create a new record
  const handleClickOpen = () => {
    setVisible(true);
    setDataLista({ ...initialValue, id: uuidv4() });
    setShouldShowError(true);
    // form.resetFields();
  };

  // delete the row in the agGrid
  const handleRequestDelete = (id) => {
    let newArray = filterData?.fieldOptions?.filter((r) => r.id !== id);
    API.put(
      "programFields",
      "/programFields/32ca97d2-1eb1-43c3-9e80-a30a60acfc3b",
      {
        body: {
          fieldOptions: newArray,
        },
      }
    ).then(() => {
      let field = [...filterData?.fieldOptions.filter((el) => el.id !== id)];
      let index = programFields.findIndex(
        ({ fieldId }) => fieldId === "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b"
      );
      let newValues = [...programFields];
      if (index !== -1) {
        newValues[index] = { ...newValues[index], fieldOptions: field };
      }
      dispatch(programFieldsReducer(newValues));
      gridApi.applyTransaction({
        remove: [{ id: id }],
      });
    });
  };

  //opens the modal with the state with the object of the clicked row
  const handleUpdate = (oldData) => {
    setVisible(true);
    setDataLista(oldData);
    setShouldShowError(false);
  };

  //make the agGrid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridApi(params.api);
    params.api.setRowData(filterData?.fieldOptions);
  };

  const getRowId = useCallback((param) => {
    return param?.id;
  }, []);

  // clear any filter
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
    setAgGridSearchInput(null);
  };

  //global search of agGrid
  const onFilterTextChange = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
    setAgGridSearchInput(e.target.value);
  };

  // get ip data
  const getIp = async () => {
    await API.get("getIp", "/getIp").then((res) =>
      setInitialValue((prev) => ({ ...prev, ip: res.split(",")[0] }))
    );
    // setInitialValue((prev) => ({ ...prev, shfletuesi: deviceType }));
  };

  // execute the function when component will render
  useEffect(() => {
    getIp();
  }, []);

  // dropdon filter check to not repeat same value 2 times
  const duplicateCheck = [];
  const descriptionduplicateCheck = [];
  const ipduplicateCheck = [];

  filterData?.fieldOptions &&
    filterData?.fieldOptions
      ?.map((data) => {
        if (duplicateCheck.includes(data.name)) return null;
        duplicateCheck.push(data.name);
        return data;
      })
      .filter((e) => e);

  filterData?.fieldOptions &&
    filterData?.fieldOptions
      ?.map((data) => {
        if (descriptionduplicateCheck.includes(data.description)) return null;
        descriptionduplicateCheck.push(data.description);
        return data;
      })
      .filter((e) => e);

  filterData?.fieldOptions &&
    filterData?.fieldOptions
      ?.map((data) => {
        if (ipduplicateCheck.includes(data.ip)) return null;
        ipduplicateCheck.push(data.ip);
        return data;
      })
      .filter((e) => e);

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  // console.log("filterData", filterData);

  return (
    <LoadableComp loading={!!!filterData?.fieldOptions}>
      {isMobileView ? (
        <div className="sistemi-paisjet-orarit">
          <div className="menaxhimi-orarit">
            <div className="ag-grid-punojsit">
              <div className="ag-grid-header">
                <Row style={{ width: "100%" }}>
                  <Col
                    flex={4}
                    style={{ display: "flex" }}
                    className="responsive-col-grid-pagesa"
                  >
                    <div className="header-search">
                      <div className="icon-search-header">
                        <IconKerko width={15} height={15} />
                      </div>
                      <Input
                        placeholder="Kërko një rekord"
                        onChange={onFilterTextChange}
                        value={agGridSearchInput}
                        style={{ width: 180 }}
                      />
                      <Button className="pastro-btn" onClick={clearFilters}>
                        Pastro
                      </Button>
                    </div>
                    <div className="header-icons">
                      {/* modal with advance  agGrid filters  */}
                      <FilterPaisjetOrarit
                        {...{
                          ipduplicateCheck,
                          descriptionduplicateCheck,
                          duplicateCheck,
                          gridApi,
                          clearFilters,
                        }}
                      />
                      <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="sistemi-paisjet-orarit"
                        filename="paisjet-orarit"
                        sheet="paisjet-orarit"
                        buttonText={
                          <Tooltip
                            placement="top"
                            overlayClassName="global-icon-tooltip"
                            title={"Eksporto në eksel"}
                          >
                            <IconExcel />
                          </Tooltip>
                        }
                      />
                      <ExportToPdf
                        tabelName={"pajisjetOrarit"}
                        id={"#sistemi-paisjet-orarit"}
                      />
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Printo tabelën"}
                      >
                        <IconNgargo onClick={handlePrint} />
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Shfaq historikun"}
                      >
                        <LogsIcon onClick={() => setLogsVisible(true)} />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col
                    flex={1}
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <Button
                      className="krijo-orar-ri-btn"
                      onClick={handleClickOpen}
                    >
                      Shto një pajisje të re
                    </Button>
                  </Col>
                </Row>
              </div>
              <div
                className="ag-theme-alpine"
                style={{ height: 790, width: "100%" }}
              >
                <AgGridComponent
                  gridApi={gridApi}
                  getRowId={getRowId}
                  rowSelection="single"
                  onGridReady={onGridReady}
                  paginationPageSize={15}
                  columnDefs={columnDefs}
                />
              </div>
            </div>
            <HtmlExelTabel
              tableData={rowData?.map?.(
                ({ name, description, ip, sistemi, shfletuesi }) => ({
                  name,
                  description: htmlParser(description),
                  ip,
                  sistemi,
                  shfletuesi,
                })
              )}
              header={[
                "Emri i pajisjes",
                "Përshkrimi",
                "IP",
                "Sistemi",
                "Shfletuesi",
              ]}
              id="sistemi-paisjet-orarit"
              componentRef={componentRef}
            />
          </div>
          {/* modal to create new record */}
        </div>
      ) : (
        <MobilePaisjetOrarit
          tableData={filterData?.fieldOptions}
          setVisible={setVisible}
        />
      )}
      {logsVisible && (
        <PaisjetOraritLogs
          isVisible={logsVisible}
          setIsVisible={setLogsVisible}
          data={filterData}
          logsName={"Pajisjet"}
        />
      )}
      {visible && (
        <PaisjetOraritPostModal
          {...{
            gridApi,
            visible,
            setVisible,
            programFields,
            dataLista,
            shouldShowError,
            setShouldShowError,
            filterData,
          }}
        />
      )}
    </LoadableComp>
  );
}

export default SistemiPaisjetOrarit;
