import defaultDayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/it";

defaultDayjs.extend(utc);
defaultDayjs.extend(timezone);
defaultDayjs.locale("en");

defaultDayjs.tz.setDefault("Europe/Tirane");

const dayjs = (...args) => {
  return defaultDayjs(...args).tz();
};

const timezonedUnix = (value) => {
  return defaultDayjs.unix(value).tz();
};

dayjs.unix = timezonedUnix;
dayjs.duration = defaultDayjs.duration;

export default dayjs;
