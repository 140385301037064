import { store } from "../../../store/store";
import { programFields as programFieldsReducer } from "../../../store/actions";
import { candidates as candidatesReducer } from "../../../store/actions";
import moment from "moment";
import { API } from "aws-amplify";
import { notificationUserToAdmin } from "../../../utils/sendNotification";
import {
  apiDelete,
  apiPost,
  apiPut,
} from "../../DocumentationConfiguration/utils/api";
import {
  applicantInterviewDateKeylog,
  translateObjectFields,
  createDriveFolder,
} from "../../Intervistat/Kandidatet/ShtoKandidat/ShowContactDetails/ShowContactDetails";

// WILL BE ENV VARIABLES LATER
const REACT_APP_CALENDLY_TOKEN =
  "Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzIzMjAwNjQyLCJqdGkiOiIwYzBmZmRkMy1iYmU4LTRmYWUtYjBiZC1lZWY2YTU2OWRlZDYiLCJ1c2VyX3V1aWQiOiJiNTcwN2Q5NS1iYjMwLTQ0MWQtYWRkMS1mYjk2MThkZWRkZjgifQ.4ezd5DLm9R4h5nKHyIC8fPe0QjdI_jCD3PqF_P4fgC-0W7ZXjluGKkSGpk2mIOSaRvleysb_RGe-r-czn-5A2A";
const GET_ALL_EVENTS =
  "https://api.calendly.com/scheduled_events?organization=https://api.calendly.com/organizations/63477c25-5b62-4da0-b8a0-5ee8e0c189f2";
const CHECK_FIELD_ID = "345gfdg-4354356dfghdf-45-67-45-6";
const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const getStore = (field) => {
  const state = store.getState();
  return state[field];
};
const dispatch = () => {
  return store.dispatch;
};
const options = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: REACT_APP_CALENDLY_TOKEN,
  },
};
const initialData = {
  applicantTeam: null,
  applicantPersonalNumber: null,
  applicantPhoneNumber: null,
  applicantAddress: null,
  applicantDiplomaLevel: null,
  applicantUniversity: null,
  applicantUniversityField: null,
  applicantTrainingStatus: true,
  applicantTraining: null,
  applicantReferenceStatus: true,
  applicantAdvantages: [],
  applicantStartDate: null,
  applicantPayment: null,
  applicantBirthday: null,
  applicantDisadvantages: [],
  googleDriveFiles: [],
  googleDriveFolderId: "",
  applicantPlatform: "",
  applicantReference: null,
};

//region CHECK NEW&SAVE
export const saveNewCandidate = async ({
  candidates,
  departments,
  employees,
  hotCredentials,
  driveRequest,
  defaultPermissions,
}) => {
  const { collection } = await fetch(GET_ALL_EVENTS, options).then((res) =>
    res.json()
  );
  const activeEvents = collection?.filter(
    ({ status = "" }) => status === "active"
  );
  // const canceledEvents = collection?.filter(
  //   ({ status }) => status === "canceled"
  // );

  const invitees = await Promise.all(
    activeEvents?.map(async (event) => {
      if (moment().diff(moment(event?.created_at), "hours") <= 24) {
        const { collection } = await fetch(
          `${event?.uri}/invitees`,
          options
        ).then((res) => res.json());
        const invitee = collection[0];
        return { invitee, event };
      }
    })
  );

  for await (const { invitee, event } of invitees?.filter(Boolean)) {
    const isEventRescheduled = !!invitee?.old_invitee;
    let currentEventsCandidate = currentCandidate(
      candidates,
      invitee?.old_invitee?.split("/invitees/")[0],
      invitee?.event
    );
    const doesEventExist =
      (invitee?.old_invitee?.split("/invitees/")[0] || invitee?.event) ===
      currentEventsCandidate?.applicantEventURI;

    const {
      inviteeReApplied,
      inviteeDepartment,
      inviteeRole,
      inviteePhoneNumber,
      inviteeReference,
    } = getInviteeFormData(candidates, departments, invitee);

    const toSend = {
      applicantEmail: invitee?.email,
      applicantEventURI: invitee?.event,
      applicantFirstName:
        invitee?.first_name || invitee?.name.split(" ")[0] || "",
      applicantLastName:
        invitee?.last_name || invitee?.name.split(" ")[1] || "",
      applicantStatus: "Draft",
      applicantRoles: [inviteeRole],
      applicantInterviewDate: event?.start_time,
      applicantDepartment: inviteeDepartment,
      applicantReApplied: inviteeReApplied,
      applicantReference: `<p>${inviteeReference}</p>`,
      applicantPhoneNumber: inviteePhoneNumber || null,
    };

    let parentId = "";
    if (!!hotCredentials?.length) {
      const parentFolder = hotCredentials.find(
        (el) => el?.credentialId === "8"
      );
      parentId = parentFolder.credentialValue;
    }

    const dateObj = new Date();
    const timestamp = `${
      dateObj.getUTCDate() + 1
    }${dateObj.getUTCMonth()}${dateObj.getUTCFullYear()}`;
    const folderName = `${invitee?.first_name} ${invitee?.last_name}-${timestamp}`;

    const googleDriveFolderId = await createDriveFolder({
      driveRequest,
      parentId,
      folderName,
      defaultPermissions,
    });

    if (
      !!isEventRescheduled &&
      !!currentEventsCandidate &&
      currentEventsCandidate?.applicantEventURI !== invitee?.event
    ) {
      const { applicantId, userId, applicantEventURI, ...rest } =
        currentEventsCandidate;
      const changes = getKeylogs({ currentEventsCandidate, toSend });

      if (!!applicantId) {
        await apiPut("interviews", applicantId, {
          ...rest,
          ...toSend,
          googleDriveFolderId,
          keylog: [...(rest?.keylog || []), ...(changes || [])],
        })
          .then((res) => {
            dispatch(candidatesReducer(res));
            if (
              !moment(currentEventsCandidate?.applicantInterviewDate).isSame(
                event?.start_time
              )
            ) {
              const notifBody = {
                notificationTitle:
                  "Një kandidat riplanifikoi orën e intervistës",
                notificationBody: `Kandidati ${invitee?.first_name} ${
                  invitee?.last_name
                } ka caktuar datën ${moment(event?.start_time).format(
                  "DD/MM/YYYY"
                )} në ${moment(event?.start_time).format(
                  "HH:mm"
                )} si datën e re për të kryer intervistën pranë zyrave Flex.`,
                notificationUrl: "/intervistat",
              };
              notificationUserToAdmin(notifBody, employees);
            }
          })
          .catch((err) => console.log("Error PUTing interview: ", err));
      } else console.log("undefined applicantId");
    } else if (!doesEventExist && !currentEventsCandidate) {
      await apiPost("interviews", {
        ...initialData,
        ...toSend,
        googleDriveFolderId,
      })
        .then((res) => {
          dispatch(candidatesReducer(res));
          const notifBody = {
            notificationTitle: "Kandidat i ri për intervistë",
            notificationBody: `Kandidati ${invitee?.first_name} ${
              invitee?.last_name
            } ka caktuar datën ${moment(event?.start_time).format(
              "DD/MM/YYYY"
            )} në ${moment(event?.start_time).format(
              "HH:mm"
            )} për të kryer intervistë pranë zyrave Flex.`,
            notificationUrl: "/intervistat",
          };
          notificationUserToAdmin(notifBody, employees);
        })
        .catch((err) => console.log("Error POSTing interview: ", err));
    }
  }

  // for (const canceled of canceledEvents) {
  //   for (const candidate of candidates) {
  //     if (candidate?.applicantEventURI === canceled?.uri) {
  //       await apiDelete("interviews", candidate?.applicantId)
  //         .then(() => {})
  //         .catch((err) => {
  //           console.log("Error deleting canceled: ", err);
  //         });
  //     }
  //   }
  // }
  updateAutomationControllerField("calendlyLastCheck");
};

const currentCandidate = (candidates, oldUri, newUri) => {
  return candidates?.find(
    ({ applicantEventURI }) =>
      applicantEventURI === oldUri || applicantEventURI === newUri
  );
};

//region BUILD KEYLOGS
const getKeylogs = ({ currentEventsCandidate, toSend }) => {
  const { programFields } = getStore("programFields");
  const { fieldOptions = undefined } = programFields?.find(
    ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
  );
  const { applicantEventURI, createdAt, ...rest } = toSend;
  let changes = [];
  const keylogData = ({ key, value, field = "", format = "" }) => {
    return {
      activity: "Ndryshuar",
      author: rest.applicantFirstName + " " + rest.applicantLastName,
      changeDate: moment().valueOf(),
      field: field || key,
      oldValue: !!format
        ? moment(currentEventsCandidate?.[key]).format(format)
        : currentEventsCandidate?.[key],
      newValue: value,
    };
  };
  for (const [key, value] of Object.entries(rest)) {
    if (Array.isArray(value)) {
      if (currentEventsCandidate?.[key][0] !== rest?.[key][0]) {
        changes.push(keylogData({ key, value, field: "Rolet" }));
      }
    } else if (moment(value, moment.ISO_8601).isValid()) {
      const interviewDateValues = applicantInterviewDateKeylog({
        originalObject: currentEventsCandidate,
        changedObject: rest,
        key,
      });
      for (let i = 0; i < interviewDateValues.length; i++) {
        const el = interviewDateValues[i];
        changes.push(keylogData(el));
      }
    } else {
      if (currentEventsCandidate?.[key] !== rest?.[key]) {
        changes.push(keylogData({ key, value }));
      }
    }
  }

  return translateObjectFields({ fieldOptions, changes });
};

//region CALENDLY Q&A
const getInviteeFormData = (candidates, departments, invitee) => {
  let inviteeReApplied = false;
  for (let i = 0; i < candidates?.length; i++) {
    if (
      candidates[i]?.applicantFirstName.toUpperCase() ===
        invitee?.first_name.toUpperCase() &&
      candidates[i]?.applicantLastName.toUpperCase() ===
        invitee?.last_name.toUpperCase() &&
      !invitee?.old_invitee &&
      candidates[i]?.createdAt < new Date(invitee?.created_at).getTime()
    ) {
      inviteeReApplied = true;
      break;
    }
  }

  let inviteeRole = null;
  let inviteePhoneNumber = null;
  let inviteeReference = null;
  let qAndALength = invitee?.questions_and_answers.length;
  for (let i = 0; i < qAndALength; i++) {
    const qAndA = invitee.questions_and_answers[i];
    if (qAndA.question === "For which position did you apply?") {
      inviteeRole = qAndA.answer;
    }
    if (qAndA.question === "Phone Number") {
      initialData.applicantPhoneNumber = qAndA?.answer;
      inviteePhoneNumber = qAndA?.answer;
    }
    if (qAndA.question === "Reference if any:") {
      initialData.applicantReference = qAndA?.answer;
      inviteeReference = qAndA?.answer;
    }
  }

  let inviteeDepartment = null;
  for (let i = 0; i < departments?.length; i++) {
    const dp = departments[i];
    for (let j = 0; j < dp.departmentRoles.length; j++) {
      const role = dp.departmentRoles[j];
      if (role?.name === inviteeRole) {
        inviteeDepartment = dp.departmentName;
        break;
      }
    }
    if (inviteeDepartment) {
      break;
    }
  }
  return {
    inviteeReApplied,
    inviteeDepartment,
    inviteeRole,
    inviteePhoneNumber,
    inviteeReference,
  };
};

//region UPDATE TIMER
export const updateAutomationControllerField = (fieldName) => {
  const { programFields = [] } = getStore("programFields");
  const autoController = programFields?.find(
    ({ fieldId }) => fieldId === CHECK_FIELD_ID
  )?.fieldOptions;

  if (autoController) {
    const newFieldOptions = {
      ...(autoController || {}),
      [fieldName]: moment().toISOString(),
    };

    API.put("programFields", `/programFields/${CHECK_FIELD_ID}`, {
      body: {
        fieldOptions: newFieldOptions,
      },
    })
      .then(() => {
        const newProgramFields = [...programFields];
        const fieldIndex = newProgramFields.findIndex(
          ({ fieldId }) => fieldId === CHECK_FIELD_ID
        );
        if (fieldIndex > -1) {
          newProgramFields[fieldIndex] = {
            ...newProgramFields[fieldIndex],
            fieldOptions: newFieldOptions,
          };
          dispatch(programFieldsReducer(newProgramFields));
        }
      })
      .catch((err) => {
        console.log("Error updating fields: ", err);
      });
  }
};
