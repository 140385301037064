import { Modal } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Snowflake } from "../icons";
// import HappyHolidays from "../pictures/HappyHolidays.png";
// import HappyAutumn from "../pictures/HappyAutumn.jpg";
import HappyThanksGiving from "../pictures/FlexThanksGiving.png";
import "./HappyHolidays.scss";
function HappyHolidaysModal({ visible = false }) {
	const [visibility, setVisible] = useState(false);

	useEffect(() => {
		let holidayTime = localStorage.getItem("gezuarVitinERi");
		if (!!holidayTime) {
			if (moment().diff(moment(holidayTime), "hours", true) > 8) {
				localStorage.setItem("gezuarVitinERi", moment().valueOf());
				setVisible(true);
			}
		} else {
			localStorage.setItem("gezuarVitinERi", moment().valueOf());
			setVisible(true);
		}
	});

	return (
		<Modal
			wrapClassName="happy-holidays-modal"
			centered
			open={visibility}
			width={"900px"}
			title={null}
			footer={null}
			closeIcon={<Snowflake />}
			onCancel={() => setVisible(false)}
		>
			{/* <img className="image-happy-holidays" src={HappyHolidays} alt="Happy Holidays" /> */}
			<img
				className="image-happy-holidays"
				src={
					"https://i.ibb.co/XV2HN9X/Black-Gold-Elegant-Stars-Background-Happy-New-Year-Facebook-Post.gif"
				}
				alt="Happy Autumn"
				style={{
					width: "100%",
					height: "auto",
					borderRadius: "10px",
					display: "flex",
					justifyContent: "center",
				}}
			/>
		</Modal>
	);
}

export default HappyHolidaysModal;
