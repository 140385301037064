import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  Card,
  Form,
  Select,
  message,
  Checkbox,
  Skeleton,
  DatePicker,
} from "antd";
import { CloseIcon, TickIcon } from "../../../../../assets/icons";
import "./details.scss";
import { useAuth } from "../../../../../authentication/authHelpers";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import PunonjesitContext from "../../../store/PunonjesitContext";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

function Details({
  handleDetailsClose,
  setFormData,
  changes,
  setChanges,
  handleChange,
  formData,
  setValue,
  socketHandler,
}) {
  const { employee, setEmployee } = useContext(PunonjesitContext);
  const id = employee.employeeId;
  const { employees } = useSelector((state) => state.employeesList);
  const { departments } = useSelector((state) => state.departments);
  const { programFields } = useSelector((state) => state.programFields);
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lloj, setLloj] = useState([]);
  const [contractDuration, setContractDuration] = useState([]);
  const [selcetValue, setSelectValue] = useState(
    employee?.employeeDepartmentName || null
  );
  const [roles, setRoles] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [visibleDate, setVisibleDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState("none");
  const [depId, setDepId] = useState(null);
  const [team, setTeam] = useState();
  const [startDate] = useState();

  let auth = useAuth();

  console.log({ departments });

  const handleSave = (success) => {
    setLoading(true);
    if (!!employee?.keylog) {
      API.put("employees", `/employees/${id}`, {
        body: {
          ...success,
          employeeDepartmentId: depId,
          employeeDateOfReEmployment: moment(
            form.getFieldValue("employeeDateOfReEmployment")
          ).valueOf(),
          employeeStatus: form.getFieldValue("employeeStatus"),
          employeeContractDuration: form.getFieldValue(
            "employeeContractDuration"
          ),
          // ? "Aktiv"
          // : employee?.employeeStatus,
          keylog: [...employee?.keylog, ...changes],
        },
      }).then((r) => {
        // if (socketHandler.readyState === socketHandler.OPEN)
        //   socketHandler.send(
        //     JSON.stringify({
        //       request: "user_edit",
        //       body: r?.employee?.Item,
        //       id: auth?.employeeId,
        //     })
        //   );
        showMessage(r);
        handleDetailsClose(r);
        setLoading(false);
        setEmployee(r?.employee?.Item);
      });
    } else {
      API.put("employees", `/employees/${id}`, {
        body: {
          ...success,
          employeeDepartmentId: depId,
          employeeHireDate: moment(
            form.getFieldValue("employeeHireDate")
          ).valueOf(),
          keylog: [...changes],
        },
      }).then((r) => {
        // if (socketHandler.readyState === socketHandler.OPEN)
        //   socketHandler.send(
        //     JSON.stringify({
        //       request: "user_edit",
        //       body: r?.employee?.Item,
        //       id: auth?.employeeId,
        //     })
        //   );
        showMessage(r);
        handleDetailsClose(r);
        setLoading(false);
        setEmployee(r?.employee?.Item);
      });
    }
  };

  const showMessage = () => {
    message.success("Të dhënat u ndryshuan!");
  };

  //* you can change fields and input new data
  const handleChangeDep = (objField, e, label) => {
    const value = objField;
    let changedObj = {
      activity: "Ndryshuar",
      author:
        auth?.user?.attributes?.given_name +
        " " +
        auth?.user?.attributes?.family_name,
      changeDate: moment().valueOf(),
      field: label,
      oldValue: employee[`${value}`],
      newValue: e.split(" ").slice(0, -1).join(" "),
    };
    setDepId(e.split(" ").slice(-1)[0]);
    setChanges([
      ...changes.filter((el) => el.field !== changedObj?.field),
      changedObj,
    ]);
    if (objField === "employeeDepartmentName") {
      setSelectValue(e.split(" ").slice(0, -1).join(" "));
      form.setFieldsValue({
        employeeRoleName: null,
      });
    }
  };

  //* shows at Details card the date when an employee left
  const showField = (e) => {
    e !== "Aktiv" && e !== null ? setShowEndDate("") : setShowEndDate("none");
  };

  //* makes visible to input Supervisor if an employee doesn't have one
  const handleDisplay = () => {
    setVisibility(!visibility);
  };

  //* makes visible DatePicker to add a date of ReEmployment
  const reEmploymentDate = () => {
    setVisibleDate(!visibleDate);
  };

  useEffect(() => {
    form.setFieldsValue({
      employeeTeam: employee?.employeeTeam,
      employeeDepartmentName: employee?.employeeDepartmentName,
      employeeDepartmentId: employee?.employeeDepartmentId,
      employeeRoleName: employee?.employeeRoleName,
      employeeTypeOfEmployment: employee?.employeeTypeOfEmployment,
      employeeContractDuration: employee?.employeeContractDuration,
      employeeBenefitClass: employee?.employeeBenefitClass,
      employeeSupervisor: employee?.employeeSupervisor,
      employeeRoleName: employee?.employeeRoleName,
      employeelsSupervisor: employee?.employeelsSupervisor,
      employeeHireDate: moment(employee?.employeeHireDate),
      employeeStatus: employee?.employeeStatus,
    });
    if (moment(employee?.employeeDateOfReEmployment).isValid()) {
      form.setFieldsValue({
        employeeDateOfReEmployment: moment(
          employee?.employeeDateOfReEmployment
        ),
      });
    }
  }, [employee, form]);

  useEffect(() => {
    if (!!selcetValue && !!departments) {
      setRoles(departments?.find((dpt) => dpt?.departmentName === selcetValue));
    }
  }, [selcetValue, departments]);

  console.log({ roles });
  useEffect(() => {
    employee?.employeelsSupervisor ? setVisibility(false) : setVisibility(true);
  }, []);

  useEffect(() => {
    if (programFields?.length > 0) {
      setOptions(
        programFields
          ?.filter((word) => word?.fieldName === "Statusi i punonjesit")[0]
          ?.fieldOptions.filter((item) => item.name !== "Logs")
      );
      setLloj(
        programFields?.filter(
          (word) => word?.fieldName === "Lloji i punesimit"
        )[0]?.fieldOptions
      );
    }
  }, []);

  useEffect(() => {
    const contractTime = programFields?.find(
      (el) => el?.fieldName === "Periudha e Kontrates"
    );
    setContractDuration(contractTime);
  }, [programFields]);

  useEffect(() => {
    const companyTeam = programFields?.find(
      (res) => res?.fieldName === "Employee Team"
    );
    setTeam(companyTeam);
  }, []);

  useEffect(() => {
    if (
      employee?.employeeStatus !== "Aktiv" &&
      employee?.employeeStatus !== null
    ) {
      setShowEndDate("");
    }
  }, []);

  useEffect(() => {
    setChanges([]);
  }, [employee, employees]);

  useEffect(() => {
    employee?.employeeDateOfReEmployment !== undefined
      ? setVisibleDate(true)
      : setVisibleDate(false);
  }, []);

  //set supervisors for Detaje dropdown
  useEffect(() => {
    let supervisorsFilter = employees
      .filter(
        (el) =>
          el.employeeDepartmentName === "Administration" &&
          el.employeeRoleName === "Administrator"
      )
      .map((admin) => admin.employeeFirstName + ` ` + admin.employeeLastName);
    supervisorsFilter.push(roles?.departmentManagerName);
    setSupervisors(supervisorsFilter);
  }, [employees, roles]);

  //region RETURN
  return (
    <div className="details-edit-card">
      <Card
        className="info-card"
        title="Detaje"
        extra={
          <div className="icon-button">
            <Form.Item>
              <button
                id="close"
                onClick={handleDetailsClose}
                style={{ marginRight: 10 }}
              >
                <CloseIcon className="action-icon" />
              </button>
              <button id="tick" form="details" htmlType="submit">
                <TickIcon className="action-icon" />
              </button>
            </Form.Item>
          </div>
        }
        style={{ width: "100%" }}
      >
        {loading === false ? (
          <Form
            className="details"
            colon={false}
            name="details"
            labelCol={{ flex: "160px" }}
            labelAlign="left"
            autoComplete="off"
            onFinish={handleSave}
            form={form}
          >
            {employee?.employeeStatus === "Aktiv" ? (
              <>
                <div className="wide-div">
                  <Form.Item
                    name="employeeTeam"
                    label="Team:"
                    rules={[
                      { required: true, message: "Ju lutem zgjidhni team!" },
                    ]}
                  >
                    <Select
                      name="employeeTeam"
                      variant={false}
                      style={{
                        height: 32,
                        borderRadius: 5,
                        background: "#f5f5f7",
                      }}
                      className="select-details"
                      placeholder="Zgjidh team"
                      allowClear
                      onChange={(val) =>
                        handleChange("employeeTeam", val, "Employee Team")
                      }
                    >
                      {team?.fieldOptions?.map((val) => (
                        <Option value={val}>{val}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="wide-div">
                  <Form.Item
                    name="employeeDepartmentName"
                    label="Departamenti:"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem zgjidhni departamentin!",
                      },
                    ]}
                  >
                    <Select
                      variant={false}
                      style={{
                        height: 32,
                        borderRadius: 5,
                        background: "#f5f5f7",
                      }}
                      className="select-details"
                      placeholder="Zgjidh departamentin"
                      allowClear
                      onChange={(val) =>
                        handleChangeDep(
                          "employeeDepartmentName",
                          val,
                          "Departamenti"
                        )
                      }
                    >
                      {departments?.map((department) => (
                        <Option
                          value={
                            department?.departmentName +
                            " " +
                            department?.departmentId
                          }
                          key={department?.departmentId}
                        >
                          <div className="demo-option-label-item">
                            {department?.departmentName}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="wide-div">
                  <Form.Item
                    name="employeeRoleName"
                    label="Pozicioni:"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem zgjidhni pozicioni!",
                      },
                    ]}
                  >
                    <Select
                      className="select-details"
                      placeholder="Zgjidh Pozicionin"
                      variant={false}
                      style={{
                        height: 32,
                        borderRadius: 5,
                        background: "#f5f5f7",
                      }}
                      allowClear
                      defaultValue={employee?.employeeRoleName}
                      onChange={(val) =>
                        handleChange("employeeRoleName", val, "Pozicioni")
                      }
                    >
                      {roles?.departmentRoles?.map((data) => (
                        <Option key={data?.id} value={data?.name}>
                          {data?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="wide-div">
                  <Form.Item
                    name="employeeOtherPosition"
                    label="Pozicion tjetër:"
                  >
                    <Select
                      variant={false}
                      style={{
                        height: "100%",
                        borderRadius: 5,
                        background: "#f5f5f7",
                      }}
                      className="pozicionTjeter"
                      placeholder="Zgjidh Pozicionin"
                      mode="multiple"
                      allowClear
                      defaultValue={employee?.employeeOtherPosition}
                      onChange={(val) =>
                        handleChange(
                          "employeeOtherPosition",
                          val,
                          "Pozicioni tjetër"
                        )
                      }
                    >
                      {roles?.departmentRoles
                        ?.filter(
                          (el) =>
                            el.name !==
                            form.getFieldsValue("employeeRoleName")
                              .employeeRoleName
                        )
                        ?.map((data) => (
                          <Option key={data?.id} value={data?.name}>
                            {data?.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="wide-div">
                  <Form.Item
                    name="employeeStatus"
                    label="Statusi i punonjesit:"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem zgjidhni statusin e punonjësit!",
                      },
                    ]}
                  >
                    <Select
                      variant={false}
                      style={{
                        height: 32,
                        borderRadius: 5,
                        background: "#f5f5f7",
                        margin: 0,
                      }}
                      className="detailsInfo"
                      placeholder="Zgjidh statusin"
                      allowClear
                      onChange={(e) => {
                        showField(e);
                        handleChange(
                          "employeeStatus",
                          e,
                          "Statusi i punonjesit"
                        );
                      }}
                    >
                      {options?.map((option) => (
                        <Option value={option?.name} key={option?.id}>
                          <div className="demo-option-label-item">
                            {option?.name}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="wide-div">
                  <Form.Item
                    name="employeeHireDate"
                    label="Data e punësimit:"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem zgjidhni data e punësimit!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      placeholder="Zgjidh datën e punësimit"
                      allowClear
                      onChange={(val) =>
                        handleChange(
                          "employeeHireDate",
                          val,
                          "Data e punesimit"
                        )
                      }
                      className="my-picker"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="wide-div"
                  style={{ display: "flex", width: "100%" }}
                >
                  <Form.Item
                    style={{ width: "100%" }}
                    name="employeeTypeOfEmployment"
                    label="Lloji punësimit:"
                    rules={[
                      {
                        required: true,
                        message: "Zgjidhni llojin e punësimit!",
                      },
                    ]}
                  >
                    <Select
                      className="detailsInfo"
                      variant={false}
                      style={{
                        width: "100%",
                        height: 32,
                        borderRadius: 5,
                        background: "#f5f5f7",
                        margin: 0,
                      }}
                      placeholder="Lloji i punësimit"
                      allowClear
                      onChange={(val) =>
                        handleChange(
                          "employeeTypeOfEmployment",
                          val,
                          "Lloji punësimit"
                        )
                      }
                    >
                      {lloj?.map((option) => (
                        <Option value={option}>
                          <div className="demo-option-label-item">{option}</div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div
                  className="wide-div"
                  style={{ display: "flex", width: "100%" }}
                >
                  <Form.Item
                    style={{ width: "100%", margin: 0 }}
                    name="employeeContractDuration"
                    label="Periudha e kontrates:"
                    rules={[
                      {
                        required: true,
                        message: "Zgjidhni periudhen e kontrates!",
                      },
                    ]}
                  >
                    <Select
                      className="select-details"
                      allowClear
                      variant={false}
                      style={{
                        width: "100%",
                        height: 32,
                        borderRadius: 5,
                        background: "#f5f5f7",
                        margin: 0,
                      }}
                      placeholder="Periudha e kontrates"
                      onChange={(val) =>
                        handleChange(
                          "employeeContractDuration",
                          val,
                          "Periudha e kontrates"
                        )
                      }
                    >
                      {contractDuration?.fieldOptions?.map((duration) => (
                        <Option value={duration?.name}>
                          <div className="demo-option-label-item">
                            {duration?.name}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="detailCheckBoxes">
                  <div id="supervisor">
                    <Form.Item
                      name="employeelsSupervisor"
                      valuePropName="checked"
                      style={{ margin: 0 }}
                    >
                      <Checkbox
                        onClick={(e) => {
                          handleChange(
                            "employeelsSupervisor",
                            e.target.checked,
                            "Eshte supervizor"
                          );
                          handleDisplay();
                        }}
                      >
                        <span className="label-style">Është supervizor</span>
                      </Checkbox>
                    </Form.Item>
                    <div
                      className="wide-div"
                      style={{ display: visibility ? "" : "none" }}
                    >
                      <Form.Item
                        style={{ margin: 0 }}
                        name="employeeSupervisor"
                        label="Supervizori:"
                        rules={[
                          {
                            required: visibility,
                            message: "Zgjidhni supervizorin!",
                          },
                        ]}
                      >
                        <Select
                          className="select-details"
                          placeholder="Zgjidhni supervizorin"
                          variant={false}
                          style={{
                            height: 32,
                            borderRadius: 5,
                            background: "#f5f5f7",
                          }}
                          allowClear
                          onChange={(val) =>
                            handleChange(
                              "employeeSupervisor",
                              val,
                              "Supervizori"
                            )
                          }
                        >
                          {supervisors?.map((el) => (
                            <Option value={el}>{el}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <Form.Item
                      name="employeeReEmploymentDate"
                      style={{ margin: 0 }}
                    >
                      <Checkbox
                        defaultChecked={
                          !!employee?.employeeDateOfReEmployment &&
                          employee?.employeeDateOfReEmployment !== ""
                            ? true
                            : false
                        }
                        onClick={(res) => {
                          handleChange(
                            "employeeDateOfReEmployment",
                            res.target.checked,
                            "Data e ripunësimit"
                          );
                          reEmploymentDate();
                        }}
                      >
                        <span className="label-style">Data e ripunësimit</span>
                      </Checkbox>
                    </Form.Item>
                    <div
                      className="wide-div"
                      style={{ display: visibleDate ? "" : "none" }}
                    >
                      <Form.Item
                        name="employeeDateOfReEmployment"
                        label="Data e ripunësimit:"
                      >
                        <DatePicker
                          format={dateFormat}
                          placeholder="Zgjidh datën e ripunësimit"
                          allowClear
                          onChange={(val) =>
                            handleChange(
                              "employeeDateOfReEmployment",
                              val,
                              "Data e ripuënsimit"
                            )
                          }
                          disabledDate={(curr) =>
                            curr < startDate ||
                            curr > moment().add(1, "week").valueOf()
                          }
                          className="my-picker"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="wide-div">
                <Form.Item
                  name="employeeStatus"
                  label="Statusi i punonjesit:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem zgjidhni statusin e punonjësit!",
                    },
                  ]}
                >
                  <Select
                    variant={false}
                    style={{
                      height: 32,
                      borderRadius: 5,
                      background: "#f5f5f7",
                      margin: 0,
                    }}
                    className="detailsInfo"
                    placeholder="Zgjidh statusin"
                    allowClear
                    onChange={(e) => {
                      showField(e);
                      handleChange("employeeStatus", e, "Statusi i punonjesit");
                    }}
                  >
                    {options?.map((option) => (
                      <Option value={option?.name} key={option?.id}>
                        <div className="demo-option-label-item">
                          {option?.name}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}
          </Form>
        ) : (
          <Skeleton active paragraph={{ rows: 8 }} />
        )}
      </Card>
    </div>
  );
}
export default Details;
