import {
  Alert,
  Modal,
  Select,
  DatePicker,
  Checkbox,
  message,
  Statistic,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../authentication/authHelpers";
import "./MultiGenerate.scss";
import { paySubFactory } from "../../PaySub/employeeCalc/employeeSubFactory";
import { HeaderStepper } from "../../..";
import { FulfillingSquareSpinner } from "react-epic-spinners";
import PayStub from "../../PaySub/PaySub";
import { useReactToPrint } from "react-to-print";
import MultiGenerateTable from "./MultiGenerateTable";
import { useLocation } from "react-router";
import { exportPagatExcel } from "./excel";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import dayjs from "../../../../utils/dayjs";
import { TransferLabel } from "../../../commonComponents/CustomTransfer/components";
import { Delete, DeleteIcon } from "../../../../assets/icons";
import { PrinterFilled } from "@ant-design/icons";
const { Option } = Select;

function MultiGenerate({ setMultiGenVis, multiGenVis, multiEmpl, clockings }) {
  const [current, setCurrent] = useState(0);
  const { flexEligibleEmployees } = useSelector((state) => state.employeesList);
  const { state } = useLocation();

  // const { clockings } = useSelector((state) => state.clockings);
  const [toGenerate, setToGenerate] = useState({
    timeframe: {
      start: dayjs(state?.data?.muaji || dayjs()).startOf("month"),
      end: dayjs(state?.data?.muaji || dayjs()).endOf("month"),
    },
    employees: [],
  });
  const [loading, setTest] = useState([]);
  const [payStubObj, setPayStubObj] = useState(null);
  // const [form] = For.useForm();
  const [existUnapproved, setExistUnapproved] = useState(false);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { prepayments } = useSelector((state) => state.prepayments);
  const [finalized, setFinalized] = useState(false);
  const [savedPaySttubs, setSavedPayStubs] = useState([]);
  const { wages } = useSelector((state) => state.wages);
  const { programFields } = useSelector((state) => state.programFields);
  //
  const { users } = useSelector((state) => state.usersList);
  const auth = useAuth();

  const onReset = () => {
    setMultiGenVis(false);
    setPayStubObj(null);
    setExistUnapproved(false);
    setCurrent(0);
    setToGenerate({
      timeframe: {
        start: dayjs().startOf("month"),
        end: dayjs().endOf("month"),
      },
      employees: [],
    });
  };

  const selectAllApproved = () => {
    let appliedEmpl = [];
    if (!!flexEligibleEmployees) {
      console.log("flexEligibleEmployees", {
        flexEligibleEmployees,
        toGenerate,
        state,
      });
      flexEligibleEmployees.forEach((e) => {
        let appliedShifts = clockings.filter(
          (c) =>
            c.employeeId === e?.employeeId &&
            dayjs(c?.clockInDate).valueOf() >=
              dayjs(toGenerate?.timeframe?.start).valueOf() &&
            dayjs(c?.clockOutDate).valueOf() <=
              dayjs(toGenerate?.timeframe?.end).valueOf()
        );
        console.log("appliedShifts", appliedShifts);
        if (appliedShifts.length > 0) {
          let areAllApproved = !appliedShifts.some(
            (el) => el?.approved === false
          );
          if (areAllApproved) {
            appliedEmpl.push({
              employeeId: e.employeeId,
              fullName: e.employeeFirstName + " " + e.employeeLastName,
            });
          }
        }
      });
    }
    if (appliedEmpl.length > 0)
      setToGenerate((prev) => ({ ...prev, employees: appliedEmpl }));
  };

  const next = () => {
    setCurrent(current + 1);
    if (current === steps.length - 2) {
      if (
        !!toGenerate?.timeframe &&
        !!programFields &&
        toGenerate?.employees.length > 0 &&
        !!prepayments
      ) {
        const approvals = clockings
          .filter(
            (el) =>
              dayjs(el?.clockInDate).valueOf() >=
                dayjs(toGenerate?.timeframe?.start).valueOf() &&
              dayjs(el?.clockOutDate).valueOf() <=
                dayjs(toGenerate?.timeframe?.end).valueOf()
          )
          .find((el) => el?.approved === false);
        if (!!approvals) {
          setExistUnapproved(true);
        }
        /**
         * DO NOT CHANGE
         */
        const appliedEmployees = toGenerate?.employees;
        setSavedPayStubs([]);
        if (!finalized) {
          appliedEmployees?.map((empl) => {
            const appliedEmployee = flexEligibleEmployees.find(
              (el) => el.employeeId === empl.employeeId
            );
            const appliedClockings = clockings.filter(
              (c) => c.employeeId === empl.employeeId
            );
            const approvedClockings = appliedClockings.filter(
              (el) => el.approved === true
            );

            const test13 = new paySubFactory({
              type: "allEarnings",
              employee: appliedEmployee,
              clockings: approvedClockings,
              employeeRequests: employeeRequests,
              momentInstance: toGenerate?.timeframe,
              prepayments: prepayments,
              programFields: programFields,
            });
            setSavedPayStubs((prev) => (!!prev ? [...prev, test13] : [test13]));
          });
        } else {
          appliedEmployees?.map((empl) => {
            const appliedWage = wages.find(
              (el) =>
                el.employeeId === empl.employeeId &&
                dayjs(el?.startPeriod).format("DD/MM/YYYY") ===
                  dayjs(toGenerate.timeframe.start).format("DD/MM/YYYY") &&
                el?.wageStatus === "Finalized"
            );
            if (!!appliedWage) {
              // console.log("Applied Wage", appliedWage);
              setSavedPayStubs((prev) =>
                !!prev ? [...prev, appliedWage] : [appliedWage]
              );
            } else {
              // WARNING
              // console.log("Selected employee doesn`t have a finalized wage record, continue! ");
            }
          });
        }
      }
    }
  };

  console.log(toGenerate?.employees);

  const prev = () => {
    if (payStubObj !== null) {
      setPayStubObj(null);
      setExistUnapproved(false);
    }
    setCurrent(current - 1);
  };
  const componentRef = useRef("payStub-print");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(toGenerate);

  const PickEmployee = () => (
    <div className="pickEmployeeStep">
      {/* {existUnapproved && (
				<>
					<Alert
						message="Kujdes!"
						description="Për punonjësit dhe periudhën e zgjedhur, ka turne të pa aprovuara qe nuk janë përfshirë në llogaritje."
						type="warning"
						showIcon
						closable
					/>
					<br />
				</>
			)} */}
      <div className="pickEmployee">
        <div className="selectionButtons">
          <MondayButton
            className="mondayButtonGreen"
            onClick={selectAllApproved}
          >
            Zgjidh te gjithe te aprovuarit
          </MondayButton>
          <MondayButton
            className="mondayButtonGreen"
            onClick={() =>
              setToGenerate((prev) => ({ ...prev, employees: [] }))
            }
          >
            Pastro
          </MondayButton>
        </div>
        <div className="employeePicker">
          <span>
            <strong style={{ color: "red" }}>*</strong>
            Zgjidh Punonjësit:
          </span>
          <Select
            className="pick-empl"
            showSearch
            // value={toGenerate?.employees}
            placeholder="Zgjidh Punonjesin"
            // style={{ width: 400 }}
            onSelect={(val, option) => {
              const { value, label } = option;
              setToGenerate((prev) => ({
                ...prev,
                employees: [
                  ...(prev?.employees || []),
                  { employeeId: value, fullName: label },
                ],
              }));
            }}
            optionFilterProp="children"
            // mode="multiple"
            optionLabelProp="label"
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            {flexEligibleEmployees?.map((empl) => (
              <Option
                value={empl?.employeeId}
                label={`${empl?.employeeFirstName} ${empl?.employeeLastName}`}
                key={`${empl?.employeeId}`}
              >
                {getDynamicWarnings(empl)}
              </Option>
            ))}
          </Select>
          <label> Punonjesit e zgjedhur: {toGenerate?.employees.length}</label>
        </div>
      </div>
      <div className="pickedEmployeesList">
        {toGenerate.employees.map((employee) => (
          <div className="pickedContainer">
            <span
              className="deletePicked"
              onClick={() =>
                setToGenerate((prev) => ({
                  ...prev,
                  employees: prev.employees.filter(
                    (el) => el.employeeId !== employee?.employeeId
                  ),
                }))
              }
            >
              <Delete width={22} height={22} fill="#ea3943" />
            </span>
            <span className="pickedTitle">{employee?.fullName}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const getDynamicWarnings = (employee) => {
    let type2 = "success";
    const approvals = clockings
      .filter(
        (el) =>
          dayjs(el?.clockInDate).valueOf() >=
            dayjs(toGenerate?.timeframe?.start).valueOf() &&
          dayjs(el?.clockOutDate).valueOf() <=
            dayjs(toGenerate?.timeframe?.end).valueOf()
      )
      .find(
        (el) =>
          el?.approved === false && el?.employeeId === employee?.employeeId
      );
    // console.log("Approvals", approvals);
    if (!!approvals) type2 = "warning";
    return (
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert
          showIcon
          style={{ width: "100%" }}
          type={type2}
          message={getDynamicName(employee, type2)}
        />
      </div>
    );
  };

  const getDynamicName = (employee, type) => {
    if (type === "warning") {
      return `${employee?.employeeFirstName}  ${employee?.employeeLastName} | ${employee?.employeeDepartmentName} | Turne te Pa Aprovuara`;
    } else {
      return `${employee?.employeeFirstName}  ${employee?.employeeLastName} | ${employee?.employeeDepartmentName} `;
    }
  };

  const PickTime = () => (
    <div
      className="pick-month"
      style={{
        display: "flex",
        justifyContent: "center",
        height: "200px",
        alignItems: "center",
        gap: "7px",
      }}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <strong style={{ color: "red" }}>*</strong>
        Zgjidh Muajin:
      </span>{" "}
      <DatePicker
        width={300}
        defaultValue={toGenerate.timeframe.start}
        // className="month-shift-picker"
        picker="month"
        allowClear={false}
        format={"MMMM YYYY"}
        onChange={(val) =>
          setToGenerate((prev) => ({
            ...prev,
            timeframe: {
              start: dayjs(val).startOf("month"),
              end: dayjs(val).endOf("month"),
            },
          }))
        }
      />
      <span style={{ marginLeft: 50 }}>
        <Checkbox
          checked={finalized}
          onChange={(e) => setFinalized(e.target.checked)}
        >
          Shfaq vetem te finalizuarat
        </Checkbox>
      </span>
    </div>
  );

  const PrintTable = () => {
    return (
      <div style={{ width: 1160, height: "100%", overflowY: "scroll" }}>
        <MultiGenerateTable {...{ savedPaySttubs, componentRef, toGenerate }} />
      </div>
    );
  };

  const steps = [
    {
      title: "Zgjidh periudhën",
      Comp: PickTime,
      disabled: true,
    },
    {
      title: "Punonjesit",
      Comp: PickEmployee,
      disabled: true,
    },
    {
      title: "Gjenero",
      Comp: PrintTable,
      disabled: true,
    },
  ];

  useEffect(() => {
    console.log(multiEmpl);
    if (!!multiEmpl) {
      setToGenerate((prev) => ({ ...prev, employees: multiEmpl }));
    }
  }, [multiEmpl]);

  return (
    <div>
      {" "}
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        // width={1500}
        className="multi-generate-modal darkHeader"
        title="Shiko Pagën për disa punonjës"
        open={multiGenVis}
        onOk={() => setMultiGenVis(false)}
        onCancel={onReset}
        centered={true}
        footer={[
          <>
            {" "}
            <MondayButton className="mondayButtonRed" onClick={onReset}>
              Mbyll Faqen
            </MondayButton>
            {current === 0 && (
              <div className="finished-gen">
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => next()}
                  disabled={
                    !!toGenerate?.timeframe?.start &&
                    !!toGenerate?.timeframe?.end
                      ? false
                      : true
                  }
                  style={{
                    cursor:
                      !!toGenerate?.timeframe?.start &&
                      !!toGenerate?.timeframe?.end
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  Vazhdo me tej
                </MondayButton>
              </div>
            )}
            {current === 1 && (
              <div className="finished-gen">
                <MondayButton
                  className="mondayButtonGrey"
                  style={{ margin: "0 8px" }}
                  onClick={() => prev()}
                >
                  Kthehu mbrapa
                </MondayButton>

                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => next()}
                  style={{
                    cursor:
                      toGenerate.employees.length > 0 &&
                      !!toGenerate?.timeframe?.start &&
                      !!toGenerate?.timeframe?.end
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  Vazhdo me tej
                </MondayButton>
              </div>
            )}{" "}
            {current === steps.length - 1 && (
              <div className="finished-gen">
                {" "}
                <MondayButton
                  className="mondayButtonGrey"
                  style={{ margin: "0 8px" }}
                  onClick={() => prev()}
                >
                  Kthehu mbrapa
                </MondayButton>
                <MondayButton
                  className="mondayButtonYellow"
                  onClick={() => handlePrint()}
                >
                  Printo
                </MondayButton>
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => {
                    exportPagatExcel(savedPaySttubs, toGenerate);
                  }}
                >
                  Shkarko Excel
                </MondayButton>
              </div>
            )}
          </>,
        ]}
      >
        <div className="generate-modal-container">
          {/* {existUnapproved && (
						<>
							<Alert
								message="Kujdes!"
								description="Për punonjësit dhe periudhën e zgjedhur, ka turne të pa aprovuara qe nuk janë përfshirë në llogaritje."
								type="warning"
								showIcon
								closable
							/>
							<br />
						</>
					)} */}
          <HeaderStepper
            currentStep={current}
            setCurrentStep={setCurrent}
            steps={steps}
          />
        </div>
      </Modal>
    </div>
  );
}

export default MultiGenerate;
