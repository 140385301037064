import { Modal } from 'antd'
import React from 'react'
import { TickIcon, Warning, ModalXIcon, XIcon } from '../../../assets/icons';
import MondayButton from '../../commonComponents/MondayButton/MondayButton';
import { API } from 'aws-amplify';
import Swal from 'sweetalert2';

const ConfirmModal = ({
    showClearConfirmModal,
    confirmChangesType,
    setShowClearConfirmModal,
    rowData,
    setRowData,
    setChanges,
    deletedFields,
    programField,
    setProgramField
}) => {
    const handleSubmit = () => {
        Swal.fire({
            title: "Ju lutem prisni...",
            didOpen: () => {
                Swal.showLoading();
            },
        });
        let gridRowData = [];
        gridRowData.push(...deletedFields)
        gridRowData.push(...rowData);
        if (confirmChangesType === "Confirm") {
            API.put("programFields", `/programFields/${programField?.fieldId}`, {
              body: {
                fieldOptions: gridRowData,
              },
            })
              .then(() => {
                setShowClearConfirmModal(false);
                setChanges(false);
                setProgramField(gridRowData);
                Swal.fire({
                  icon: "success",
                  title: "Ndryshimet u ruajten!",
                });
              })
              .catch((err) => {
                console.log("Error: ", err);
              });
        } else {
            setRowData(programField?.fieldOptions)
            setShowClearConfirmModal(!showClearConfirmModal)
            setChanges(false)
            Swal.fire({
                icon: "success",
                title: "Ndryshimet u anulluan!",
                didOpen: () => {
                    Swal.hideLoading()
                }
                // timer: 1500,
            });
        }
    }

    return (
      <Modal
        open={showClearConfirmModal}
        centered
        onCancel={() => setShowClearConfirmModal(!showClearConfirmModal)}
        title="Warning Message"
        className="confirm-modal lightHeader"
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon width={16} height={16}/>}
            onClick={() => setShowClearConfirmModal(!showClearConfirmModal)}
          >
            Cancel
          </MondayButton>,
          <MondayButton
            Icon={<TickIcon width={18} height={18} />}
            onClick={handleSubmit}
            className="mondayButtonGreen"
          >
            Save
          </MondayButton>,
        ]}
      >
        <div className="confirm-body">
          <Warning width={60} height={60} />
          <p>{`Are you sure you want to ${
            confirmChangesType === "Confirm" ? "save" : "cancel"
          } this changes?`}</p>
        </div>
      </Modal>
    );
}

export default ConfirmModal