import React from "react";
import dayjs from "../../../../utils/dayjs";
import { Select, TimePicker, InputNumber } from "antd";
const { Option } = Select;

function DayPayHeader({
  data,
  dayType,
  programFields,
  salaryPerHour,
  setSalaryPerHour,
}) {
  return (
    <div>
      {" "}
      <div className="new-shift-wrapper">
        <div className="warning-title" style={{ margin: 20 }}></div>
        <div className="to-edit-fields" style={{ margin: 20 }}>
          {" "}
          <div className="shift-field-end">
            <label style={{ fontWeight: "600", marginLeft: "35px" }}>
              <strong style={{ color: "red" }}>*</strong>Punonjësi:{" "}
              {/* {`${data?.employee_details?.employeeFirstName} ${data?.employee_details?.employeeLastName}`} */}
            </label>
            <span
              // onClick={toggleApprove}
              style={{
                // fontWeight: "600",
                width: "160px",
                color: "white",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                //   marginTop: "20px",
                marginLeft: "-30px",
                marginRight: "0px",
                cursor: "pointer",
              }}
            >
              <div className="header-edit-modal-aprrove" style={{ width: 160 }}>
                {`${data?.employee_details?.employeeFirstName} ${data?.employee_details?.employeeLastName}`}
              </div>
            </span>
          </div>
          <div className="shift-field-start">
            <label style={{ fontWeight: "600" }}>
              <strong style={{ color: "red" }}>*</strong>Data:{" "}
            </label>
            <div className="header-edit-modal-aprrove">
              {dayjs(data?.data).format("DD/MM/YYYY")}
            </div>
          </div>
          <div style={{ marginLeft: "20px" }} className="shift-field-end">
            <label style={{ fontWeight: "600" }}>
              <strong style={{ color: "red" }}>*</strong>Kategoria:
            </label>
            <Select
              className="daytype-select"
              showSearch
              style={{ backgorund: "#f1f7fb" }}
              defaultValue={dayType(data?.dayType)}
              // onChange={onClockinCateg}
              placeholder="Zgjidh nje opsion"
              optionFilterProp="children"
              disabled={true}
            >
              {programFields
                ?.find((el) => el.fieldName === "ClockIn Types")
                ?.fieldOptions?.map((el, idx) => (
                  <Option key={`shiftType-${idx}`} value={el?.name}>
                    {el?.description}
                  </Option>
                ))}
            </Select>
          </div>
          <div style={{ marginLeft: "20px" }} className="shift-field-end">
            <label style={{ fontWeight: "600" }}>
              <strong style={{ color: "red" }}>*</strong>Fillimi Turnit:
            </label>
            <TimePicker
              disabled={true}
              format={"HH:mm"}
              // onChange={onChange}
              defaultValue={dayjs(data?.fillim_turni)}
            />
          </div>
          <div style={{ marginLeft: "20px" }} className="shift-field-end">
            <label style={{ fontWeight: "600" }}>
              <strong style={{ color: "red" }}>*</strong>Mbarimi Turnit:
            </label>
            <TimePicker
              disabled={true}
              format={"HH:mm"}
              // onChange={onChange}
              defaultValue={dayjs(data?.mbarim_turni)}
            />
          </div>
          <div style={{ marginLeft: "20px" }} className="shift-field-end">
            <label style={{ fontWeight: "600" }}>
              <strong style={{ color: "red" }}>*</strong>Pagesa për orë:
            </label>
            <div
              className="header-edit-modal-aprrove"
              style={{ cursor: "pointer" }}
            >
              <InputNumber
                style={{ width: "100%" }}
                variant={false}
                min={1}
                max={100000}
                value={salaryPerHour}
                onChange={(e) => setSalaryPerHour(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DayPayHeader;
