import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
import "./calendarTimeline.scss";
import Clock from "react-live-clock";
import { Col, Row, Divider, Skeleton } from "antd";
import dayjs from "../../../../../../utils/dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import { useSelector } from "react-redux";
import { round } from "lodash";
import "react-calendar-timeline/lib/Timeline.css";

// dayjs.extend(AdvancedFormat);

const Departments = ({ leave, employeesData }) => {
  const [activeTab, setActiveTab] = useState();
  const [percentage, setPercentage] = useState(0);
  const [names, setNames] = useState([{}]);
  const [ganttItems, setGanttItems] = useState([]);
  const [disab, setDisab] = useState(false);
  const { departments } = useSelector((state) => state.departments);
  const defaultTimeStart = moment().add(0, "month");
  const defaultTimeEnd = moment().add(1, "month");
  //Percakton ngyren e lejes sipas tipit, leje lindje, raport ose pushime
  let onVacation = 0;
  const colorVacations = (type) => {
    let color = "";
    switch (type) {
      case "Pushime":
        color = "#1DA193";
        break;
      case "Dite Personale":
        color = "#858585";
        break;
      case "Leje lindje":
        color = "#DE8EBD";
        break;
      default:
        color = "#EA3943";
    }
    return color;
  };

  const colorVacationsSideBar = (type) => {
    let colorSideBar = "";
    switch (type) {
      case "Pushime":
        colorSideBar = "#9BD8C5";
        break;
      case "Dite Personale":
        colorSideBar = "#C6C9CC";
        break;
      default:
        colorSideBar = "#EA3943";
    }
    return colorSideBar;
  };
  //Formaton vlerat e perqindjes te pushimet
  const formatPercentage = (percentage) => {
    if (isNaN(percentage)) {
      return 0;
    } else return percentage;
  };
  //filtron punonjesit qe kane departament id te njejte me id e departamentit te klikuar, me pas
  //u ben map ketyre punonjesve duke vendosur emrin e tyre te names
  const handleDepartmentClick = (name) => {
    setDisab(false);
    setActiveTab(name);

    let res = employeesData
      ?.filter((el) => el?.employeeDepartmentName === name)
      ?.map((n) => {
        let type = leave
          .filter(
            (el) =>
              el?.userSub === n?.userSub && el?.requestStatus === "Aprovuar"
          )
          ?.sort((a, b) => b?.createdAt - a?.createdAt);
        return {
          id: n?.userSub,
          hasRequest: leave.some(
            (el) =>
              el?.userSub === n?.userSub &&
              el?.requestStatus === "Aprovuar" &&
              (el?.requestPeriod[0] <=
                moment().add(1, "months").endOf("month").valueOf() ||
                el?.requestPeriod[0] <= moment().endOf("month").valueOf()) &&
              (el?.requestPeriod.at(-1) >=
                moment().add(1, "months").startOf("month").valueOf() ||
                el?.requestPeriod.at(-1) >=
                  moment().startOf("month").valueOf()) &&
              el?.requestPeriod.at(-1) >= moment().valueOf()
          ),
          requestType: type?.length > 0 ? type.at(-1)?.requestType : "",
          title: n?.employeeFirstName + " " + n?.employeeLastName,
        };
      });

    res.length > 0 ? setNames(res) : setDisab(true);
  };
  //!PERQINDJA LLOGARITET VETEM PER PUNONJESIT QE JANE AKTUALISHT ME PUSHIME, JO PER PUSHIMET E ARDHSHME, QE MUND TE JENE SHFAQUR NE GANTT CHART
  const employeesOnVactionPercentage = () => {
    let allEmployeesOfDepartment = names?.length; // numri i te gjithe punonjesve ne department

    let onVac = leave.filter(
      (l) =>
        l?.requestPeriod[0] <= moment().valueOf() &&
        l?.requestPeriod.slice(-1)[0] >= moment().valueOf()
    );
    onVacation = 0; // Numri i punonjesve ne pushime per departamentin e selektuar
    onVac.map((el) => {
      if (names.filter((n) => n?.id === el?.userSub)?.length > 0) {
        onVacation++; // increment
      }
    });
    let percentage = (onVacation * 100) / allEmployeesOfDepartment;
    setPercentage(round(percentage, 1));
    onVacation = 0;
  };

  useEffect(() => {
    if (leave?.length >= 0) {
      //Merr punonjesit me leje nga db dhe i vendos te ganttItems,
      // e cila perdoret te calendar timeline per te shfaqur pushimet
      let result = leave.map((lev) => {
        return {
          id: lev?.requestId,
          group: lev?.userSub,
          title: `${
            moment(lev?.requestPeriod[0]).format("D") +
            " - " +
            moment(lev?.requestPeriod.at(-1)).format("D")
          }`,
          start_time: moment(lev?.requestPeriod[0]).startOf("day").valueOf(),
          end_time:
            lev?.requestType === "Dite Personale"
              ? moment(lev?.requestPeriod[0]).endOf("day").valueOf()
              : moment(lev?.requestPeriod.slice(-1)[0]).valueOf(),
          itemProps: {
            style: {
              background:
                lev?.requestPeriod?.at(-1) < moment().valueOf()
                  ? "#6E7278"
                  : lev?.requestStatus === "Aprovuar"
                  ? colorVacations(lev?.requestType)
                  : lev?.requestStatus === "Pending"
                  ? "#FCC94A"
                  : "#EA3943",
              color: "#FFFFFF",
              minWidth: "60px",
            },
          },
        };
      });
      setGanttItems(result);
    }
  }, [leave]);

  useEffect(() => {
    employeesOnVactionPercentage();
  }, [names, activeTab]);

  return (
    (<div>
      {ganttItems?.length >= 0 ? (
        <Row>
          <Col span={3}>
            <div className="time">
              {" "}
              <Clock
                format={"HH:mm"}
                ticking={true}
                timezone={"Europe/Tirane"}
              />
            </div>
            <div className="day-date">
              {moment().format("dddd")}, {moment().format("D MMMM")}
            </div>
            <div>
              {departments?.length >= 0 ? (
                <div className="departments-list">
                  {departments?.map((dep) => (
                    <li
                      key={dep?.departmentId}
                      id={dep?.departamentId}
                      className={
                        activeTab === dep?.departmentName
                          ? "chosen-department"
                          : "department"
                      }
                      onClick={() => {
                        handleDepartmentClick(dep?.departmentName);
                      }}>
                      {dep?.departmentName}
                    </li>
                  ))}
                </div>
              ) : (
                <Skeleton />
              )}
            </div>
          </Col>
          <Divider
            type="vertical"
            style={{ height: "380px", backgroundColor: "#F1F7FB" }}
            dashed
          />

          <Col style={{ width: "85%" }}>
            {!disab && ganttItems.length >= 0 ? (
              // {ganttItems.length > 0 && (
              (<Timeline
                style={{
                  width: "98%",
                  marginLeft: 16,
                }}
                itemHeightRatio={0.75}
                groups={names}
                items={ganttItems}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                canMove={false}
                canResize={false}
                canChangeGroup={false}>
                <TimelineHeaders>
                  <SidebarHeader
                    style={{ position: "inherit", color: "black" }}>
                    {({ getRootProps }) => {
                      return (
                        <div {...getRootProps()}>
                          <span className="percentage">
                            {formatPercentage(percentage)}% Pushim
                          </span>
                        </div>
                      );
                    }}
                  </SidebarHeader>

                  <DateHeader unit="primaryHeader" />

                  <DateHeader />
                </TimelineHeaders>
              </Timeline>)
            ) : (
              // )}
              (<div className="no-data-dep">Ky departament nuk ka të dhëna!</div>)
            )}
          </Col>
        </Row>
      ) : (
        <Skeleton />
      )}
    </div>)
  );
};
export default Departments;
