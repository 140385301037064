import React, { useEffect, useState } from "react";
import { Card, Button, Badge, Form } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EditIcon, MenagementIcon, XIcon } from "../../../../../assets/icons";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";
import { API } from "aws-amplify";
import { RichTextEditor } from "../../../../commonComponents";
import { htmlParser } from "../../../../../utils";
import "./KompaniaRolet.scss";
import GeneralViewModal from "../../../../commonComponents/GeneralViewModal/GeneralViewModal";
import { useSelector } from "react-redux";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

function KompaniaRolet() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state.usersList);
  //get the state with the location
  const { state } = useLocation();
  // state for the modal retun boolean
  const [visible, setVisible] = useState(false);
  // data with all the employes that are for the role return array
  const [content, setContent] = useState(null);
  // state with title of role return strin
  const [roleName, setRoleName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showViewEdit, setShowViewEditCard] = useState(false);
  const [toBeEdited, setToBeEdited] = useState(null);
  const [savedData, setSavedData] = useState({
    description: null,
  });
  //get data from state location and store in state
  const getData = () => {
    setTableData(state);
  };

  // change the state of the data  with the new value
  const handleChange = (objField, e) => {
    let test = objField;
    setSavedData((prev) => ({ ...prev, [test]: e }));
  };

  // opens the modal with the clicked state data
  const OpenModal = (RoleName) => {
    setContent(
      state?.lengthForEachDepartment
        ?.filter((el) => el.employeeRoleName === RoleName)
        ?.map((el) => el)
    );
    setRoleName(RoleName);
    setVisible(true);
  };

  //open the edit card view
  const handleShowEditView = (id) => {
    setShowViewEditCard(!showViewEdit);
    setToBeEdited(id);
  };

  // close the edit card view
  const handleHideEditView = (id) => {
    setShowViewEditCard(false);
    if (id === toBeEdited) {
      setToBeEdited(null);
      setSavedData({ description: null });
    }
  };
  // update description function
  const updateItems = async (idObj) => {
    let deps = tableData?.departmentRoles?.map((el) => {
      if (el.id === idObj) {
        return {
          ...el,
          description: savedData.description,
        };
      }
      return { ...el };
    });
    await API.put("departments", `/departments/${tableData.departmentId}`, {
      body: { departmentRoles: deps },
    }).then(() => {
      let save = { ...tableData, departmentRoles: deps };
      navigate("/konfigurimet/kompania/rolet", { state: save });
      setToBeEdited(null);
      setSavedData({ description: null });
    });
  };

  useEffect(() => {
    if (!!state) {
      getData();
    }
  }, [state]);

  return (
    <LoadableComp loading={!!tableData ? false : true}>
      <div className="departmentRolesContainer">
        <div className="departmentRoles">
          {tableData?.departmentRoles?.length > 0 ? (
            tableData?.departmentRoles?.map((role) => (
              <div className="roleCard">
                <Form form={form}>
                  <Card
                    key={role.employeeId}
                    title={
                      <div className="cardHeaderTitle">
                        <div className="name">
                          <MenagementIcon />
                          {role.name}
                        </div>
                        {toBeEdited === role?.id ? (
                          <div
                            className={`icon ${
                              toBeEdited === role?.id && "editMode"
                            }`}
                            onClick={() => handleHideEditView(role.id)}
                          >
                            <XIcon width={15} height={15} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            onClick={() => handleShowEditView(role.id)}
                          >
                            <EditIcon width={15} height={15} />
                          </div>
                        )}
                      </div>
                    }
                    bordered={false}
                  >
                    <div className="supervisor">
                      <span>
                        Supervizori: {tableData?.departmentManagerName}
                      </span>
                    </div>
                    <h1>Përshkrimi i pozicionit:</h1>
                    {toBeEdited === role?.id ? (
                      <div className="modeContainer">
                        <RichTextEditor
                          value={
                            !savedData.description
                              ? role.description
                              : savedData.description
                          }
                          className="richTextEditor"
                          onChange={(e) => handleChange("description", e)}
                        />
                        <MondayButton
                          disabled={
                            savedData.description !== null ? false : true
                          }
                          className="mondayButtonGreen"
                          onClick={() => updateItems(role.id)}
                        >
                          Ruaj Ndryshimet
                        </MondayButton>
                      </div>
                    ) : (
                      <div className="modeContainer">
                        <div className="description" style={{ height: 170 }}>
                          {!!role.description ? (
                            htmlParser(role.description)
                          ) : (
                            <div className="fallbackText">
                              Nuk ka përshkrim për këtë pozicion.
                            </div>
                          )}
                        </div>
                        <h3>
                          <Badge status="default" />
                          &nbsp;
                          {
                            tableData?.lengthForEachDepartment?.filter(
                              (el) => el?.employeeRoleName === role?.name
                            )?.length
                          }
                          &nbsp;punonjës aktivë
                        </h3>
                        <MondayButton
                          key={role.employeeId}
                          onClick={() => OpenModal(role.name)}
                          className="mondayButtonGreen"
                        >
                          Shiko punonjësit
                        </MondayButton>
                      </div>
                    )}
                  </Card>
                </Form>
              </div>
            ))
          ) : (
            <div className="fallbackText">
              Nuk ka asnjë punonjës për këtë rol
            </div>
          )}
        </div>
        <div className="departmentRolesFooter">
          <Link to="/konfigurimet/kompania/pozicionet">
            <MondayButton className="mondayButtonGreen">
              Kthehu pas
            </MondayButton>
          </Link>
        </div>
      </div>
      <GeneralViewModal
        {...{
          visible,
          setVisible,
          tableDefs,
        }}
        title={`Lista e punonjësve për rolin ${roleName}`}
        dataList={users?.allUsers?.Items}
        subDataList={content}
        pagination={7}
      />
    </LoadableComp>
  );
}

export default KompaniaRolet;

const tableDefs = [
  { header: "Punonjësi", key: "fullName", badge: true },
  { header: "Data e punësimit", key: "employeeHireDate" },
];
