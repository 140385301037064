import { Tooltip } from "antd";
import React from "react";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import QuillRenderer from "../../Intervistat/Kandidatet/Kategorite/Components/QuillRenderer";

export const columnDefs = ({ handleEdit, handleDelete }) => {
  return [
    {
      headerName: "Emri i template të Email",
      field: "templateName",
      // filter: "agTextColumnFilter",
      // width: "400px",
      resizable: true,
      flex: 1,

      sortable: true,
    },
    {
      headerName: "Kategoria e template të Email",
      field: "templateCategory",
      // filter: "agTextColumnFilter",
      // width: "400px",
      resizable: true,
      flex: 1,
      sortable: true,
    },
    {
      headerName: "Përmbajtja e template te Email",
      field: "templateBodyValues",
      // filter: "agTextColumnFilter",
      // width: "400px",
      resizable: true,
      flex: 1,
      cellRenderer: ({ data, rowIndex }) => {
        return <QuillRenderer value={data.templateBodyValues} />;
      },

      sortable: true,
    },

    {
      headerName: "Edit",
      resizable: true,
      width: "100px",
      cellStyle: () => {
        return {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        };
      },

      cellRenderer: ({ data, rowIndex }) => (
        <div onClick={() => handleEdit(data)} key={rowIndex}>
          <div className="buttonStyle">
            <Tooltip title="Edit">
              <EditIcon fill="#fff" width={18} height={18} />
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      headerName: "Delete",
      width: "100px",
      cellStyle: () => {
        return {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        };
      },
      resizable: true,
      cellRenderer: ({ data, rowIndex }) => (
        <div
          onClick={() => {
            handleDelete(data);
          }}
          key={rowIndex}
        >
          <div className="buttonStyle">
            <Tooltip title="Delete">
              <DeleteIcon fill="#fff" width={18} height={18} />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];
};
