import { WarningFilled } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import DocumentationModal from "../../../DocumentationModal/DocumentationModal";
import { Tooltip } from "antd";
import dayjs from "../../../../../../../utils/dayjs";
import "./DocumentationCard.scss";
import PunonjesitContext from "../../../../../../punonjesit/store/PunonjesitContext";

const DocumentationCard = ({ doc, key, openAutomatically }) => {
  const { categoryConfiguration } = useContext(PunonjesitContext);
  const { docType, docStatus, googleDriveUploads, createdAt } = doc;
  const [documentationModalVisible, setDocumentationModalVisible] = useState(
    !!openAutomatically
  );

  const filesUploaded = googleDriveUploads.length;

  //* checks if docName is equal with docType
  const { docStatuses = [] } =
    categoryConfiguration?.documentationsAvailable?.find(
      ({ docName }) => docName === docType
    ) || {};
  //* finds status name color and documentation status and displays it
  const { statusColor: backgroundColor } =
    docStatuses?.find(({ statusName }) => statusName === docStatus) || {};

  const TEXT = !!filesUploaded
    ? `${filesUploaded} ${
        filesUploaded?.length > 1 ? "Skedarë të ngarkuar" : "Skedar i ngarkuar"
      }`
    : "Mungojnë Skedarët";

  return (
    <>
      <div
        className="documentationViewCard"
        onClick={() => setDocumentationModalVisible(true)}
        key={key}
      >
        <Tooltip className="statusCardOverlay" title={docType}>
          <span className="docTitle">
            <div>{docType}</div>
            <div className="docCreationTime">
              {dayjs(createdAt).format("DD/MM/YYYY")}
            </div>
          </span>
        </Tooltip>
        <div className="leftSection">
          <div className="docStatusLabel" style={{ backgroundColor }}>
            {docStatus}
          </div>
          <div
            className={`uploadedFilesLabel ${!filesUploaded ? "noFiles" : ""}`}
          >
            {!filesUploaded && <WarningFilled className="warningIcon" />}
            <span>{TEXT}</span>
          </div>
        </div>
      </div>
      {!!documentationModalVisible ? (
        <DocumentationModal
          doc={doc || {}}
          {...{
            visible: documentationModalVisible,
            setVisible: setDocumentationModalVisible,
            docStatuses,
          }}
        />
      ) : null}
    </>
  );
};

export default DocumentationCard;
