import { InputNumber } from "antd";
import "./CustomAgPaginator.scss";

const CustomAgPaginator = ({ gridApi }) => {
  const totalPages = gridApi?.paginationGetTotalPages();
  return totalPages > 1 ? (
    <div className="customAgPaginator">
      <span>Go to Page:</span>
      <InputNumber
        min={1}
        max={totalPages}
        onChange={(e) => gridApi?.paginationGoToPage(e - 1)}
      />
    </div>
  ) : null;
};

export default CustomAgPaginator;
