import dayjs from "../../../utils/dayjs";
import { useCallback, useState } from "react";
import { useAuth } from "../../../authentication/authHelpers";

export const useUploadedFiles = (initialFiles) => {
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
  const auth = useAuth();

  const formattedFiles = uploadedFiles?.map(({ newDocFile, ...file }) => file);

  const onPickerSuccessCb = useCallback(
    ({ uploadedFiles }) => {
      const uploadedFileNames = uploadedFiles.map((item) => ({
        ...item,
        newDocFile: true,
        uploadedBy: `${auth?.user?.attributes?.given_name} ${auth?.user?.attributes?.family_name}`,
        uploadedAt: dayjs().valueOf(),
      }));
      setUploadedFiles((prev) => [...prev, ...uploadedFileNames]);
    },
    [auth]
  );

  const onDeleteCb = useCallback((fileId) => {
    setUploadedFiles((prev) => prev.filter(({ id }) => id !== fileId));
    setFilesToBeDeleted((prev) => [...prev, fileId]);
  }, []);

  return [
    [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccessCb],
    [filesToBeDeleted, setFilesToBeDeleted, onDeleteCb],
  ];
};
