import React, { useContext } from "react";
import "./ListaEkandidateve.scss";
import { Button, Dropdown, Modal, Typography, Space } from "antd";
import KandidatiTabs from "./KandidatiTabs";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { ClockCircleFilled, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "../../../utils/dayjs";
import { useDispatch, useSelector } from "react-redux";
import { InterviewsContext } from "../data";
import { QuestionsMark } from "./ShtoKandidat/Image";
import { candidates as candidatesReducer } from "../../../store/actions";
import types from "../../../store/types/types";
import { InfoBluecIcon } from "../../../assets/icons";
import { useAuth } from "../../../authentication/authHelpers";
import { translateObjectFields } from "./ShtoKandidat/ShowContactDetails/ShowContactDetails";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

const Text = Typography;
const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const Kandidati = () => {
  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);

  const auth = useAuth();
  const dispatch = useDispatch();

  const {
    kandidatiSelektuar,
    colors,
    setKandidatiSelektuar,
    defaultSwalProperties,
    intervistat,
    setIntervistat,
    driveRequest,
  } = useContext(InterviewsContext);

  const { fieldOptions: disqualificationOptions = [] } = programFields?.find(
    (el) => el?.fieldName === "Arsyet e skualifikimit"
  );
  const { fieldOptions: candidateOptions = undefined } = programFields?.find(
    ({ fieldId }) => fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
  );

  //region UPDATE BUTTONS
  const updateKandidat = async (applicantStatus = "", e = "") => {
    if (kandidatiSelektuar?.applicantStatus === applicantStatus)
      return Swal.mixin({ ...defaultSwalProperties }).fire({
        icon: "question",
        title: "Kandidati ka statusin e njejte!",
      });

    try {
      Swal.fire({
        title: "Ju lutem prisni...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => Swal.showLoading(),
      });
      const constants = {
        activity: "Ndryshuar",
        author:
          auth.userAccess[0]?.given_name +
          " " +
          auth.userAccess[0]?.family_name,
        changeDate: moment().valueOf(),
      };
      const reasonChange = {
        ...constants,
        field: "applicantDisqualificationReason",
        oldValue: kandidatiSelektuar?.applicantDisqualificationReason,
        newValue: e,
      };
      const statusChange = {
        ...constants,
        field: "applicantStatus",
        oldValue: kandidatiSelektuar?.applicantStatus,
        newValue: applicantStatus,
      };
      const keylogs = translateObjectFields({
        fieldOptions: candidateOptions,
        changes: !!e ? [statusChange, reasonChange] : [statusChange],
      });
      const toSend = {
        applicantStatus,
        applicantDisqualificationReason: e,
        keylog: [...(kandidatiSelektuar?.keylog || []), ...keylogs],
      };

      await API.put(
        "interviews",
        `/interviews/${kandidatiSelektuar?.applicantId}`,
        {
          body: toSend,
        }
      );
      const updatedIntervista = candidates?.map((intervista) =>
        intervista?.applicantId === kandidatiSelektuar?.applicantId
          ? {
              ...intervista,
              ...toSend,
            }
          : intervista
      );

      dispatch(candidatesReducer(updatedIntervista));
      setIntervistat?.(
        intervistat?.map?.((intervista) =>
          intervista?.applicantId === kandidatiSelektuar?.applicantId
            ? {
                ...intervista,
                ...toSend,
              }
            : intervista
        )
      );
      setKandidatiSelektuar({
        ...kandidatiSelektuar,
        ...toSend,
      });
    } catch (error) {
      console.error("Error during status update:", error);
      Swal.mixin({ ...defaultSwalProperties }).fire({
        icon: "error",
        title: "Error ne ndryshimin e status!",
      });
    } finally {
      let title = document.createElement("span");
      title.innerHTML = `${kandidatiSelektuar?.applicantFirstName} ${kandidatiSelektuar?.applicantLastName} <b style="color:${colors?.[applicantStatus]}; font-size:13px">${applicantStatus}</b>`;
      Swal.mixin({ ...defaultSwalProperties }).fire({
        icon: "success",
        title,
        text: `Statusi u ndryshua me sukses!`,
      });
    }
  };

  //region Skualifiko options
  const items = [
    ...disqualificationOptions.map((item, index) => ({
      ...item,
      label: (
        <div
          onClick={() => updateKandidat("Skualifikuar", item)}
          key={index}
          style={{ textAlign: "center" }}
        >
          {item}
        </div>
      ),
    })),
  ];

  //region DELETE MODAL
  const deleteKandidati = () => {
    Modal.confirm({
      title: "A jeni të sigurt?",
      icon: <QuestionsMark />,
      content: (
        <Space direction="vertical">
          <Text>Ju po fshini intervistë me {" " + applicantFullName}.</Text>
          <Text>
            Nëse jeni të sigurt për këtë veprim, klikoni
            <span className="text-bold">"Po, fshije"</span>
          </Text>
        </Space>
      ),
      okText: "Po, fshije",
      cancelText: "Jo, mos e fshi",
      width: "660px",
      className: "cancelModal",
      centered: true,
      cancelButtonProps: {
        type: "primary",
        style: {
          background: "#EA3943",
          borderColor: "#EA3943",
          borderRadius: "5px",
        },
      },
      okButtonProps: {
        className: "ant-btn-confirm",
      },
      onOk() {
        deleteKandidatiFunc();
      },
      onCancel() {},
    });
  };

  //region DELETE FUNCTION
  const deleteKandidatiFunc = async () => {
    try {
      await API.del(
        "interviews",
        `/interviews/${kandidatiSelektuar?.applicantId}`
      ).then(() => {
        driveRequest.deleteDriveItem(kandidatiSelektuar?.googleDriveFolderId);
        dispatch(
          candidatesReducer(kandidatiSelektuar, types.REMOVE_CANDIDATES)
        );
        setKandidatiSelektuar({});
      });
    } catch (err) {
      console.log("Error deleting InterView", err);
    }
  };

  let applicantFullName =
    kandidatiSelektuar.applicantFirstName +
    " " +
    kandidatiSelektuar.applicantLastName;

  let textFormater =
    applicantFullName.length > 30
      ? applicantFullName.slice(0, 30) + "..."
      : applicantFullName;

  //region RETURN
  return (
    <div className="kandidatiSelektuarContent">
      <div
        className="statusColor"
        style={{
          backgroundColor: colors?.[kandidatiSelektuar?.applicantStatus],
        }}
      ></div>
      <div className="kandidatiView">
        <div className="kandidatiDetails">
          <div className="nameAndStatus">
            <div className="nameAndStatusItem">
              <span className="name">{textFormater}</span>
              <div
                className="statusContent"
                style={{
                  background: colors?.[kandidatiSelektuar?.applicantStatus],
                }}
              >
                <span>{kandidatiSelektuar?.applicantStatus}</span>
              </div>
            </div>
            <div className="nameAndStatusItem">
              <MondayButton
                className="mondayButtonRed"
                onClick={() => deleteKandidati()}
              >
                Fshini Kandidatin
              </MondayButton>
              <Dropdown
                placement="bottom"
                menu={{ items }}
                disabled={
                  kandidatiSelektuar?.applicantStatus === "Skualifikuar" ||
                  kandidatiSelektuar?.applicantStatus === "Pranuar"
                }
              >
                <Button
                  // onClick={() => updateKandidat("Skualifikuar")}
                  className="skualifkoBtn"
                >
                  Skualifiko
                </Button>
              </Dropdown>
              <Button
                disabled={
                  kandidatiSelektuar?.applicantStatus === "Skualifikuar" ||
                  kandidatiSelektuar?.applicantStatus === "Pranuar"
                }
                onClick={() => updateKandidat("Pranuar")}
                className="pranoBtn"
              >
                Prano
              </Button>
            </div>
          </div>
          <div className="details">
            <div className="detailsRow">
              <span>
                <span className="detailsTitle">Pozicioni:</span>
                {kandidatiSelektuar?.applicantDepartment}
              </span>
              <span>
                <span className="detailsTitle">Rolet:</span>
                {Array.isArray(kandidatiSelektuar?.applicantRoles)
                  ? kandidatiSelektuar?.applicantRoles.join(", ")
                  : kandidatiSelektuar?.applicantRoles}
              </span>
              <span>
                <span className="detailsTitle">Data e aplikimit:</span>
                {dayjs(kandidatiSelektuar?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="detailsRow">
              <span>
                <CalendarOutlined />
                <span className="detailsTitle">Data e intervistës:</span>
                {dayjs(kandidatiSelektuar?.applicantInterviewDate).format(
                  "DD/MM/YYYY"
                )}
              </span>
              <span>
                <ClockCircleFilled />
                <span className="detailsTitle">Ora e intervistës:</span>
                {dayjs(kandidatiSelektuar?.applicantInterviewDate).format(
                  "HH:mm"
                )}
              </span>
              {!!kandidatiSelektuar?.applicantDisqualificationReason?.length ? (
                <span>
                  <InfoBluecIcon height={14} width={14} fill="black" />
                  <span className="detailsTitle">Arsyeja e skualifikimit:</span>
                  {kandidatiSelektuar?.applicantDisqualificationReason}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className="firstDetails">
          <div className="pozicioniEdheData">
            <div className="centerContent">
              <span>Pozicioni: {kandidatiSelektuar?.applicantDepartment}</span>
            </div>
            {kandidatiSelektuar?.applicantRoles && (
              <div className="centerContent">
                <span>
                  Rolet:{" "}
                  {Array.isArray(kandidatiSelektuar?.applicantRoles)
                    ? kandidatiSelektuar?.applicantRoles.join(", ")
                    : kandidatiSelektuar?.applicantRoles}
                </span>
              </div>
            )}
            <div className="centerContent">
              <span>Data e aplikimit:</span>
              <span>
                {dayjs(kandidatiSelektuar?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div> */}
        <div>
          <div className="kandidatiDetails">
            {/* <div className="pozicioniEdheData">
              <div className="centerContent">
                <span>
                  <CalendarOutlined />
                  Data e intervistës:
                </span>
                <span>
                  {" "}
                  {moment(kandidatiSelektuar?.applicantInterviewDate).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </div>
              <div className="centerContent">
                <span>
                  <ClockCircleFilled />
                  Ora e intervistës:
                </span>

                <span>
                  {moment(kandidatiSelektuar?.applicantInterviewDate).format(
                    "HH:mm"
                  )}
                </span>
              </div>

              {kandidatiSelektuar?.applicantDisqualificationReason?.length !==
                0 && (
                <div className="centerContent">
                  <span>
                    <InfoBluecIcon height={14} width={14} fill="black" />
                    Arsyeja e skualifikimit:
                  </span>

                  <span>
                    {kandidatiSelektuar?.applicantDisqualificationReason}
                  </span>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
      <div className="kandidatiTabs">
        <KandidatiTabs />
      </div>
    </div>
  );
};

export default Kandidati;
