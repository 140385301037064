import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Checkbox, Menu, Alert } from "antd";
import { PaymentInfo } from "./PaymentInfo";
import { EditIcon } from "../../../../assets/icons";
import "./EmployeePayment.scss";
import NameCard from "../components/NameCard";
import { paymentData } from "./paymentData";
import PaymentGrid from "./PaymentGrid";
import { API } from "aws-amplify";
import LoaderComponent from "../../../commonComponents/LoadableComp/LoadableComp";
import moment from "moment-timezone";
import {
  BluredCard,
  ComponentCard,
} from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import PunonjesitContext from "../../store/PunonjesitContext";
export const EmployeePayment = () => {
  const { employee } = useContext(PunonjesitContext);

  const [showPaymentInfo, setShowPaymentInfo] = useState(true);
  const [changes, setChanges] = useState([]);
  const [shifts, setShifts] = useState(null);

  const formatData = (data, format) => {
    if (format === "shift") {
      let shiftNames = [];
      if (!!shifts?.length) {
        if (!!data?.length) {
          data?.forEach((id) => {
            let name = shifts?.find((el) => el?.shiftId === id)?.shiftName;
            shiftNames.push(name);
          });
        } else {
          shiftNames.push("Nuk ka te dhena");
        }
      }
      return shiftNames?.join(", ") || "";
    }
    if (format === "currency") {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(data);
    }
  };

  const handleClick = () => {
    setShowPaymentInfo(false);
  };
  const handleHidePayment = () => {
    setShowPaymentInfo(true);
  };
  const getShifts = async () => {
    await API.get("shifts", "/shifts").then((res) => setShifts(res));
  };

  // get the data when the page renders
  useEffect(() => {
    getShifts();
  }, []);

  return (
    <div style={!!shifts ? {} : { height: "100vh" }}>
      <LoaderComponent loading={!!shifts ? false : true}>
        <div className="globalPageWrapper">
          <div className="employeePayment">
            {showPaymentInfo === true ? (
              <Card
                className="paymentCard"
                title="Informacioni i pagesës"
                data-testid="employee-payment-card"
                extra={
                  <div className="icon-button">
                    <button id="edit" onClick={handleClick}>
                      <EditIcon className="pen-icon" />
                    </button>
                  </div>
                }
              >
                <ComponentCard
                  blurDuration={30}
                  height="100%"
                  blur={true}
                  content={
                    <div className="paymentData">
                      {paymentData?.map(
                        ({ id, icon, label, fieldName, format = null }) => (
                          <div className="brick" key={id}>
                            {icon}
                            <span
                              className="fieldName"
                              style={{
                                ...(!!employee?.[fieldName]
                                  ? {}
                                  : { color: "#ea3943" }),
                              }}
                            >
                              {!!employee?.[fieldName]
                                ? !!format
                                  ? formatData(employee?.[fieldName], format)
                                  : employee?.[fieldName]
                                : "Nuk ka te dhena"}
                            </span>
                            <span className="label">{label}</span>
                          </div>
                        )
                      )}
                    </div>
                  }
                />
              </Card>
            ) : (
              <PaymentInfo
                {...{
                  handleHidePayment,
                  changes,
                  setChanges,
                  shifts,
                }}
              />
            )}
            <PaymentGrid employee={employee} />
          </div>
        </div>
      </LoaderComponent>
    </div>
  );
};
