import "./AgGridHeader.scss";
import FilterAgGrid from "../../punonjesit/components/FilterAgGrid";
import {
  AgGridToExcel,
  AgGridToPDF,
  AgGridToPrint,
  SearchAgGrid,
  BulkDeleteAgGrid,
  SwitchAgGrid,
  DropdownAgGrid,
} from "./components";
import MondayButton from "../MondayButton/MondayButton";
import { RefreshIcon } from "../../../assets/icons";
import { LogsIcon } from "../../Documentation/View/assets";

const AgGridHeader = ({ gridApi, gridData, items, exportTitle, children }) => {
  const handleClean = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
  };

  return (
    <div className="agGridHeader">
      {Object.entries(items)?.map(([key = "", props = {}]) => {
        if (key.toLowerCase() === "search") {
          return <SearchAgGrid {...{ gridApi, handleClean }} />;
        } else if (key.toLowerCase() === "dropdowns") {
          return props?.map((prop) => (
            <DropdownAgGrid {...prop} gridData={gridData} gridApi={gridApi} />
          ));
        } else if (key.toLowerCase() === "switch") {
          return <SwitchAgGrid {...props} />;
        } else if (key.toLowerCase() === "icons") {
          const exportProps = {
            gridApi,
            title: exportTitle,
          };
          return (
            <div className="headerIcons">
              {Object.entries(props)?.map(([iconKey = "", iconProps = {}]) => {
                if (iconKey.toLowerCase() === "filter") {
                  return (
                    <FilterAgGrid
                      gridApi={gridApi}
                      clearFilters={handleClean}
                      tableData={gridData}
                      {...iconProps}
                    />
                  );
                }
                if (iconKey.toLowerCase() === "excel") {
                  return <AgGridToExcel {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "pdf") {
                  return <AgGridToPDF {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "print") {
                  return <AgGridToPrint {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "delete") {
                  return <BulkDeleteAgGrid {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "refresh") {
                  return (
                    <span className="headerIcon" {...iconProps}>
                      <RefreshIcon width={20} height={20} />
                    </span>
                  );
                }
                if (iconKey.toLowerCase() === "modal") {
                  return (
                    <span className="headerIcon" {...iconProps}>
                      <LogsIcon width={20} height={20} fill="#323338" />
                    </span>
                  );
                }
              })}
            </div>
          );
        } else return null;
      })}
      {children}
    </div>
  );
};

export default AgGridHeader;
