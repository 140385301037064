import { CaretDownFilled } from "@ant-design/icons";
import { Select } from "antd";
import { useMemo } from "react";

const DropdownAgGrid = ({ gridApi, gridData, keyName, placeholder, icon }) => {
  const { Option } = Select;

  const onChange = async (e) => {
    if (e !== undefined) {
      await gridApi.getColumnFilterInstance(keyName).then((comp) => {
        comp.setModel({
          type: "contains",
          filter: e,
        });
      });
      gridApi.onFilterChanged();
    } else {
      gridApi.destroyFilter(keyName);
    }
  };

  const options = useMemo(() => {
    const items = [];
    if (!gridData?.length) {
      return items;
    }
    const data = gridData?.map((row) => row?.[keyName])?.filter(Boolean);
    data?.forEach((el) => {
      if (!items?.includes(el)) {
        items.push(el);
      }
    });
    return items;
  }, [gridData]);

  return (
    <div className="headerDropdown">
      <span className="icon">{icon}</span>
      <Select
        suffixIcon={<CaretDownFilled />}
        placeholder={placeholder}
        onChange={onChange}
      >
        {options?.map((option, idx) => (
          <Option value={option} key={idx}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default DropdownAgGrid;
